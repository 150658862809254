import React, { useState } from 'react'
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import TabletMacIcon from '@mui/icons-material/TabletMac';
const SelfCheckBox = ({style}) => {
    const [isChecked, setIsChecked] = useState(false); // Manage checkbox state

    const handleCheckboxChange = () => {
        isChecked?localStorage.removeItem("selfUser"):localStorage.setItem("selfUser","selfUser")
        setIsChecked((prev) => !prev); // Toggle the state on checkbox change


    };
    return (
        <label className="self-container" style={style}>
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                style={{ display: 'none' }} // Hide default checkbox
            />
            <div className="self-checkmark">
                {isChecked ? (
                    <>
                        <TabletMacIcon className="self-icon self-Yes"/>
                        <p className="self-Yes self-name">SELF</p>
                    </>
                ) : (
                    <>
                        <p className="self-No self-name">POS</p>
                        <LaptopMacIcon className="self-icon self-No"/>
                    </>
                )}
            </div>
        </label>
        )
};

export default SelfCheckBox;
