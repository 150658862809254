import React from 'react'
import SelectCard from '../Card/SelectCard'
import dineInImg from "../../../Assets/SelfOrder/order-more.png"
import takeAwayImg from "../../../Assets/SelfOrder/online-order.png"
import { useDispatch, useSelector } from 'react-redux'
import { setSelfOrderScreen } from './selfOrderEssentialsSlice'
import { completeSelfOrderAPI } from '../../API/selfOrderAPIS'

const OrderConfirmScreen = () => {

  const dispatch = useDispatch()

  const decimalPosition=localStorage.getItem("decimalPosition")
  const { selfSingleOrderInfo } = useSelector((state) => state.selfOrderMainSlice)
  const { receiptData } = useSelector((state) => state.viewOrdersBillingSlice);
  const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)

  const cardClick = (click) => {
    click === "update" ? dispatch(setSelfOrderScreen("orderProductLists")) : completeSelfOrderAPI({ orderId: selfSingleOrderInfo?._id })
  }
  return (
    <div className='order-confirm-screen'>
      <h3>{selfSingleOrderInfo?.name}</h3>
      <div className="justify-center" style={{ gap: "15px", flexWrap: "wrap" }}>
        <SelectCard image={dineInImg} label="ADD-MORE +" onClick={() => cardClick("update")} labelStyle={{ color: "#f5585c" }} imgStyle={{ minHeight: "auto" }} />
        <SelectCard image={takeAwayImg} label="COMPLETE" onClick={() => cardClick("complete")} labelStyle={{ color: "#388e3c" }} imgStyle={{ minHeight: "auto" }} />
      </div>

      <div class="price-breakdown-container">
        <h2>Order Summary</h2>
        <div class="price-details">
          <div class="price-item">
            <span>PAX:</span>
            <span>{receiptData?.orderInfo?.length}</span>
          </div>
          <div class="price-item">
            <span>Taxable amount:</span>
            <span>{currencyDetails?.symbol || ""}&nbsp;{(receiptData?.taxableAmt || 0)?.toFixed(decimalPosition)}</span>
          </div>
          {
            receiptData?.taxDetails &&
            receiptData?.taxDetails?.map((item,i)=>(
              <div class="price-item" key={i}>
                <span>{item?.taxName || ""}</span>
                <span>{currencyDetails?.symbol || ""}&nbsp;{(item?.amount || 0)?.toFixed(decimalPosition)}</span>
              </div>
            ))
          }
        {
        receiptData?.pkgCharge>0 &&
        <div class="price-item">
            <span>Packaging Charge:</span>
            <span>{currencyDetails?.symbol || ""}&nbsp;{(receiptData?.pkgCharge || 0)?.toFixed(decimalPosition)}</span>
          </div>}
          <div class="price-item total">
            <span>Total:</span>
            <span>{currencyDetails?.symbol || ""}&nbsp;{(receiptData?.amtTotal || 0)?.toFixed(decimalPosition)}</span>
          </div>
        </div>
      </div>


    </div>
  )
}

export default OrderConfirmScreen