import React from 'react'
import AdvncShiftReportHeader from './AdvncShiftReportHeader'
import BillTypeSummaryPrint from './BillTypeSummaryPrint'
import DeliveryPartnerSummaryPrint from './DeliveryPartnerSummaryPrint'
import DeliveryBoysSummaryPrint from './DeliveryBoysSummaryPrint'
import DiscountSummaryPrint from './DiscountSummaryPrint'
import CategorySalesSummaryPrint from './CategorySalesSummaryPrint'
import TaxDetailsPrint from './TaxDetailsPrint'
import ShiftReceiptPrint from './ShiftReceiptPrint'
import ShiftDevicePrint from './ShiftDevicePrint'
import { getTemplateAPI } from '../../../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import BillTypeTemplateSummaryPrint from './BillTypeTemplateSummaryPrint'

const AdvanceShiftReportPrintComponent = ({selectedButton}) => {
  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );
  useEffect(() => {
    getTemplateAPI();
  }, []);

  return (
    <div className='flex-column advance-shift-report-table-container'>
        <AdvncShiftReportHeader/>
        {
            selectedButton === "BILL TYPE"?
            (template?.shiftReportReceiptTemplate === "template2" 
              ? <BillTypeSummaryPrint /> 
              :<BillTypeTemplateSummaryPrint /> ) :
            selectedButton === "DELIVERY PARTNERS"?
            <DeliveryPartnerSummaryPrint/>:
            selectedButton === "DELIVERY BOYS"?
            <DeliveryBoysSummaryPrint/>:
            selectedButton === "DISCOUNT"?
            <DiscountSummaryPrint/>:
            selectedButton === "TAX DETAILS"?
            <TaxDetailsPrint/>:
            selectedButton==="RECEIPT"?
            <ShiftReceiptPrint/>:
            selectedButton==="DEVICE"?
            <ShiftDevicePrint/>:
            <CategorySalesSummaryPrint/>
        }
    </div>

  )
}

export default AdvanceShiftReportPrintComponent