import React, { useState } from 'react'
import BorderMovingBtn from '../Buttons/BorderMovingBtn'
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelfSubCat, setSpecialOfferDialog, setSubCatChildList } from './selfOrderEssentialsSlice';
import { initialSubCatFilterAPI } from './selfOrderListingAPI';
import { listSpecialItemsForBilling } from '../../../component/Modules/Sales/SpecialItems/specialItemsApi';
import { getTodaysLowerCaseDay } from '../../../Js/generalFunctions';
const SubCategoryandSpecialItems = () => {

  const dispatch=useDispatch()
  
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userBranchId = userInfo?.branchPk;
  const {mainCat,catSubList,subCat,subCatChildList} = useSelector((state) => state.selfOrderEssentialsSlice);

  const [categoryMenu,setCategoryMenu] = useState(null);
  const [subCategoryMenu,setSubCategoryMenu] = useState(null);

  const handleClose =() => {
    setCategoryMenu(null)
    setSubCategoryMenu(null)
};

const categoryOnCLick=(type)=>(event)=>{
    if(type==="CATEGORY"){
        catSubList && setCategoryMenu(event.currentTarget);
    }else{
        subCatChildList && setSubCategoryMenu(event.currentTarget);
    }
}

const subCatOnClick=(item)=>()=>{
    let category=item?._id===subCat?._id?null:item
    dispatch(setSelfSubCat(category))
    category?initialSubCatFilterAPI({catId:mainCat,parentCategories:category?._id}):dispatch(setSubCatChildList([]))
}

const specialItemDialogClick=()=>{
    listSpecialItemsForBilling({
        branchId: userBranchId,
        day: getTodaysLowerCaseDay(),
      });
    dispatch(setSpecialOfferDialog(true))
}

  return (
    <div className='self-sub-category-parent'>
        {
        mainCat &&    
        <div disabled={!mainCat} className="selected-category" onClick={categoryOnCLick("CATEGORY")}>
            {mainCat?.categoryName}
        </div>}
        <Menu
            anchorEl={categoryMenu}
            open={Boolean(categoryMenu)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{paddingInline:"5px"}}
        >
            {catSubList?.map((option, index) => (
                <div className={`${subCat?._id===option?._id?'sublist-menu-selected-category':''} sub-lists-menu-category`} key={index} onClick={subCatOnClick(option)}>
                    {option?.name}
                </div>
            ))}
        </Menu>
        {
        subCat &&    
        <div className="selected-category" onClick={categoryOnCLick("SUBCAT")} style={{marginLeft:"10px",borderColor:"green"}}>
            {subCat?.name}
        </div>}
        <Menu
            anchorEl={subCategoryMenu}
            open={Boolean(subCategoryMenu)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{paddingInline:"5px"}}
        >
            {subCatChildList?.map((option, index) => (
                <div className='sub-lists-menu-category' key={index} onClick={subCatOnClick(option)}>
                    {option?.name}
                </div>
            ))}
        </Menu>
        <BorderMovingBtn
         onClick={specialItemDialogClick}
         icon={<WhatshotIcon style={{color:"#fff"}}/> 
}/>
    </div>
  )
}

export default SubCategoryandSpecialItems