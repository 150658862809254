import React from 'react'
import AdvncShiftReportHeader from './AdvncShiftReportHeader'
import BillTypeSummaryPrint from './BillTypeSummaryPrint'
import DeliveryPartnerSummaryPrint from './DeliveryPartnerSummaryPrint'
import DeliveryBoysSummaryPrint from './DeliveryBoysSummaryPrint'
import DiscountSummaryPrint from './DiscountSummaryPrint'
import CategorySalesSummaryPrint from './CategorySalesSummaryPrint'
import TaxDetailsPrint from './TaxDetailsPrint'
import StarHorizontalLine from '../../../../Single Components/StarHorizontalLine'
import ShiftReceiptPrint from './ShiftReceiptPrint'
import ShiftDevicePrint from './ShiftDevicePrint'
import { useSelector } from 'react-redux'
import BillTypeTemplateSummaryPrint from './BillTypeTemplateSummaryPrint'

const AdvanceSummaryFullPrint = () => {
  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );
  return (
    <div className='flex-column advance-shift-report-table-container'>
        <AdvncShiftReportHeader/>
            {template?.shiftReportReceiptTemplate === "template2" ?
              <BillTypeSummaryPrint/>:
              <BillTypeTemplateSummaryPrint/>
            }
            <StarHorizontalLine/>
            <DeliveryPartnerSummaryPrint/>
            <StarHorizontalLine/>
            <DeliveryBoysSummaryPrint/>
            <StarHorizontalLine/>
            <DiscountSummaryPrint/>
            <StarHorizontalLine/>
            <TaxDetailsPrint/>
            <StarHorizontalLine/>
            <CategorySalesSummaryPrint/>
            <StarHorizontalLine/>
            <ShiftReceiptPrint/>
            <StarHorizontalLine/>
            <ShiftDevicePrint/>
            <StarHorizontalLine/>
    </div>
    

  )
}

export default AdvanceSummaryFullPrint