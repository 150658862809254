import React from "react";
import { useSelector } from "react-redux";

function BillTypeTemplateSummary({showSubHeaders}) {
    const ShiftBillTypeSummary = useSelector(
        (state) => state.posReportshiftSlice.billTypeSummary
      );
      const decimalPosition = localStorage.getItem("decimalPosition");
      const getBackgroundColor = (index) => {
        return index % 2 === 0 ? "#ffffff" : "#E6E6E6";
      };
      const deliveryTotal = (ShiftBillTypeSummary?.selfDelivery?.total || 0) + (ShiftBillTypeSummary?.aggregator?.total|| 0)
  return (
    <div
    className="new-global-table-container"
    style={{ paddingTop: "0 42px" }}
  >
  
    <div className="bill-main-head">
      <h5>Bill Type</h5>
      <h5>Amount</h5>
    </div>
    <div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(0),
      }}
    >
      <h5>Cash & Card</h5>
      <h5>
        {(ShiftBillTypeSummary?.cashAndCard?.total ||0)?.toFixed(decimalPosition)}
      </h5>
    </div>
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            <th>Payment Method</th>
            <th>Amount</th>
            <th>Commission</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.cashAndCard?.list !== undefined ? (
            ShiftBillTypeSummary?.cashAndCard?.list?.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.type}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.commission?.toFixed(decimalPosition)}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.balance?.toFixed(decimalPosition)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}
    <div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(1),
      }}
    >
      <h5>Sales</h5>
      <h5>
        {(ShiftBillTypeSummary?.sales?.total ||0)?.toFixed(decimalPosition)}
      </h5>
    </div>
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            <th>Type</th>
            <th>Order Count</th>
            <th>Amount</th>
          </tr>
        </thead>
         <tbody>
      {ShiftBillTypeSummary?.sales?.list ? (
        Object.entries(ShiftBillTypeSummary?.sales?.list).map(([type, details], i) => (
          <tr key={i}>
            <td style={{ borderBottom: "1px solid black", height: "29px" }}>
              {type}
            </td>
            <td style={{ borderBottom: "1px solid black", height: "29px" }}>
              {details.count}
            </td>
            <td style={{ borderBottom: "1px solid black", height: "29px" }}>
              {details.amount?.toFixed(decimalPosition)}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={3}>NO DATA</td>
        </tr>
      )}
    </tbody>
      </table>
    )}
      <div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(2),
      }}
    >
      <h5>Staff Expense</h5>
      <h5>
        {(ShiftBillTypeSummary?.staffExpense?.total||0)?.toFixed(decimalPosition) }
      </h5>
    </div>
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            <th>Expense</th>
            <th>Employee Name</th>
            {/* <th>Date</th> */}
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.staffExpense?.list !== undefined ? (
            ShiftBillTypeSummary?.staffExpense?.list?.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.expense}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.employee}
                </td>
                {/* <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.date}
                </td> */}
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}
      <div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(3),
      }}
    >
      <h5>Outlet Expense</h5>
      <h5>
        {(ShiftBillTypeSummary?.outletExpense?.total || 0)?.toFixed(decimalPosition)}
      </h5>
    </div>
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            {/* <th>Shift ID</th> */}
            <th>Doc No</th>
            <th>Expense</th>
            {/* <th>Date</th> */}
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.outletExpense?.list !== undefined ? (
            ShiftBillTypeSummary?.outletExpense?.list?.map((r, i) => (
              <tr key={i}>
                {/* <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.shift}
                </td> */}
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.docNo}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.expense}
                </td>
                {/* <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.date}
                </td> */}
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}
        <div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(4),
      }}
    >
      <h5>Delivery</h5>
      <h5>
        {(ShiftBillTypeSummary?.delivery?.total||0)?.toFixed(decimalPosition)}
      </h5>
    </div>
    {showSubHeaders && (
      <div className="bill-sub2-head">
        <h5>Self Delivery</h5>
        <h5> {(ShiftBillTypeSummary?.delivery?.selfDelivery?.total || 0)?.toFixed(decimalPosition)}</h5>
      </div>
    )}
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            <th>Employee</th>
            <th>Order Count</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.delivery?.selfDelivery?.list !== undefined ? (
            ShiftBillTypeSummary?.delivery?.selfDelivery?.list?.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.name}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.count}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}
    {showSubHeaders && (
      <div className="bill-sub2-head">
        <h5>Aggregator</h5>
        <h5>{(ShiftBillTypeSummary?.delivery?.aggregator?.total || 0)?.toFixed(decimalPosition)}</h5>
      </div>
    )}
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
        <tr>
            <th>Employee</th>
            <th>Order Count</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.delivery?.aggregator?.list !== undefined ? (
            ShiftBillTypeSummary?.delivery?.aggregator?.list?.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.name}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.count}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
               
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}
  <div
  className="bill-sub-head"
  style={{
    backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(5),
  }}
>
  <h5>Receipt</h5>
  <h5>
    {(ShiftBillTypeSummary?.receipt?.total || 0).toFixed(decimalPosition)}
  </h5>
</div>
{showSubHeaders && (
  <table>
    <thead style={{ backgroundColor: "#C0CFF8" }}>
      <tr>
        {/* <th>Description</th>
        <th>Amount</th> */}
      </tr>
    </thead>
    <tbody>
      {/* <tr>
        <td style={{ borderBottom: "1px solid black", height: "29px" }}>Sales</td>
        <td style={{ borderBottom: "1px solid black", height: "29px" }}>{ShiftBillTypeSummary?.receipt?.sales?.toFixed(decimalPosition)||"0.00"}</td>
      </tr>
      <tr>
      <td style={{ borderBottom: "1px solid black", height: "29px" }}>Delivery Partner</td>
      <td style={{ borderBottom: "1px solid black", height: "29px" }}>{ShiftBillTypeSummary?.receipt?.deliveryPartner?.toFixed(decimalPosition)||"0.00"}</td>
      </tr>
      <tr>
      <td style={{ borderBottom: "1px solid black", height: "29px" }}>Packaging Charge</td>
      <td style={{ borderBottom: "1px solid black", height: "29px" }}>{ShiftBillTypeSummary?.receipt?.packagingCharge?.toFixed(decimalPosition)||"0.00"}</td>
      </tr>
      <tr>
      <td >Delivery Charge</td>
      <td >{ShiftBillTypeSummary?.receipt?.deliveryCharge?.toFixed(decimalPosition)||"0.00"}</td>
      </tr> */}
      {ShiftBillTypeSummary?.receipt ? (
  Object.entries(ShiftBillTypeSummary?.receipt)
    .filter(([key]) => key !== "total") // Exclude the 'total' row
    .map(([key, value], i) => (
      <tr key={i}>
        <td style={{ borderBottom: "1px solid black", height: "29px" }}>
          {key === "sales" ? "Sales" : 
           key === "deliveryPartner" ? "Delivery Partner" : 
           key === "packagingCharge" ? "Packaging Charge" : 
           key === "deliveryCharge" ? "Delivery Charge" : key}
        </td>
        <td style={{ borderBottom: "1px solid black", height: "29px" }}>
          {value?.toFixed(decimalPosition) || "0.00"}
        </td>
      </tr>
    ))
) : (
  <tr>
    <td colSpan={2}>NO DATA</td>
  </tr>
)}

    </tbody>
  </table>
)}

<div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(6),
      }}
    >
      <h5>Tax</h5>
      <h5>
        {(ShiftBillTypeSummary?.tax?.total ||0)?.toFixed(decimalPosition)}
      </h5>
    </div>
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            <th>Tax Name</th>
            <th>Tax%</th>
            <th>Amount</th>
           
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.tax?.list !== undefined ? (
            ShiftBillTypeSummary?.tax?.list?.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.name}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.percentage}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
              
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}
<div
      className="bill-sub-head"
      style={{
        backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(7),
      }}
    >
      <h5>Device</h5>
      <h5>
        {(ShiftBillTypeSummary?.device?.total ||0)?.toFixed(decimalPosition)}
      </h5>
    </div>
    {showSubHeaders && (
      <table>
        <thead style={{ backgroundColor: "#C0CFF8" }}>
          <tr>
            <th>Device Name</th>
            <th>Order Count</th>
            <th>Amount</th>
           
          </tr>
        </thead>
        <tbody>
          {ShiftBillTypeSummary?.device?.list !== undefined ? (
            ShiftBillTypeSummary?.device?.list?.map((r, i) => (
              <tr key={i}>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.employeeName}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.count}
                </td>
                <td
                  style={{ borderBottom: "1px solid black", height: "29px" }}
                >
                  {r.amount?.toFixed(decimalPosition)}
                </td>
              
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>NO DATA</td>
            </tr>
          )}
        </tbody>
      </table>
    )}

  
  
  </div>
  )
}

export default BillTypeTemplateSummary