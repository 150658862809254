import React, { useEffect, useState } from 'react'
import { SingleViewPosOrderAPI } from '../GeneralAPI'
import { useSelector } from 'react-redux'
import Return from "../../../../../Assets/statusRibbon/return.png"
import Draft from "../../../../../Assets/statusRibbon/draft.png" 
import ActiveRibbon from "../../../../../Assets/statusRibbon/post.png"
import { Rate } from 'antd'

const PosOrdersHeader = ({ singleId }) => {
    const decimalPosition=localStorage.getItem("decimalPosition")
    const [selectedButton, setSelectedButton] = useState("Products");

    const singleView = useSelector((state) => state.generalSlice.orderListSingleView)
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    useEffect(() => {
        singleId !== null && SingleViewPosOrderAPI({ _id: singleId })
    }, [])
  return (
    <div className="global-white-bg-container">
                <div className="ribbon-ctn">
                    {singleView?.status === "RETURN" && <img src={Return} alt="" />}
                    {singleView?.status === "DONE" && <img src={ActiveRibbon} alt="" />}
                    {singleView?.status === "DRAFT" && <img src={Draft} alt="" />}
                </div>
                <div className="justify-space-between single-viewinfo-parent-ctn" style={{alignItems:"unset" }}>
                    <div className="single-view-left-ctn">
                       
                        <h3 style={{ margin: "0" }}>{singleView?.orderNo}</h3>
                       
                        <div className="justify-space-between">
                            <div className="single-view-info-ctn-left flex-column">
                                <p>Date</p>
                                <p>Branch</p>
                                <p>Shift No </p>
                                <p>Employee</p>
                                {singleView?.orderType === "DINEIN" && (
                                    <>
                                        <p>Table</p>
                                        <p>Chair</p>
                                    </>
                                )}
                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p>{singleView?.orderDate}</p>
                                <p>{singleView?.branchName}</p>
                                <p>{singleView?.shiftId}</p>
                                <p>{singleView?.employeeName}</p>
                                {singleView?.orderType === "DINEIN" && (
                                    <>
                                        <p className="combo-list">{singleView?.tables.map((item) => item.tableName).join(', ')}</p>
                                        <p className="combo-list">{singleView?.chair.map((item) => item.chairName).join(', ')}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                       <div style={{display:"flex",justifyContent:"end",paddingRight:"90px"}}>
                       <Rate
                          value={singleView?.custRating}  
                          disabled={true}  
                          /></div>
                        <div className="justify-space-between" style={{ paddingTop: "30px", paddingRight: "90px"}}>
                          
                            <div className="single-view-info-ctn-left flex-column">
                                <p>Customer</p>
                                <p>Mobile</p>
                                <p>Order Type</p>
                                {singleView?.orderType === "DELIVERY" && (
                                    <>
                                        <p>Aggregator </p>
                                    </>
                                )}
                            </div>
                            <div className="single-view-info-ctn-right flex-column">
                                <p>{singleView?.customerName}</p>
                                <p>{singleView?.mobile}</p>

                                <p>{singleView?.orderType}</p>
                                {singleView?.orderType === "DELIVERY" && (
                                    <>
                                        <p>{singleView?.aggregatorName}</p>
                                    </>
                                )}
                            </div>
                        </div>
                        {singleView?.custComment && (
                <div className="feedback-box" style={{ paddingInline: "10px", marginTop: "20px", backgroundColor: "#f0f0f0", borderRadius: "5px", border: "1px solid #ddd",width:"auto" }}>
                 
                    <p>{singleView?.custComment}</p>
                </div>
            )}
                    </div>
                </div>



                <div className="button-div" style={{ padding: "0px", paddingInline: "30px", paddingTop: "19px" }}>

                    <button
                        className={selectedButton === 'Products' ? 'selected-button' : ''}
                        onClick={() => handleButtonClick('Products')}
                    >
                        Products
                    </button>

                    <button
                        className={selectedButton === 'Payments' ? 'selected-button' : ''}
                        onClick={() => handleButtonClick('Payments')}
                    >
                        Payments
                    </button>


                    <button
                        className={selectedButton === 'KOT order' ? 'selected-button' : ''}
                        onClick={() => handleButtonClick('KOT order')}
                    >
                        KOT order
                    </button>

                </div>
                {selectedButton === "Products" && (
                    <>
                        <div className="global-product-table special-items-table" style={{ paddingInline: "29px" }}>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Product ID</th>
                                        <th>Product Name</th>
                                        <th>Qty</th>
                                        <th>UOM</th>
                                        <th>Rate</th>
                                        <th>Amount</th>
                                        <th>VAT%</th>
                                        <th>VAT</th>
                                        <th>Net Amount</th>
                                    </tr>
                                </thead>
                                <tbody style={{ borderBottom: '2px solid #dee3f8' }}>
                                    {singleView?.orderInfo?.list?.lenght !== 0 ? (singleView?.orderInfo?.list.map((r, i) => (
                                        <tr key={i}>
                                            <td>{r.itemcode}</td>
                                            <td>{r.itemName}</td>
                                            <td>{r.qty}</td>
                                            <td>{r.uomName}</td>
                                            <td>{r.rate}</td>
                                            <td>{r.amount}</td>
                                            <td>{r.vat}</td>
                                            <td>{r.vatAmount}</td>
                                            <td>{r.netAmnt}</td>
                                        </tr>
                                    ))) : (
                                        <tr>
                                            <td colSpan={9}>NO DATA</td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot style={{ textAlignLast: "end", backgroundColor: " #efeded" }}>
                                    <td style={{ fontWeight: "bold" }}>TOTAL</td>
                                    <td colSpan={5} style={{ paddingRight: "30px" }}>{singleView?.orderInfo?.amountTotal}</td>
                                    <td colSpan={1} style={{ paddingRight: "58px" }}>{singleView?.orderInfo?.vatAmountTot}</td>
                                    <td colSpan={2} style={{ paddingRight: "58px" }}>{singleView?.orderInfo?.netAmntTotal}</td>
                                </tfoot>
                            </table>

                        </div>
                        <div className="last-total-div" style={{ backgroundColor: "white" }}>
                            <div className="sub-total">
                                <h1>Total Before VAT</h1>
                                {singleView?.taxlist?.map((r, i) => (
                                    <p key={i}>{r.taxName}</p>
                                ))}
                                <p>Discount</p>
                                <p>Delivery Charge</p>
                                <p>Packaging Charge</p>
                                <hr className='line-2' />
                                <h1>Total INC VAT</h1>
                                <p>Amount Paid</p>
                                <p style={{ color: "red" }}>Amount Due</p>
                            </div>
                            <div className="sub-total-amount">
                                <h1>{singleView?.amountBeforeVat}</h1>
                                {singleView?.taxlist?.map((r, i) => (
                                    <p key={i}>{r.taxAmount}</p>
                                ))}
                                <p>{singleView?.discount}</p>
                                <p>{singleView?.deliveryCharge !== null ? singleView?.deliveryCharge : "0.00"}</p>
                                <p>{singleView?.pkgCharge !== null ? Number(singleView?.pkgCharge)?.toFixed(decimalPosition) : "0.00"}</p>
                                <hr className='line-3' />
                                <h1>{singleView?.totalIncVat}</h1>
                                <p>{singleView?.paidAmount}</p>
                                <p>{singleView?.amountDue}</p>
                            </div>
                        </div>
                    </>
                )}
                {selectedButton === "Payments" && (
                    <div className="global-product-table special-items-table" style={{ paddingInline: "29px" }}>
                        <table >
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Payment Methord</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: '2px solid #dee3f8' }}>
                                {singleView?.paymentList?.list?.lenght !== 0 ? (singleView?.paymentList?.list?.map((r, i) => (
                                    <tr key={i}>
                                        <td>{r?.paymentDate}</td>
                                        <td>{r.paymentType}</td>
                                        <td>{r.vendorName}</td>
                                        <td>{r.amount}</td>
                                    </tr>
                                ))) : (
                                    <tr>
                                        <td colSpan={4}>NO DATA</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot style={{ textAlignLast: "end", backgroundColor: " #efeded" }}>
                                <td style={{ fontWeight: "bold" }}>TOTAL</td>
                                <td colSpan={3} style={{ paddingRight: "30px" }}>{singleView?.paymentList?.totalPaid}</td>

                            </tfoot>
                        </table>

                    </div>

                )}

                {selectedButton === "KOT order" && (
                    <div className="global-product-table special-items-table" style={{ paddingInline: "29px" }}>
                        <table >
                            <thead>
                                <tr>
                                    <th>Sequence</th>

                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: '2px solid #dee3f8' }}>
                                {singleView?.kotList?.lenght !== 0 ? (singleView?.kotList.map((r, i) => (
                                    <tr key={i}>
                                        <td>{r.sequenceName}</td>
                                        <td>{r.status}</td>
                                    </tr>
                                ))) : (
                                    <tr>
                                        <td colSpan={2}>NO DATA</td>
                                    </tr>
                                )}
                            </tbody>

                        </table>

                    </div>

                )}
            </div>
  )
}

export default PosOrdersHeader