import React, { useEffect, useRef, useState } from "react";
import ReplyIcon from "@mui/icons-material/Reply";
import ReactToPrint from "react-to-print";
import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import SalesTable from "./PosShiftReportTables/SalesTable";
import Payment from "./PosShiftReportTables/Payment";
import Wallet from "./PosShiftReportTables/Wallet";
import ProductSummary from "./PosShiftReportTables/ProductSummary";
import PaymentSummary from "./PosShiftReportTables/PaymentSummary";
import icon from "../../../../Assets/Images/Icon ionic-ios-share-alt.png";
import {
  viewAllAllowedBranchesAPICall,
  viewAllBranchesAPICall,
} from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { useSelector } from "react-redux";
import {
  ListPosPaymentSummaryAPI,
  ListPosShifReportAPI,
  ListPosShifReportSalesAPI,
  ListPosShifReportWalletAPI,
} from "./ReportAPI";
import { viewShiftAPICall } from "../../../../API/Settings/Shift/viewShiftAPI";
import { viewPosSettingsAPICall } from "../Configuration/Settings/posSettingsAPI";
import store from "../../../../Redux/store";
import { useDispatch } from "react-redux";
import {
  get_shift_report_list,
  get_shift_report_payment,
  get_shift_report_payment_summary,
  get_shift_report_sale,
  get_shift_report_wallet_pay,
} from "./ReportSlice";
import Template from "./PosShiftReportPrint";
import ExcelJS from "exceljs";

const PosShiftReport = () => {
  let paymentsPrint = useRef();

  const dispatch = useDispatch();

  // console.log(branch);
  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const shiftSalesReport = useSelector(
    (state) => state.posReportshiftSlice.shiftReportSale
  );

  const shiftReportpayment = useSelector(
    (state) => state.posReportshiftSlice.shiftReporPayment
  );

  const paymentSummmary = useSelector(
    (state) => state.posReportshiftSlice.shiftReporPaymentSummary
  );

  const productSummmary = useSelector(
    (state) => state.posReportshiftSlice.shiftReport
  );

  const shiftReportwallet = useSelector(
    (state) => state.posReportshiftSlice.shiftReportWalletPay
  );
  const posSettingsList = useSelector(
    (state) => state.posSettingSlice.apiResList
  );

  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );

  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userInfo = useSelector((state) => state.loginResponseSlice.value);

  const allShiftList = useSelector((state) => state.viewShiftSlice.value);
  // console.log(allShiftList);
  const decimalPosition = localStorage.getItem("decimalPosition");
  // const userRole = useSelector((state) => state.userRoleSlice.value);
  const [selectedButton, setSelectedButton] = useState("sales");
  const [branch, setBranch] = useState(null);
  const [shift, setShift] = useState(null);
  const [shiftDetails, setShiftDetails] = useState();

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    if (buttonName === "payment") {
      setShiftDetails(shiftReportpayment);
    } else if (buttonName === "wallet") {
      setShiftDetails(shiftReportwallet);
    } else if (buttonName === "product Summary") {
      setShiftDetails(productSummmary);
    } else {
      setShiftDetails(paymentSummmary);
    }
  };

  const formatDate = (dateTimeString) => {
    return dateTimeString?.split(" ")[0]; // Split the string by space and return the first part (the date)
  };

  const handleSubmit = () => {
    let body = {
      branchId: branch?._id,
      shiftId: shift?._id,
      index: 0,
    };
    let wallet = {
      branchId: branch?._id,
      shiftId: shift?._id,
      index: 0,
      isWallet: true,
    };
    let payment = {
      branchId: branch?._id,
      shiftId: shift?._id,
      index: 0,
      isWallet: false,
    };
    ListPosShifReportSalesAPI(body, setShiftDetails);
    ListPosShifReportAPI(body);
    ListPosShifReportWalletAPI(wallet);
    ListPosShifReportWalletAPI(payment);
    ListPosPaymentSummaryAPI(body);
  };

  const branchOnchange = (e, newValue) => {
    setBranch(newValue);
    setShift(null);
    viewShiftAPICall({ branchId: newValue?._id });
  };

  const generateExcelReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Shift Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Shift Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:P1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    const styles = { 
      totalRowStyle: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFDFD9D9' } },
      mainHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF370C0C' } },
     headerRow: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFEDF2F9' } },
    detailsRow: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } },
  };

    // Add shift details
    if (shiftDetails) {
      const shiftIdRow = sheet.addRow([
        `Shift ID: ${shiftDetails?.shiftData?.shiftId || "-NIL-"}`,
      ]);
      shiftIdRow.font = { size: 15, bold: true };
      sheet.mergeCells(`A${sheet.lastRow.number}:P${sheet.lastRow.number}`);
      shiftIdRow.eachCell((cell) => {
        cell.fill = styles.detailsRow;
      });
      const startByRow = sheet.addRow([
        `Start by: ${shiftDetails?.shiftData?.startedBy || ""}`,
        "",
        "",
        `End by: ${shiftDetails?.shiftData?.endedBy || ""}`,
      ]);
      startByRow.font = { size: 12, bold: false };
      sheet.mergeCells(`A${sheet.lastRow.number}:C${sheet.lastRow.number}`);
      sheet.mergeCells(`G${sheet.lastRow.number}:P${sheet.lastRow.number}`);
      startByRow.eachCell((cell) => {
        cell.fill = styles.detailsRow;
      });

      const startDateRow = sheet.addRow([
        `Start Date: ${shiftDetails?.shiftData?.startDate || ""}`,
        "",
        "",
        `End Date: ${shiftDetails?.shiftData?.endDate || ""}`,
      ]);
      startDateRow.font = { size: 12, bold: false };
      sheet.mergeCells(`A${sheet.lastRow.number}:C${sheet.lastRow.number}`);
      sheet.mergeCells(`G${sheet.lastRow.number}:P${sheet.lastRow.number}`);
      startDateRow.eachCell((cell) => {
        cell.fill = styles.detailsRow;
      });

      sheet.addRow(['']); // Blank row
      const salesRow = sheet.addRow(['Sales', '', '', '', '', '', '',""]);
      salesRow.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
      for (let i = 1; i <= 8; i++) {
        salesRow.getCell(i).fill = styles.mainHead;
      }

      // Add table headers
      // Add table headers with merged cells
      const headerRow = sheet.addRow([
        "Order Date",
        "Order Ref",
        "Customer",
        "Mobile",
        "Delivery Charge",
        "Order Amount",
        "Paid Amount",
        "Balance",
      ]);
      headerRow.font = { size: 12, bold: true };
      headerRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = styles.headerRow
      });

      // Adjust column widths
      [20, 20, 20, 20, 20, 20, 20, 20].forEach((width, index) => {
        sheet.getColumn(index + 1).width = width;
      });

      // Initialize totals
      let totalOrderAmount = 0;
      let totalPaidAmount = 0;
      let totalBalance = 0;

      // Add data rows for Sales
      if (Array.isArray(shiftSalesReport?.sale)) {
        shiftSalesReport?.sale?.forEach((order) => {
         const row= sheet.addRow([
            order.orderDate,
            order.name,
            order.customerName,
            order.mobileNo,
            order.deliveryCharge,
            order.amtTotal,
            order.paidAmt,
            order.amountDue,
          ]);
          row.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "right" };
          });
          // Accumulate totals
          totalOrderAmount += Number(order.amtTotal) || 0;
          totalPaidAmount += Number(order.paidAmt) || 0;
          totalBalance += Number(order.amountDue) || 0;
        });
      }

      // Add total row
      const totalRow = sheet.addRow([
        "",
        "",
        "",
        "TOTAL",
        "",
        totalOrderAmount.toFixed(decimalPosition),
        totalPaidAmount.toFixed(decimalPosition),
        totalBalance.toFixed(decimalPosition),
      ]);
      totalRow.font = { size: 12, bold: true };
      for(let i=1;i<=8;i++){
        totalRow.getCell(i).fill=styles.totalRowStyle
      }
     
      totalRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
           ///////////////PAYMENTS///////////////////
      sheet.addRow(['']);
      const paymentsRow = sheet.addRow(['Payments', '', '', '', '', '', '',""]);
      paymentsRow.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } }; 
      for (let i = 1; i <= 6; i++) {
        paymentsRow.getCell(i).fill = styles.mainHead;
      }
      const headerRow2 = sheet.addRow([
        "Date",
        "Payment Reff",
        "Order Reff",
        "Customer",
        "Payment Method",
        "Amount",
      ]);
      headerRow2.font = { size: 12, bold: true };
      headerRow2.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = styles.headerRow
      });
      shiftReportpayment?.sale?.forEach((payment, index) => {
        const row = sheet.addRow([
          payment?.paymentDate.split(' ')[0], // Format date
          payment?.name,
          payment?.orderName,
          payment?.customerName,
          payment?.type,
          Number(payment?.amount).toFixed(decimalPosition), // Format amount
        ]);
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
      });
    
      // Add Total row
      const totalAmount = shiftReportpayment?.sale?.reduce(
        (acc, curr) => acc + Number(curr.amount || 0),
        0
      );
    
      const totalRow2 = sheet.addRow(["", "", "", "TOTAL", "", totalAmount.toFixed(decimalPosition)]);
      totalRow2.font = { size: 12, bold: true };
      for(let i=1;i<=6;i++){
        totalRow2.getCell(i).fill=styles.totalRowStyle
      }
     
      totalRow2.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
              //////////////////// WALLET ///////////////////
      sheet.addRow(['']);
      const walletRow = sheet.addRow(['Wallet', '', '', '', '', '', '',""]);
      walletRow.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } }; 
      for (let i = 1; i <= 6; i++) {
        walletRow.getCell(i).fill = styles.mainHead;
      }
      const headerRow3 = sheet.addRow([
        "Date",
        "Payment Reff",
        "Order Reff",
        "Customer",
        "Payment Method",
        "Amount",
      ]);
      headerRow3.font = { size: 12, bold: true };
      headerRow3.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = styles.headerRow;
      });
      shiftReportwallet?.sale?.forEach(wallet => {
        const walletRow = sheet.addRow([
            wallet?.paymentDate?.split(' ')[0],
            wallet?.name,
            wallet?.orderName,
            wallet?.customerName,
            wallet?.type,
            Number(wallet?.amount),
        ]);
        walletRow.eachCell(cell => {
            cell.alignment = { vertical: "middle", horizontal: "right" };
        });
    });

    // Wallet Total Row
    const totalWalletAmount = shiftReportwallet?.sale?.reduce(
        (acc, curr) => acc + Number(curr.amount || 0), 0
    );

    const totalWalletRow = sheet.addRow(["", "", "", "TOTAL", "", totalWalletAmount]);
    totalWalletRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 6; i++) {
        totalWalletRow.getCell(i).fill = styles.totalRowStyle;
    }
    totalWalletRow.eachCell(cell => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
    });

    /////////////////////////// PRODUCT SUMMARY //////////////////////
    sheet.addRow(['']); // Blank row
    const productSummaryRow = sheet.addRow(['Product Summary', '', '', '', '', '', '',""]);
    productSummaryRow.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
    for (let i = 1; i <= 3; i++) {
      productSummaryRow.getCell(i).fill = styles.mainHead;
    }

    // Add table headers
    // Add table headers with merged cells
    const headerRow4 = sheet.addRow([
      "Product",
      "Qty",
      "Amount",
     
    ]);
    headerRow4.font = { size: 12, bold: true };
    headerRow4.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.headerRow
    });
    if (Array.isArray(productSummmary?.sale)) {
      productSummmary.sale.forEach((product) => {
          const row = sheet.addRow([product.productName, product.totalQty, Number(product.amount).toFixed(decimalPosition)]);
          row.eachCell((cell) => {
              cell.alignment = { vertical: 'middle', horizontal: 'right' };
          });
      });
  }
  const totalProductAmount = productSummmary?.sale?.reduce(
      (acc, curr) => acc + Number(curr.amount || 0),
      0
  );
  
  const totalProductRow = sheet.addRow(['', 'TOTAL', totalProductAmount.toFixed(decimalPosition)]);
  totalProductRow.font = { size: 12, bold: true };
  for (let i = 1; i <= 3; i++) {
      totalProductRow.getCell(i).fill = styles.totalRowStyle;
  }
  
  totalProductRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'right' };
  });

  /////////////////////////////// PAYMENT SUMMARY ////////////////////////////////
  sheet.addRow(['']); // Blank row
  const paymentSummaryRow = sheet.addRow(['Payment Summary', '', '', '', '', '', '',""]);
  paymentSummaryRow.font = { size: 14, bold: true, color: { argb: 'FFFFFFFF' } };
  for (let i = 1; i <= 2; i++) {
    paymentSummaryRow.getCell(i).fill = styles.mainHead;
  }

  // Add table headers
  // Add table headers with merged cells
  const headerRow5 = sheet.addRow([
    "Payment Method",
    "Amount",
   
  ]);
  headerRow5.font = { size: 12, bold: true };
  headerRow5.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.headerRow
  });
  // Add data rows for Payment Summary
if (Array.isArray(paymentSummmary?.sale)) {
  paymentSummmary.sale.forEach((payment) => {
      const row = sheet.addRow([
          payment.type,
          Number(payment.amount).toFixed(decimalPosition),
      ]);
      row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
      });
  });
}

// Add total row for Payment Summary
const totalPaymentAmount = paymentSummmary?.sale?.reduce(
  (acc, curr) => acc + Number(curr.amount || 0),
  0
);

const totalPaymentRow = sheet.addRow(['TOTAL', totalPaymentAmount.toFixed(decimalPosition)]);
totalPaymentRow.font = { size: 12, bold: true };
for (let i = 1; i <= 2; i++) {
  totalPaymentRow.getCell(i).fill = styles.totalRowStyle;
}

totalPaymentRow.eachCell((cell) => {
  cell.alignment = { vertical: 'middle', horizontal: 'right' };
});
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "PosShiftReport.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // console.log(branch);
  useEffect(() => {
    userRole === "user" &&
      viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });

    viewAllBranchesAPICall();
    viewPosSettingsAPICall();
    store.dispatch(get_shift_report_sale(undefined));
    store.dispatch(get_shift_report_list(undefined));
    store.dispatch(get_shift_report_payment(undefined));
    store.dispatch(get_shift_report_wallet_pay(undefined));
    store.dispatch(get_shift_report_payment_summary(undefined));
  }, []);
  return (
    <div className="global-page-parent-container">
      <div
        className="global-white-bg-container Header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 30px 0 25px",
          alignItems: "center",
        }}
      >
        <p>Shift Report</p>
        <div>
          {/* <div className="ribbon-ctn">
                 <img src={icon} alt='' />
                        </div> */}

          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            content={() => paymentsPrint}
          />

          <Tooltip title="Download">
            <IconButton onClick={generateExcelReport}>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        className="global-white-bg-container "
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          className="new-global-single-input auto-complete-new"
          style={{ width: "26%", paddingInline: "20px" }}
        >
          <Autocomplete
            disablePortal
            options={
              userRole === "admin" ? allBranchesList : allowedBranchList || []
            }
            getOptionLabel={(option) => option?.branchName}
            placeholder="Branch"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Branch" focused placeholder="" />
            )}
            value={branch}
            onChange={branchOnchange}
            //   disabled={formValues?.isEdit}
          />
        </div>
        <div
          className="new-global-single-input auto-complete-new"
          style={{ width: "28%", padding: "0 55px 0 14px" }}
        >
          <Autocomplete
            disablePortal
            options={(
              allShiftList?.filter((obj) => obj.branchId === branch?._id) || []
            ).reverse()}
            getOptionLabel={(option) => option?.SHIFID}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Shift" focused placeholder="" />
            )}
            value={shift}
            onChange={(e, newValue) => setShift(newValue)}
            //   disabled={formValues?.isEdit}
          />
        </div>
        <button className="create-button-blue" onClick={handleSubmit}>
          Submit
        </button>
        {(shiftSalesReport !== undefined ||
          shiftReportwallet !== undefined ||
          productSummmary !== undefined ||
          paymentSummmary !== undefined ||
          shiftReportpayment !== undefined) && (
          <div
            className="single-view-left-ctn"
            style={{
              width: "60%",
              minWidth: "fit-content",
              maxWidth: "550px",
              padding: "16px",
              margin: "1%",
              marginBottom: "0",
            }}
          >
            <h3 style={{ margin: "0", cursor: "pointer", fontSize: "larger" }}>
              {shiftDetails?.shiftData?.shiftId}
            </h3>
            <div className="justify-space-between" style={{ display: "flex" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="single-view-info-ctn-left flex-column">
                  {(shiftDetails?.shiftData?.startedBy !== "" ||
                    shiftDetails?.shiftData?.startedBy !== null) && (
                    <p style={{ margin: "1px" }}>Shift by:</p>
                  )}
                  {(shiftDetails?.shiftData?.startDate !== "" ||
                    shiftDetails?.shiftData?.startDate !== null) && (
                    <p style={{ margin: "1px" }}>Start Date</p>
                  )}
                </div>
                <div className="single-view-info-ctn-right flex-column">
                  <p style={{ margin: "1px" }}>
                    {formatDate(shiftDetails?.shiftData?.startedBy)}{" "}
                  </p>
                  <p style={{ margin: "1px" }}>
                    {formatDate(shiftDetails?.shiftData?.startDate)}
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="single-view-info-ctn-left flex-column">
                  {(shiftDetails?.shiftData?.endedBy !== "" ||
                    shiftDetails?.shiftData?.endedBy !== null) && (
                    <p style={{ margin: "1px" }}>End by:</p>
                  )}
                  {(shiftDetails?.shiftData?.endDate !== "" ||
                    shiftDetails?.shiftData?.endDate !== null) && (
                    <p style={{ margin: "1px" }}>End Date</p>
                  )}
                </div>
                <div className="single-view-info-ctn-right flex-column">
                  <p style={{ margin: "1px" }}>
                    {formatDate(shiftDetails?.shiftData?.endedBy)}
                  </p>
                  <p style={{ margin: "1px" }}>
                    {formatDate(shiftDetails?.shiftData?.endDate)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {(shiftSalesReport !== undefined ||
        shiftReportwallet !== undefined ||
        productSummmary !== undefined ||
        paymentSummmary !== undefined ||
        shiftReportpayment !== undefined) && (
        <div
          style={{ marginTop: "8px", backgroundColor: "white", height: "74vh" }}
          className="flex-column"
        >
          <div className="button-div" style={{ padding: "20px 0 0 17px" }}>
            <button
              className={selectedButton === "sales" ? "selected-button" : ""}
              onClick={() => handleButtonClick("sales")}
            >
              SALES
            </button>

            <button
              className={selectedButton === "payment" ? "selected-button" : ""}
              onClick={() => handleButtonClick("payment")}
            >
              PAYMENT
            </button>

            {posSettingsList?.activeWalletStatus === true && (
              <button
                className={selectedButton === "wallet" ? "selected-button" : ""}
                onClick={() => handleButtonClick("wallet")}
              >
                WALLET
              </button>
            )}
            <button
              className={
                selectedButton === "product Summary" ? "selected-button" : ""
              }
              onClick={() => handleButtonClick("product Summary")}
            >
              PRODUCT SUMMARY
            </button>
            <button
              className={
                selectedButton === "payment summary" ? "selected-button" : ""
              }
              onClick={() => handleButtonClick("payment summary")}
            >
              PAYMENT SUMMARY
            </button>
          </div>
          {selectedButton === "sales" && <SalesTable />}
          {selectedButton === "payment" && <Payment />}
          {selectedButton === "wallet" && <Wallet />}
          {selectedButton === "product Summary" && <ProductSummary />}
          {selectedButton === "payment summary" && <PaymentSummary />}
        </div>
      )}

      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrint = el)}>
          <Template branch={branch} shift={shift} />
        </div>
      </div>
    </div>
  );
};

export default PosShiftReport;
