import React, { useState } from 'react'
import SelfOrderInput from '../Input/SelfOrderInput'
import SelfLoginBtn from '../Buttons/SelfLoginBtn'
import BackButton from '../Buttons/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import { setScreenSelected } from '../../Slices/selfOrderMainSlice'
import { message } from 'antd'
import { selfAddCustomerAPI } from '../../API/selfOrderAPIS'
import { setSelfOrderScreen } from '../Order/selfOrderEssentialsSlice'
const SelfRegisterPage = () => {

    const dispatch = useDispatch()
    const [expand, setExpand] = useState(false)
    const {selfMobileNo}=useSelector((state)=>state.selfOrderEssentialsSlice)
    const userInfo = useSelector((state) => state.loginResponseSlice.value);

    const inputsData = [
        { id: 'name', placeholder: 'Name' },
        { id: 'mobileNo', placeholder: 'Mobile' },
        { id: 'city', placeholder: 'City' },
        { id: 'streetName', placeholder: 'Street' },
        { id: 'buildingName', placeholder: 'Building Name' },
        { id: 'buildingNo', placeholder: 'Building No' },
        { id: 'roomNo', placeholder: 'Room No' },
    ];

    const [formValues, setFormValues] = useState({
        name: "",
        mobileNo: selfMobileNo,
        city: "",
        streetName: "",
        buildingName: "",
        buildingNo: "",
        roomNo: "",
    })

    const handleInputChange = (key) => (e) => {
        setFormValues({ ...formValues, [key]: e.target.value })
    }
    const registerClick = () => {
        if (formValues.name === "") {
            message.error("Please enter a name")
        } else if (formValues.mobileNo === "" || formValues?.mobileNo === null) {
            message.error("Mobile number is required")
        }else{
            let customerFormData=new FormData()
            customerFormData.append("name", formValues?.name)
            customerFormData.append("mobileNo", formValues?.mobileNo)
            formValues.city!=="" && customerFormData.append("city",formValues.city)
            formValues.street!=="" && customerFormData.append("streetName",formValues.streetName)
            formValues.buildingNo!=="" && customerFormData.append("buildingNo",formValues.buildingNo)
            formValues.buildingName!=="" && customerFormData.append("buildingName",formValues.buildingName)
            formValues.roomNo!=="" && customerFormData.append("roomNo",formValues.roomNo)

            customerFormData.append("branchId", userInfo?.branchPk)
            customerFormData.append("status", true)
            customerFormData.append("isCustomer", true)
            customerFormData.append("isVendor", false)
            selfAddCustomerAPI(customerFormData)
        }
    }
    return (
        <div className='self-login-ctn'>
            <BackButton onClick={() => dispatch(setSelfOrderScreen("loginScreen"))} />
            <div className="self-login-input-ctn">
                <h3>REGISTER</h3>
                {
                    !expand ?
                        <div className="self-login-body">
                            <SelfOrderInput
                                value={formValues?.name}
                                onChange={handleInputChange("name")}
                                placeholder="Name"
                            />
                            <SelfOrderInput
                                value={formValues?.mobileNo}
                                onChange={handleInputChange("mobileNo")}
                                placeholder="Mobile"
                            />
                            <p className='red-action' onClick={() => setExpand(true)}>Add More Details +</p>
                        </div>
                        :
                        <div className="self-register-input-ctn">
                            {inputsData.map((input) => (
                                <SelfOrderInput
                                    key={input.id}
                                    value={formValues[input.id]}
                                    onChange={handleInputChange(input.id)}
                                    placeholder={input.placeholder}
                                />
                            ))}
                        </div>
                }
                <SelfLoginBtn
                    label='Register'
                    onClick={registerClick}
                />
            </div>

        </div>
    )
}

export default SelfRegisterPage