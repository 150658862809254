import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { selfDialogScreenSelected } from '../../Slices/selfOrderMainSlice';
const MenuButton = () => {
  const dispatch=useDispatch()
  return (
    <div className='self-menu-btn'>
    <IconButton onClick={()=>dispatch(selfDialogScreenSelected("passwordDialog"))} style={{border:"1px solid #000"}}>
        <MenuIcon className='responsive-arrow-icon'/>
    </IconButton>
    </div>
  )
}

export default MenuButton