import React from 'react'

const SelfLoginBtn = ({ label = 'BUTTON',style={}, onClick, className = '', icon = '', ...props }) => {
  return (
    <button style={style} className={`self-order-login-btn ${className}`} onClick={onClick} {...props}>
        <i className="self-order-login-animation"></i>
            {label}
        <i class="self-order-login-animation"></i>
    </button>
  )
}

export default SelfLoginBtn