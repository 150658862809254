import React, { useEffect, useState } from "react";
import "./cartListStyle.css";
import io from "socket.io-client";
import axios from "axios";
import { useSelector } from "react-redux";
import NextCustomerSvg from "../../icons/NextCustomerSvg";

const CartList = () => {
  const [cartData, setCartData] = useState();
  const { branchPk, data, ...rest } = useSelector(
    (state) => state.loginResponseSlice.value
  );

  useEffect(() => {
    const socket = io(axios.defaults.baseURL, {
      path: "/socket.io/",
      reconnection: true,
      transports: ["websocket"],
      reconnectionAttempts: 5,
    });

    socket.on("connect", () => {
      if (data && branchPk) {
        socket.emit("setUsername", data?._id, branchPk);
      }
    });

    socket.on("posBillingCart", (res) => {
      setCartData(res);
    });

    return () => {
      // Clean up event listeners
      socket.off("connect");
      socket.off("posBillingCart");
      socket.disconnect();
    };
  }, []);

  return (
    <div className="cart-list-container">
      <div className="cart-list-table-wrapper">
        <table>
          <thead>
            <tr>
              <th className="cd-text-start">Item Name</th>
              <th className="cd-tex-end">Price</th>
              <th className="cd-tex-end">Quantity</th>
              <th className="cd-tex-end">Total</th>
            </tr>
          </thead>
          <tbody>
            {cartData?.orderInfo?.length > 0 ? (
              cartData?.orderInfo?.map((data, i) => (
                <tr >
                  <td>{data?.desccription}</td>
                  <td className="cd-tex-end">{data?.unitPrice}</td>
                  <td className="cd-tex-end">{data?.qty}</td>
                  <td className="cd-tex-end">{data?.subTotalWoTax}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                
                >
                  <div   style={{height:'85vh', display: "flex", placeContent: "center",alignItems:'center',padding:'1em',flexDirection:'column' }}>
                    <NextCustomerSvg height={300} width={300}/>
                    <h3>Next Customer !!</h3>
                  </div>
                  
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>Total</td>
              <td>{cartData?.subTotal || 0}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default CartList;
