import { Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';
import SelfCheckBox from '../Single Components/SelfCheckBox';


const LoginFormMobile = ({ onChangeFn,handleNextClick,clickLoginBtn }) => {

  const { companyCode,userName,password } = useSelector((state) => state.loginFormNewSlice || {});
  const userRole = useSelector((state) => state.searchUserSlice.value);

  const isNextEnabled=!(userName===null || userName==="" || companyCode===null || companyCode==="")

  return (
    <div className='login-new-main-left-container-mobile'>
      <div className="login-text-container">
        <Typography className="login-text-mobile">
          Login
        </Typography>
        <Typography className="login-description-mobile">
          Restaurant Innovative ERP Solutions.
        </Typography>
      </div>
      <div className="login-new-form-container-mobile">
        <div className="login-form" style={{position:"relative"}}>
        {userRole?.enableSelfOrd && <SelfCheckBox style={{top:"-45px"}}/>}
          <div className="user-name-container-mobile">

            {/* <p>Company Code</p> */}
            <div className="login-new-username-wrapper">
              <input
                placeholder='Company Code'
                className="right-input-login-user"
                type={"text"}
                name="companyCode"
                id="companyCodeInput"
                value={companyCode}
                onChange={onChangeFn}
                autocomplete="off"
              />
            </div>

            <div className="login-new-username-wrapper">
              <input
                className="right-input-login-user"
                placeholder='User Name'
                type={"text"}
                name="userName"
                id="companyCodeInput"
                value={userName}
                onChange={onChangeFn}
                autocomplete="off"
              />

            </div>
            {userRole && (
                <>
                <div className="login-new-username-wrapper">
              <input
                className="right-input-login-user"
                placeholder='Password'
                type='password'
                name="password"
                id="companyCodeInput"
                value={password}
                onChange={onChangeFn}
                onKeyDown={(e) => {
                  e.key === "Enter" && clickLoginBtn();
                }}
              />

            </div>

            <button
              type="submit"
              // className="login-btn btn-hide"
              id="userNameNextBtn"
              className={password ? "login-new-btn-mbl" : "login-btn-disabled"} 
              disabled={password === "" ? true : false}
              onClick={clickLoginBtn}
            >
              Login
            </button>
                </>
              )}
          </div>
          {!userRole && 
          <button
          disabled={!isNextEnabled} 
          type="submit" 
          onClick={handleNextClick}
          className={isNextEnabled?"login-new-btn-mbl":"login-btn-disabled"} 
          id="userNameNextBtn"
          >
            Next
          </button>
          }

          <div className="login-new-content-wrapper-forgot-password-register-mobile">
            <a href="https://eyeterp.com/" target="_blank" style={{color:"#fff"}}>Register here !</a>
            <p> Forgot password &gt;&gt;&gt;</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginFormMobile