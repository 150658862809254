import React, { useEffect, useState } from 'react';
import './LoginNew.css';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { set_company_code_login, set_password_login, set_user_name } from './loginFormNewSlice';
import { finalLoginAPICall, searchUserByUSerNameAPICall } from '../../API/Login/LoginAPI';
import LoginForm from './LoginForm';
import LoginFormMobile from './LoginFormMobile';
import img1 from './../../Assets/Images/4521601.png';
import img2 from './../../Assets/Images/BNFGH.png';
import img3 from './../../Assets/Images/TYERT.png';
import EyetLogo from '../../Assets/Images/Logo.png';
import { errorSnackMsg } from '../Custom Hooks/SnackkBarUtilities';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { update_search_user_response } from '../../Redux/Login/searchUserSlice';
import { update_userRole } from '../../Redux/Login/userRoleSlice';

const LoginNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobile = useMediaQuery('(max-width:768px)');
  const { companyCode, userName, password } = useSelector(state => state.loginFormNewSlice || {});
  const userRole = useSelector(state => state.searchUserSlice.value);
  const isAuth = localStorage.getItem("isAuth");
  const selfUser = localStorage.getItem("selfUser");

  const [imageOrder, setImageOrder] = useState([img1, img2, img3]);

  // Handle image rotation
  const handleImageClick = (clickedIndex) => {
    const newOrder = [...imageOrder];
    if (clickedIndex === 0) {
      newOrder[0] = imageOrder[1];
      newOrder[1] = imageOrder[0];
    } else if (clickedIndex === 2) {
      newOrder[2] = imageOrder[0];
      newOrder[0] = imageOrder[1];
    }
    setImageOrder(newOrder);
  };

  const userRoleClearFn=()=>{
    dispatch(update_search_user_response({ searchUserData: undefined }));
    localStorage.setItem("userRole", null);
    dispatch(update_userRole({ userRole: undefined }));
  }
  // Handle input change for companyCode, userName, password
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyCode') {
      dispatch(set_company_code_login(value));
      userRoleClearFn()
    } else if (name === 'userName') {
      dispatch(set_user_name(value));
      userRoleClearFn()
    } else if (name === 'password') {
      dispatch(set_password_login(value));
    }
  };
  
  // Handle next button click
  const handleNextClick = () => {
    searchUserByUSerNameAPICall({ userName,companyCode, prefix: 'RST' },userRoleClearFn);
  };

  // Handle login submission
  const clickLoginBtn = () => {
    if(password==="" || !password){
      errorSnackMsg("Password can't be empty !!")
    }else{
      finalLoginAPICall({ userName, password, companyCode, prefix: 'RST' });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageOrder(prevOrder => [prevOrder[2], prevOrder[0], prevOrder[1]]);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

 //Redirect to userDashboard if user already logged in
 useEffect(() => {
  if (isAuth) {
    successAlert()
    setTimeout(()=>{   
      navigate(selfUser?"/userDashBoard/selfOrder":"/userdashboard/dashboardhome");
    },1500)
  }
}, [isAuth]);



  //Success Message When Logged in Successfully
  const successAlert = () => {
    Swal.fire({
      title: "Logged in",
      text: "User authorized successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const ImageCarousel = () => (
    <div className="image-scroll-container">
      {imageOrder.map((image, index) => (
        <div
          key={index}
          className={`image-item ${index === 1 ? 'center' : ''}`}
          onClick={() => handleImageClick(index)}
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}
    </div>
  );

  return (
    <div className='login-new-main-container'>
      <div className={isMobile ? 'login-new-header-container-mobile' : 'login-new-header-container'} style={{ justifyContent: isMobile ? 'center' : 'space-between' }}>
        <img className="logo" src={EyetLogo} alt="EYET logo" />
        {!isMobile && userRole?.logo && <img className="rest-logo" src={userRole?.logo} alt="REST logo" />}
      </div>

      <div className='login-new-container'>
        {isMobile ? (
          <div className='login-ctn-mobile-bg'>
            <LoginFormMobile
              onChangeFn={handleInputChange}
              handleNextClick={handleNextClick}
              clickLoginBtn={clickLoginBtn}
            />
            <ImageCarousel/>
          </div>
        ) : (
          <>
            <LoginForm
              onChangeFn={handleInputChange}
              handleNextClick={handleNextClick}
              clickLoginBtn={clickLoginBtn}
            />
            <div className='login-new-main-right-container'>
              <ImageCarousel/>
            </div>
          </>
        )}
      </div>
 </div>
  );
};

export default LoginNew;
