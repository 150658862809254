import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "../../../../../Redux/store";
import { salesQuotationFilterAPICall } from "./salesQuatationAPI";
import {IconButton, Skeleton, Tooltip} from "@mui/material";
import { get_sales_order_quot_row_data_list } from "./salesQuatationSlice";
import { convertDateFormat, getCurrentTime, previousDate, today } from "../../../../../Js/Date";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs";
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";

export const SalesQuotation = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  // State variables
  const filteredItemsInitial = {
    fromDate: previousDate,
    toDate: today,
    branchList: [],
    createdBy: [],
    status: '',
    search: '',
  };

  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredDatas, setFilteredData] = useState({});
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Hooks
  let navigate = useNavigate();
 const filteredData = useSelector((state) => state.salesQuatationSlice.quotationFilter);
  const totalPages = filteredData?.pages; 
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // Navigation function
  const createBranchTransfer = () => {
    navigate("/userdashboard/sales/orders/quotation/create");
  };

  // Row click handler
  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    store.dispatch( setFilterActive(window.location.pathname))
    navigate("/userdashboard/sales/orders/quotation/singleView");
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Sales Quotation");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Sales Quotation"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:F1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Name",
      "Date",
      "Customer",
      "Total",
      "Created By",
      "Status",
     
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Name", key: "NAME", width: 20 },
      { header: "Date", key: "quotationDate", width: 20 },
      { header: "Customer", key: "CUSTOMER", width: 25 },
      { header: "Total", key: "total", width: 25 },
      { header: "Created By", key: "CREATEDBY", width: 25 },
      { header: "Sales Quotation", key: "status", width: 15 },
    ];

    filteredData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        NAME: item?.NAME,
        quotationDate:convertDateFormat(item?.quotationDate) ,  
        CUSTOMER: item.CUSTOMER,
        total: item?.total,
        CREATEDBY: item?.CREATEDBY,
        status: item?.status,
        
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Sales Quotation.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
    !loadData && salesQuotationFilterAPICall({
        statusType: 1,
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 23:59:59`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 00:00:00`,
        createdBy: FormDataInfo?.createdBy,
        branchList: FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? '' : FormDataInfo?.status[0],
        search: FormDataInfo?.search,
        index: currentPage-1,
      },setIsLoading);
    }
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };
  }, [FormDataInfo, currentPage]);

  useEffect(() => {
    localStorage.setItem("singleViewById", "");
    store.dispatch(
      get_sales_order_quot_row_data_list({
        orderQuotationSingleData: undefined,
      })
    );
  }, []);
  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
        <div className="justify-space-between list-create-head-bar">
          <h3>Quotation</h3>
          <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList}/>
            <CategorySearchandFilter
              statusOptions={['DRAFT', 'CANCEL']}
              onData={handleDataFromChild}
              isCreatedBy={true}
              isStatus={true}
              onBranchSelect={handleBranchSelect}
              onFilterChange={handleFilterChange}
            />
            <button
              onClick={createBranchTransfer}
              className="create-button-blue btn btn-primary"
            >
              Create
            </button>
          </div>
        </div>

      <div className="justify-space-between pagination-container">
          {FormDataInfo?.branchList?.length > 0 && (
              <h3>Branch name: {selectedBranch?.storeCode}-{selectedBranch?.branchName}</h3>
          )}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            setFormDataInfo={setFormDataInfo}
          />
      </div>
     
      <div className="new-global-table-container updated-table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Total</th>
              <th>Created By</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={8}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : filteredData?.list !== undefined && filteredData?.list?.length !== 0 ? (
              filteredData?.list
                ?.slice(0)

                ?.map((r, i) => (
                  <tr onClick={() => handleClickRow(r)}>
                    <td>{r?.NAME}</td>
                    <td>{convertDateFormat(r?.quotationDate)}</td>
                    <td>{r?.CUSTOMER}</td>
                    <td>{r?.total}</td>
                    <td>{r?.CREATEDBY || ""}</td>
                    <td className="table-data-box">
                      {r?.status === "DRAFT" ? (
                        <span className="draft"> DRAFT</span>
                      ) : r?.status === "POSTED" ? (
                        <span className="post"> POST</span>
                      ) : r?.status === "CANCEL" ? (
                        <span className="cancel">CANCEL</span>
                      ) : (
                        <span>{r?.status}</span>
                      )}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={6}>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
