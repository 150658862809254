import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { useSelector } from "react-redux";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import SearchIcon from "@mui/icons-material/Search";
import { BankAndCashReportAPIcall } from "./GeneralReportAPI";
import { useNavigate } from "react-router-dom";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { getCurrentTime } from "../../../../../Js/Date";
import { IconButton, Tooltip } from "@mui/material";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Template from "./BankAndCashPrint";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";
import PrintComponent from "../../../../print/report/PrintComponent";

export const BankAndCashReport = () => {
  const ExcelJS = require("exceljs");

  const filtereDataOptions = useSelector((state) => state.listCategory);
  let componentRef = useRef();
  const [symbol, setSymbol] = useState(null);
  let navigate = useNavigate();
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const bankAndCash = useSelector((state) => state?.generalReport?.BankAndCash);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [filteredData, setFilteredData] = useState({});
  const [selectedBranch, setSelectedBranch] = useState(null);
  console.log(bankAndCash);
  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };
  const handleClickRow = (row) => {
    localStorage.setItem("singleViewById", row?._id);
    // localStorage.setItem("fromDate", `${FormDataInfo?.fromDate} 00:00:00`);
    // localStorage.setItem("toDate", `${FormDataInfo?.toDate} 23:59:59 `);
    localStorage.setItem("account", row?.accountName);
    localStorage.setItem("selectedFromDate", selectedFromDate);
    localStorage.setItem("selectedToDate", selectedToDate);

    // setSelectedHeading("Ledger Balance");
    store.dispatch(setFilterActive(window.location.pathname));
    navigate(
      "/userdashboard/accounts/report/generalReport/bankAndCashReport/singleView"
    );
  };

  const initialvalue = {
    label: "Bank and Cash",
    totalBalance: 0,
    totalCredit: 0,
    totalDebit: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };

  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Bank and Cash");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Bank and Cash"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:D1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:D${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow(["Bank", "Debit", "Credit", "Balance"]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Bank", key: "accountName", width: 50 },
      { header: "Debit", key: "debit", width: 20 },
      { header: "Credit", key: "credit", width: 20 },
      { header: "Bank and Cash Report", key: "balance", width: 20 },
    ];

    bankAndCash[0]?.data?.forEach((item) => {
      const row = sheet.addRow({
        accountName: item.accountName,
        debit: `${symbol} ${item.debit?.toFixed(2)}`,
        credit: `${symbol} ${item?.credit?.toFixed(2)}`,
        balance: `${symbol} ${item?.balance?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber === 4) {
          // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      `${symbol} ${bankAndCash[0]?.totalDebit?.toFixed(2)}`,
      `${symbol} ${bankAndCash[0]?.totalCredit?.toFixed(2)}`,
      `${symbol} ${bankAndCash[0]?.totalBalance?.toFixed(2)}`,
    ]);

    totalRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Bank and Cash.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    setFormValues({
      ...formValues,
      totalBalance:
        (bankAndCash !== undefined && bankAndCash[0]?.totalBalance) || 0,
      totalCredit:
        (bankAndCash !== undefined && bankAndCash[0]?.totalCredit) || 0,
      totalDebit:
        (bankAndCash !== undefined && bankAndCash[0]?.totalDebit) || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(bankAndCash !== undefined ? bankAndCash[0]?.data : []);
  }, [bankAndCash, companyProfileData]);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
    // Store selected dates
    setSelectedFromDate(data.fromDate);
    setSelectedToDate(data.toDate);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      let body = {
        startDate:
          FormDataInfo?.fromDate === ""
            ? ""
            : `${FormDataInfo?.fromDate} 00:00:00`,
        endDate:
          FormDataInfo?.toDate === ""
            ? ""
            : `${FormDataInfo?.toDate} 23:59:59 `,
        branchId:
          FormDataInfo?.branchList?.length === 0
            ? ""
            : FormDataInfo?.branchList[0],
        search: FormDataInfo?.search,
      };
      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData && BankAndCashReportAPIcall(body);
    }
  }, [FormDataInfo]);
  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>Bank & Cash Report</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons
            downloadClick={downloadList}
            PrintClick={handlePrint}
          />
          <CategorySearchandFilter
            statusOptions={["PURCHASE ORDER", "DONE", "CANCEL"]}
            onData={handleDataFromChild}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

      <div
        className="global-report-table-container updated-table-container"
        style={{
          height: "calc(100vh - 145px)",
          maxHeight: "calc(100vh - 145px)",
        }}
      >
        <table style={{ paddingTop: "5px" }}>
          <thead>
            <tr>
              <th>Bank</th>
              <th style={{ width: "22%" }}>Debit</th>
              <th style={{ width: "22%" }}>Credit</th>
              <th style={{ width: "22%" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {bankAndCash !== undefined &&
              bankAndCash[0]?.data?.map((item, index) => (
                <tr key={index} onClick={() => handleClickRow(item)}>
                  <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                    {item.accountName}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}&nbsp;{item.debit?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}&nbsp;{item.credit?.toFixed(decimalPosition)}
                  </td>
                  <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                    {symbol}&nbsp;{item.balance?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th>
                {symbol}&nbsp;
                {bankAndCash !== undefined &&
                  bankAndCash[0]?.totalDebit?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {bankAndCash !== undefined &&
                  bankAndCash[0]?.totalCredit?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {bankAndCash !== undefined &&
                  bankAndCash[0]?.totalBalance?.toFixed(decimalPosition)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentRef}>
          <PrintComponent pageHeight={45} header={"Bank & Cash Report"}>
            <table>
              <thead>
                <tr>
                  <th>Bank</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {bankAndCash !== undefined &&
                  bankAndCash[0]?.data?.map((item, index) => (
                    <>
                      <tr>
                        <td>{item?.accountName}</td>
                        <td>
                          {symbol}&nbsp;{item?.debit?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{item?.credit?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {item?.balance?.toFixed(decimalPosition)}
                        </td>
                      </tr>
                      {bankAndCash[0]?.data?.length - 1 === index && (
                        <tr className="new-layout-print-footer">
                          <td>Total</td>
                          <td>
                            {symbol}&nbsp;
                            {bankAndCash !== undefined &&
                              bankAndCash[0]?.totalDebit?.toFixed(
                                decimalPosition
                              )}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {bankAndCash !== undefined &&
                              bankAndCash[0]?.totalCredit?.toFixed(
                                decimalPosition
                              )}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {bankAndCash !== undefined &&
                              bankAndCash[0]?.totalBalance?.toFixed(
                                decimalPosition
                              )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
              </tbody>
              <tfoot></tfoot>
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
};
