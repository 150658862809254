import React, { useEffect, useState } from 'react'
import "./posOrders.css"
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { IconButton, Tooltip } from '@mui/material'
import ReactToPrint from 'react-to-print'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ListPosOrdersAPI } from '../GeneralAPI'
import { getCurrentTime } from '../../../../../Js/Date'
import TableLoading from "../../../../Single Components/TableLoading"
import { Rate } from 'antd'
import ExcelJS from "exceljs"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons'

function PosOrders() {
  const navigate=useNavigate()
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const [filteredData, setFilteredData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (page) => {
    
    setCurrentPage(page);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
const orderList=useSelector((state)=>state.generalSlice.orderList)
const totalPages = orderList?.pages; 
  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
};
const singleRowClick=(row)=>{
  localStorage.setItem("singleId",row._id)
  navigate("/userdashboard/pointOfSales/general/orderSingleView")
}
const formatFilterInfo = (filters) => {
  const parts = [];

  if (filters?.toDate&&filters.fromDate) {
    parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
  }
  if (filters?.branchList) {
    parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
  }
  if (filters?.status) {
    parts.push(`status: ${filters?.status}`);
  }
  if (filters?.Ordertype) {
    parts.push(`Order type: ${filters?.Ordertype?.label}`);
  }
 
  
  return parts; // Return the array directly
};

const downloadList = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("shift ");
  sheet.properties.defaultRowHeight = 20;

  // Add header section
  const titleRow = sheet.addRow(["shift"]);
  titleRow.font = { size: 15, bold: true };
  sheet.mergeCells('A1:H1');
  titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  // Add additional header information
  const filterParts = formatFilterInfo(filteredData) || [];

  // Ensure filterParts is an array and iterate over it
  if (Array.isArray(filterParts)) {
    filterParts.forEach((part, index) => {
      const row = sheet.addRow([part]);
      row.font = { size: 11, bold: true };
      sheet.mergeCells(`A${index + 2}:H${index + 2}`);
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    });
  }

  // Add an empty row between header and table
  sheet.addRow([]);

  // Header row styling
  const headerRow = sheet.addRow(["Date", "Order No", "Shift", "Employee","Customer","Order Amount","Amount Paid","Status"]);
  const headerFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
  };
  const headerFont = {
    size: 12,
    bold: true,
    color: { argb: "FF000000" }, // White font color
  };

  headerRow.eachCell((cell, colNumber) => {
    cell.fill = headerFill;
    cell.font = headerFont;
    cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
  })

  sheet.columns = [
    { header: "Date", key: "orderDate", width: 18 },
    { header: "Order No", key: "orderNo", width: 18 },
    { header: "Shift", key: "shiftId", width: 20 },
    { header: "Employee", key: "employeeName", width: 25 },
    { header: "Customer", key: "customerName", width: 15 },
    { header: "Order Amount", key: "orderAmount", width: 15 },
    { header: "Amount Paid", key: "paidAmount", width: 15 },
    { header: "Orders", key: "status", width: 15 },

  ];

  orderList?.list?.forEach((item) => {
    const row = sheet.addRow({
      orderDate: item.orderDate,
      orderNo: item.orderNo,
      shiftId: item?.shiftId,
      employeeName: item?.employeeName,
      customerName: item?.customerName,
      orderAmount: item?.orderAmount,
      paidAmount: item?.paidAmount,
      status: item?.status,
    
    });

    const rowFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
    };
    
    row.eachCell((cell, colNumber) => {
      cell.fill = rowFill;
      cell.border = {
      //   top: { style: "thin", color: { argb: "FF000000" } },
      //   left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FFedf2f9" } },
      //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber >= 1) { // Right align all columns except the first
          cell.alignment = { horizontal: 'center' };
        }
       
    });
  });

  // Add row for totals
  // const totalRow = sheet.addRow([
  //   "Total",
  //   "",
  //   `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
  //   `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
  // ]);

  // totalRow.eachCell((cell,colNumber) => {
  //   cell.font = { bold: true, color: { argb: "FF000000" } };
  //   cell.fill = headerFill;
  //   cell.border = {
  //     top: { style: "thin", color: { argb: "FFFFFFFF" } },
  //     left: { style: "thin", color: { argb: "FFFFFFFF" } },
  //     bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
  //     right: { style: "thin", color: { argb: "FFFFFFFF" } },
  //   };
  //   if (colNumber > 1) { // Right align all columns except the first
  //     cell.alignment = { horizontal: 'right' };
  //   }
  // });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "pos orders.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
useEffect(()=>{
  if (FormDataInfo.length !== 0) {
    
    ListPosOrdersAPI({
        fromDate: FormDataInfo.fromDate === '' ? undefined : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
        endDate: FormDataInfo.toDate === '' ? undefined : `${FormDataInfo.toDate} ${getCurrentTime()}`,
        branchId: FormDataInfo?.branchList?.length === 0 ? undefined:FormDataInfo?.branchList,
        status: FormDataInfo?.status?.length === 0 ? undefined : FormDataInfo?.status[0],
        search: FormDataInfo?.search===''? undefined : FormDataInfo?.search,
        orderType: FormDataInfo?.Ordertype ==='' ? undefined : FormDataInfo?.Ordertype,
        index:currentPage-1
    },setIsLoading)
  }
},[FormDataInfo,currentPage])
  return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
    <div className="justify-space-between list-create-head-bar">
        <h3>Orders</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons downloadClick={downloadList}/>
          <CategorySearchandFilter
              statusOptions={['DONE', 'DRAFT','RETURN']}
              onData={handleDataFromChild}
              isStatus={true}
              isOrderType={true}
              onBranchSelect={()=>{}}
              isBranch={userRole==="admin"}
              onFilterChange={handleFilterChange}
          />
        </div>
    </div>

      <div className="justify-space-between pagination-container">
          <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
          />
      </div>
    
        <div className="new-global-table-container updated-table-container">
        <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Order No</th>
            <th>Shift</th>
            <th>Employee</th>
            <th>Customer</th>
            <th>Order Amount</th>
            <th>Amount Paid</th>
            <th>Status</th>
            <th>Rating</th>
          </tr>
        </thead>
        {isLoading ? (
                <TableLoading  colSpan={8} rowcount={10}/>
              ) : (
        <tbody>
            { 
            (orderList?.list!==undefined && orderList?.list.length!==0)?
            orderList?.list?.map((r,i)=>(
                    <tr key={i} onClick={()=>singleRowClick(r)}>
                        <td>{r.orderDate}</td>
                        <td>{r.orderNo}</td>
                        <td>{r.shiftId}</td>
                        <td>{r.employeeName}</td>
                        <td>{r.customerName}</td>
                        <td>{r.orderAmount}</td>
                        <td>{r.paidAmount}</td>
                        <td className="table-data-box">
                            {r?.status === "DRAFT" ? (
                                <span className="draft"> DRAFT</span>
                            ) : r?.status === "DONE" ? (
                                <span className="post"> DONE</span>
                            ) : r?.status === "CANCELLED" ? (
                                <span className="cancel">CANCEL</span>
                            ) :  r?.status==='RETURN'?(
                              <span className="return">RETURN</span>)
                              : r?.status==='CHDRAFT'?(
                                <span className="return" style={{backgroundColor:"#dd9a1d"}}>BOOKED</span>):
                               
                             (
                                <span>{r?.status}</span>
                            )}
                        </td>
                        <td>
                          <Rate
                          value={r.custRating}  
                          disabled={true}  
                          style={{fontSize:"15px"}}
                          />
                        </td>
                    </tr>
                )):
                <tr>
                  <td colSpan={8}>NO DATA</td>
                </tr>
            }
        </tbody>
              )}
        </table>
        </div>
</div>
  )
}

export default PosOrders