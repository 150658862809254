import React from 'react'
import dummyProductImg from "../../../Assets/BillingImages/emptyFoodImg.svg"
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
const SelfCartItem = ({ item, key,handleDecrease,handleIncrease,handleDelete }) => {

  const decimalPosition = localStorage.getItem("decimalPosition");
  const { currencyDetails } = useSelector((state) => state.mainDashBoardSlice)

  return (
    <div className="self-cart-item" key={key}>
      <img
        src={item?.imageUrl !== null &&
          item?.imageUrl.length !== 0
          ? item?.imageUrl
          : dummyProductImg
        }
        alt="FOOD IMG"
        className="self-cart-item__image"
      />
      <div className="self-cart-item__details">
        <p className="self-cart-item__name">{item?.productName}</p>
        <p className="self-cart-item__size">{Number(item?.salesPrice)?.toFixed(decimalPosition)}</p>
      </div>

      <div className='self-cart-end-ctn'>
        <div className="self-cart-item__quantity">
          <button onClick={handleDecrease(item)} className="self-cart-item__button">−</button>
          <span className="self-cart-item__quantity-number">{item?.quantity}</span>
          <button onClick={handleIncrease(item)} className="self-cart-item__button">+</button>
        </div>
        <div className="self-cart-item__price">
          <p>{currencyDetails?.symbol || ""} {Number(item?.subTotalWoTax)?.toFixed(decimalPosition)}</p>
        </div>
        <button disabled={item?.objType} onClick={handleDelete(item)}  className="self-cart-item__delete" style={{backgroundColor:item?.objType?"inherit":"#f8d7da"}}>
          <DeleteIcon color={item?.objType?'disabled':'error'}/>
        </button>
      </div>
    </div>
  )
}

export default SelfCartItem