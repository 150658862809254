import { createSlice } from "@reduxjs/toolkit";


export const newBillingSlice=createSlice({
    name:"newBillingSlice",
    initialState:{
        billingScreen:false,
        productsList:[],
        subCategoryList:undefined,
        paymentTypeList:undefined,
        barCodeList:undefined,
        productType:1,
        barCodeProduct:null,
        authPassWord:"",
        branchConfigurationList:null
    },
    reducers:{
        get_products_list_for_billing:(state,action)=>{
            state.productsList=action.payload.billingProductsList
        },
        get_sub_category_list_for_billing:(state,action)=>{
            state.subCategoryList=action.payload.billingSubCategories
        },
        get_payment_types_list_for_billing:(state,action)=>{
            state.paymentTypeList=action.payload.billingPaymentTypes
        },
        get_barcode_product_list_add_billing:(state,action)=>{
            state.barCodeList=action.payload.barCodeItems
        },
        switch_to_billing_screen:(state,action)=>{
            state.billingScreen=action.payload.billingBoolean
        },
        setCartProductType:(state,action)=>{
            state.productType=action.payload
        },
        setBarcodeProduct:(state,action)=>{
            state.barCodeProduct=action.payload
        },
        setAuthPassword:(state,action)=>{
            state.authPassWord=action.payload
        },
        get_branch_configuration_list:(state,action)=>{
            state.branchConfigurationList=action.payload
        }

    }
})

export const {get_products_list_for_billing,
              get_sub_category_list_for_billing,
              get_payment_types_list_for_billing,
              get_barcode_product_list_add_billing,
              switch_to_billing_screen,setCartProductType,
              setBarcodeProduct,setAuthPassword,get_branch_configuration_list
            }=newBillingSlice.actions;

export default newBillingSlice.reducer