import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, IconButton } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CloseIcon from '@mui/icons-material/Close';
import SelfLoginBtn from '../Buttons/SelfLoginBtn';
import { useDispatch, useSelector } from 'react-redux';
import { selfDialogScreenSelected } from '../../Slices/selfOrderMainSlice';
import { setSelfOrderScreen } from '../Order/selfOrderEssentialsSlice';

const RegisterCustomerDialog = () => {
  const dispatch = useDispatch();
  const {selfDialogScreen}=useSelector((state)=>state.selfOrderMainSlice)
  
  const handleClose = () => dispatch(selfDialogScreenSelected(null));

  const registerClick=()=>{
    dispatch(setSelfOrderScreen("registerScreen"))
    handleClose()
  }

  return (
    <>

      <Dialog
        open={selfDialogScreen==="registerPopup"}
        onClose={handleClose}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '20px',
            maxWidth: '400px',
            width: '100%',
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="10px">
              {/* <ErrorOutlineIcon fontSize="large" color="error" /> */}
              <Typography variant="h6" fontWeight="bold">
                Customer not found !!
              </Typography>
            </Box>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="body1" color="textSecondary" textAlign="center">
            Would you like to register as a new customer ?
          </Typography>
          <Box display="flex" justifyContent="center" marginTop="20px">
            <PersonAddAltIcon style={{ fontSize: '80px', color: '#f44336' }} />
          </Box>
        </DialogContent>

        <DialogActions >
          <SelfLoginBtn label="REGISTER" style={{margin:"0"}} onClick={registerClick}/>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterCustomerDialog;
