import React from 'react'
import { useSelector } from 'react-redux';

function ShiftReceiptPrint() {
    const decimalPosition = localStorage.getItem("decimalPosition");
    const ShiftBillTypeSummary = useSelector(
      (state) => state.posReportshiftSlice.billTypeSummary
    );
    const {showSubHeaders} = useSelector((state) => state.posReportshiftSlice);
  return (
    <div
    className="new-global-table-container shift-report-print-container"
    style={{ paddingTop: "0 42px" }}
  >
    <div className="bill-main-head">
      <h5>Receipt</h5>
      <h5>Amount</h5>
    </div>
    {showSubHeaders && (
      <div
        className="bill-sub-head"
       
      >
        <h5>Sales</h5>
        <h5>
        {(ShiftBillTypeSummary?.receipt?.sales||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
    )}

    {showSubHeaders && (
      <div
        className="bill-sub-head"
      
      >
        <h5>Delivery Partner Receipt</h5>
        <h5>
        {(ShiftBillTypeSummary?.receipt?.deliveryPartner||0)?.toFixed(
              decimalPosition
            )}
        </h5>
      </div>
    )}

    {showSubHeaders && (
      <div
        className="bill-sub-head"
       
      >
        <h5>Packaging Charge</h5>
        <h5>
        {(ShiftBillTypeSummary?.receipt?.packagingCharge||0)?.toFixed(
              decimalPosition
            )}
        </h5>
      </div>
    )}

    {showSubHeaders && (
      <div
        className="bill-sub-head"
      
      >
        <h5>Delivery Charge</h5>
        <h5>
        {(ShiftBillTypeSummary?.receipt?.deliveryCharge||0)?.toFixed(
              decimalPosition
            )}
        </h5>
      </div>
    )}

    <div className="bill-main-head" >
      <h5>Receipt Total</h5>
      <h5>
        {" "}
        {(ShiftBillTypeSummary?.receipt?.total||0)?.toFixed(decimalPosition)}
      </h5>
    </div>
  </div>
  )
}

export default ShiftReceiptPrint