import React, { useEffect, useRef, useState } from "react";
import "./InvoiceMarginReport.css";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
// import { Bar, Line } from "react-chartjs-2";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { ViewProductMarginReportAPIcall } from "./GeneralReportAPI";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";
import PrintComponent from "../../../../print/report/PrintComponent";

function ProductMarginReport() {
  let paymentsPrintNew = useRef();
  const ExcelJS = require("exceljs");

  let navigate = useNavigate();
  const tableListData = useSelector(
    (state) => state?.generalReport?.ProductMarginReport
  );

  const totalPages = tableListData?.pages;
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  // const [filteredData,setFilteredData]=useState("")
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [handleDelete, setHandleDelete] = useState("");

  const deleteRowData = (data) => {
    setHandleDelete(data);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState({});
  const [symbol, setSymbol] = useState(null);
  const handleFilterChange = (updatedtItems) => {
    setFilteredData(updatedtItems);
  };
  const decimalPosition = localStorage.getItem("decimalPosition");
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const handleRowClick = (row) => {
    localStorage.setItem("productSingleViewId", row?._id);
    navigate(
      "/userdashboard/accounts/report/generalReport/productMarginReport/SingleView",
      { state: { productName: row?.productName } }
    );
  };

  // const labels =
  //     [
  //         'Product1', 'Product2', 'Product3', 'Product4', 'Product5', 'Product6', 'Product7', 'Product8', 'product9', 'product10',
  //         'Product11', 'Product12', 'Product13', 'Product14', 'Product15', 'Product16', 'Product17', 'Product18', 'product19', 'product20'
  //         , 'Product21', 'Product22', 'Product23', 'Product24', 'Product25',
  //     ];
  const labels = tableListData?.report?.map((item) => item?.productName) || [];

  const option = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  //   const datas = {
  //     labels,
  //     datasets: [
  //       {
  //         label: "",
  //         // data: salesPercData,
  //         backgroundColor: "#2dcf21",
  //         barThickness: 15,
  //       },
  //     ],
  //   };
  // const datas = {
  //     labels,
  //     datasets: [
  //         {
  //             label: "Profit Margin",
  //             data: [45, 75, 50, 90, 20, 40, 20, 30, 70, 40, 60, 80, 20, 50, 70, 30, 80, 60, 40, 20, 90, 50, 70, 30, 60],
  //             backgroundColor: "#05b30e",
  //             barThickness: 35,
  //         },
  //     ],
  // };
  const datas = {
    labels,
    datasets: [
      {
        label: "Profit Margin",
        data:
          tableListData?.report?.map((item) =>
            item.profitMargin?.toFixed(decimalPosition)
          ) || [],
        backgroundColor: "#05b30e",
        barThickness: 35,
      },
    ],
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Product Margin Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Product Margin Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:F1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "PRODUCT",
      "QTY",
      "NET SALE",
      "COST",
      "PROFIT",
      "PROFIT MARGIN %",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "PRODUCT", key: "productName", width: 30 },
      { header: "QTY", key: "qty", width: 20 },
      { header: "NET SALE", key: "netSale", width: 20 },
      { header: "COST", key: "cost", width: 20 },
      { header: "PROFIT", key: "profit", width: 20 },
      { header: "Product Margin Report", key: "profitMargin", width: 30 },
    ];

    tableListData?.report?.forEach((item) => {
      const row = sheet.addRow({
        productName: item.productName,
        qty: item.qty,
        netSale: item.netSale,
        cost: `${symbol} ${item?.cost?.toFixed(2) || ""}`,
        profit: `${symbol} ${item?.profit?.toFixed(2) || ""}`,
        profitMargin: ` ${item?.profitMargin?.toFixed(2) || ""}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFededed" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          cell.alignment = { horizontal: "right" };
        }
        if (colNumber === 4) {
          // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
          if (value < 0) {
            cell.font = { color: { argb: "FFededed" } }; // Red color for negative values
          }
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      `${symbol} ${
        tableListData?.total !== undefined &&
        tableListData?.total?.qty?.toFixed(2)
      }`,
      `${symbol} ${
        tableListData?.total !== undefined &&
        tableListData?.total?.netSale?.toFixed(2)
      }`,
      `${symbol} ${
        tableListData?.total !== undefined &&
        tableListData?.total?.cost?.toFixed(2)
      }`,
      `${symbol} ${
        tableListData?.total !== undefined &&
        tableListData?.total?.profit?.toFixed(2)
      }`,
      `${symbol} ${
        tableListData?.total !== undefined &&
        tableListData?.total?.profitMargin?.toFixed(2)
      }`,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        cell.alignment = { horizontal: "right" };
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Product Margin Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      let body = {
        fromDate:
          FormDataInfo.fromDate === ""
            ? ""
            : `${FormDataInfo?.fromDate} 00:00:00`,
        toDate:
          FormDataInfo.toDate === "" ? "" : `${FormDataInfo?.toDate} 23:59:59`,
        branchId: FormDataInfo?.branchList,
        search: FormDataInfo?.search,
        index: currentPage - 1,
      };
      ViewProductMarginReportAPIcall(body);
    }
  }, [FormDataInfo, currentPage]);

  const handlePrint = useReactToPrint({
    content: () => paymentsPrintNew.current,
  });

  return (
    <div className="in-or-out-container updated-global-page-parent-container">
      <div
        className="in-or-out-report-wrapper"
        style={{ backgroundColor: "unset", padding: "5px" }}
      >
        <div className="justify-space-between list-create-head-bar">
          <h3>Product Margin Report</h3>
          <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList} PrintClick={handlePrint} />
            <CategorySearchandFilter
              onData={handleDataFromChild}
              onBranchSelect={() => {}}
              onFilterChange={handleFilterChange}
            />
          </div>
        </div>
        <div
          className="invoice-table-container"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "0",
            height: "85vh",
          }}
        >
          <div className="invoice-main-div">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>

            {/* <h3>Product Margin Report</h3> */}
            <table>
              <thead>
                <tr>
                  <th>PRODUCT</th>
                  <th>QTY</th>
                  <th>NET SALE</th>
                  <th>COST</th>
                  <th>PROFIT</th>
                  <th>PROFIT MARGIN %</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : tableListData?.report !== undefined &&
                  tableListData?.report?.length !== 0 ? (
                  tableListData?.report?.slice(0)?.map((r, i) => (
                    <tr key={i} onClick={() => handleRowClick(r)}>
                      <td>{r?.productName}</td>
                      <td>{r?.qty?.toFixed(decimalPosition)}</td>
                      <td>
                        {symbol}&nbsp;{r?.netSale?.toFixed(decimalPosition)}
                      </td>

                      <td>
                        {symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}
                      </td>
                      <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={5}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
              <tr>
                <th>Total</th>
                <th>
                  {tableListData?.total !== undefined &&
                    tableListData?.total?.qty?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {tableListData?.total !== undefined &&
                    tableListData?.total?.netSale?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {tableListData?.total !== undefined &&
                    tableListData?.total?.cost?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {tableListData?.total !== undefined &&
                    tableListData?.total?.profit?.toFixed(decimalPosition)}
                </th>
                <th>
                  {tableListData?.total !== undefined &&
                    tableListData?.total?.profitMargin?.toFixed(
                      decimalPosition
                    )}
                </th>
              </tr>
            </table>
          </div>
        </div>

        {/* <div style={{ marginTop: '6%' }} className="payments-container"> */}
        {/* <div style={{ width: "100%", position: "relative", overflowX: 'auto' }}> */}
        {/* <Bar options={option} data={datas} /> */}
        {/* </div> */}
        {/* </div> */}
      </div>
      {/* <LoadingForm loading={isLoading} /> */}
      <div style={{ display: "none" }}>
        <div ref={paymentsPrintNew} >
          <PrintComponent header={"Product Margin Report"} pageHeight={70}>
            <table>
              <thead>
                <tr>
                  <th>PRODUCT</th>
                  <th>QTY</th>
                  <th>NET SALE</th>
                  <th>COST</th>
                  <th>PROFIT</th>
                  <th>PROFIT MARGIN %</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : tableListData?.report !== undefined &&
                  tableListData?.report?.length !== 0 ? (
                  tableListData?.report?.slice(0)?.map((r, i) => (
                    <>
                    <tr key={i} onClick={() => handleRowClick(r)}>
                      <td>{r?.productName}</td>
                      <td>{r?.qty?.toFixed(decimalPosition)}</td>
                      <td>
                        {symbol}&nbsp;{r?.netSale?.toFixed(decimalPosition)}
                      </td>

                      <td>
                        {symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}
                      </td>
                      <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                    </tr>
                    { tableListData?.report?.length -1 === i &&
                       <tr className="new-layout-print-footer">
                       <td>Total</td>
                       <td>
                         {tableListData?.total !== undefined &&
                           tableListData?.total?.qty?.toFixed(decimalPosition)}
                       </td>
                       <td>
                         {symbol}&nbsp;
                         {tableListData?.total !== undefined &&
                           tableListData?.total?.netSale?.toFixed(decimalPosition)}
                       </td>
                       <td>
                         {symbol}&nbsp;
                         {tableListData?.total !== undefined &&
                           tableListData?.total?.cost?.toFixed(decimalPosition)}
                       </td>
                       <td>
                         {symbol}&nbsp;
                         {tableListData?.total !== undefined &&
                           tableListData?.total?.profit?.toFixed(decimalPosition)}
                       </td>
                       <td>
                         {tableListData?.total !== undefined &&
                           tableListData?.total?.profitMargin?.toFixed(
                             decimalPosition
                           )}
                       </td>
                     </tr>
                    }
                    </>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={5}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
             
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
}

export default ProductMarginReport;
