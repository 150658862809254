import React, { useEffect, useRef, useState } from "react";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import "./CustomerReceiptReport.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { convertDateFormat } from "../../../../../Js/Date";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { CustomerReceiptReportSingleViewAPIcall } from "./GeneralReportAPI";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import {
  setFilterActive,
  set_Back_button_action,
} from "../../../../Single Components/listSlice/filterCategorySlice";
import ReactToPrint from "react-to-print";
import store from "../../../../../Redux/store";
import ExcelJS from "exceljs";
import PrintComponent from "../../../../print/report/PrintComponent";

function CustomerReceiptReportSingleView() {
  let paymentsPrintNew = useRef();
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState({});
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const SingleListData = useSelector(
    (state) => state?.generalReport?.customerReceiptReportSingleView
  );
  console.log(SingleListData);

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);

  const singleViewId = localStorage.getItem("customerReceiptSingleViewId");

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");

  const totalJournalKeys =
    SingleListData !== undefined
      ? Object.keys(SingleListData?.total?.journal)
      : [];
  console.log(totalJournalKeys, "lll");
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const handleClickRow = (row) => {
    if (row.type === 1) {
      localStorage.setItem("singleViewById", row?._id);
      store.dispatch(setFilterActive(window.location.pathname));
      navigate(
        "/userdashboard/accounts/customers/customerInvoices/singleView",
        {
          state: {
            isReceipt: true,
            name: SingleListData?.customer,
            seq: row?.invoiceNo,
          },
        }
      );
    } else {
      localStorage.setItem("singleViewById", row._id);
      // store.dispatch(get_sales_w_so_row_data_list({salesWSOsingleData:row}))
      //navigate("/userdashboard/accounts/customers/saleswso/create")
      store.dispatch(setFilterActive(window.location.pathname));
      navigate("/userdashboard/accounts/customers/saleswso/singleView", {
        state: {
          isReceipt: true,
          name: SingleListData?.customer,
          seq: row?.invoiceNo,
        },
      });
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleClick = () => {
    localStorage.removeItem("customerReceiptSingleViewId");
    navigate("/userdashboard/sales/Report/CustomerReceiptReport");
    dispatch(set_Back_button_action({ backButtonClickAction: true }));
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate) {
      parts.push(`As of ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Customer Receipt Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Customer Receipt Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Name",
      "Discount",
      "Tax Value",
      "Invoice Amount",
      "Paid Amount",
      ...totalJournalKeys,
      "Credit",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with 'FF' prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "invoiceDate", width: 20 },
      { header: "Name", key: "invoiceNo", width: 20 },
      { header: "Discount", key: "discount", width: 20 },
      { header: "Tax Value", key: "tax", width: 20 },
      { header: "Invoice Amount", key: "invAmount", width: 20 },
      { header: "Paid Amount", key: "paidAmount", width: 20 },
      ...totalJournalKeys.map((key) => ({ header: key, key, width: 20 })),
      { header: "Customer Receipt Report", key: "amountDue", width: 20 },
    ];

    SingleListData?.result?.forEach((item) => {
      const row = sheet.addRow({
        invoiceDate: convertDateFormat(item.invoiceDate),
        invoiceNo: item.invoiceNo,
        discount: `${symbol} ${item.discount?.toFixed(decimalPosition)}`,
        tax: `${symbol} ${item.tax?.toFixed(decimalPosition)}`,
        invAmount: `${symbol} ${item.invAmount?.toFixed(decimalPosition)}`,
        paidAmount: `${symbol} ${item.paidAmount?.toFixed(decimalPosition)}`,
        ...Object.fromEntries(
          totalJournalKeys.map((key) => [
            key,
            `${symbol} ${
              item.journal[key]?.toFixed(decimalPosition) || "0.00"
            }`,
          ])
        ),
        amountDue: `${symbol} ${item.amountDue?.toFixed(decimalPosition)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with 'FF' prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: 'thin', color: { argb: 'FF000000' } },
          //   left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          //   right: { style: 'thin', color: { argb: 'FF000000' } },
        };
        if (colNumber > 1) {
          cell.alignment = { horizontal: "right" };
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      `${symbol} ${SingleListData?.total?.invAmount?.toFixed(decimalPosition)}`,
      `${symbol} ${SingleListData?.total?.paidAmount?.toFixed(
        decimalPosition
      )}`,
      ...totalJournalKeys.map(
        (key) =>
          `${symbol} ${
            SingleListData?.total?.journal[key]?.toFixed(decimalPosition) ||
            "0.00"
          }`
      ),
      `${symbol} ${SingleListData?.total?.amountDue?.toFixed(decimalPosition)}`,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FF000000" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
      if (colNumber > 1) {
        cell.alignment = { horizontal: "right" };
      }
    });

    sheet.getRow(1).height = 30; // Increase row height for the title
    sheet.getRow(2).height = 25; // Increase row height for additional header information
    sheet.getRow(headerRow.number).height = 20; // Increase row height for the header

    const headerAlignment = {
      vertical: "middle",
      horizontal: "center",
    };

    headerRow.eachCell((cell) => {
      cell.alignment = headerAlignment; // Center-align header cells
    });

    const totalRowAlignment = {
      vertical: "middle",
      horizontal: "right",
    };

    totalRow.eachCell((cell) => {
      cell.alignment = totalRowAlignment; // Right-align total row cells
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "CustomerReceiptReport.xlsx";
      link.click();
    });
  };

  useEffect(() => {
    CustomerReceiptReportSingleViewAPIcall({
      _id: singleViewId,
    });
  }, [singleViewId]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);
  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="global-page-parent-container">
      {/* table */}

      <div
        className="create-button-blue-container"
        style={{
          padding: "10px",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          margin: "0",
        }}
      >
        <div>
          <h3 style={{ display: "inline-block" }}>
            <span
              style={{ color: "gray", cursor: "pointer" }}
              onClick={handleClick}
            >
              Customer Receipt Report
            </span>
            <ArrowForwardIosIcon style={{ fontSize: "0.9rem" }} />
            {SingleListData?.customer}
          </h3>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "50%",
          }}
        >
          {/* <CategorySearchandFilter
              // statusOptions={['POSTED', 'DRAFT']}
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              isLocation={true}
              isCategory={true}
              isposCategory={true}
              isDate={false}

            // isStatus={true}
            /> */}
          <Tooltip title="Share">
            <IconButton>
              <i
                class="bi bi-share"
                style={{
                  backgroundColor: "#9797e9",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>

          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i
                  style={{
                    color: "white",
                    backgroundColor: " #d787e7",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                  class="bi bi-printer"
                ></i>
              </IconButton>
            )}
            content={() => paymentsPrintNew}
          />

          <Tooltip title="Download">
            <IconButton onClick={downloadList}>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="global-white-bg-container" style={{ height: "91vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Discount</th>
                <th>Tax Value</th>
                <th>Invoice Amount</th>
                <th>Paid Amount</th>
                {totalJournalKeys?.map((r) => (
                  <th>{r}</th>
                ))}
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={8}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.result !== undefined &&
                SingleListData?.result?.length !== 0 ? (
                SingleListData?.result?.slice(0)?.map((r, i) => (
                  <tr key={i} onClick={() => handleClickRow(r)}>
                    <td>{convertDateFormat(r?.invoiceDate)}</td>
                    <td>{r?.invoiceNo}</td>
                    <td>
                      {symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}
                    </td>
                    <td>
                      {symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}
                    </td>
                    <td>
                      {symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}
                    </td>
                    <td>
                      {symbol}&nbsp;{r?.paidAmount?.toFixed(decimalPosition)}
                    </td>
                    {totalJournalKeys?.length > 0 &&
                      totalJournalKeys.map((key, i) => {
                        const value =
                          r?.journal[key]?.toFixed(decimalPosition) || "0.00";
                        return (
                          <td key={i}>
                            {symbol}&nbsp;{value}
                          </td>
                        );
                      })}

                    <td>
                      {symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={9}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th
                  style={{
                    textAlign: "start",
                    fontSize: "1rem",
                    paddingLeft: "3%",
                  }}
                  colSpan={4}
                >
                  TOTAL
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.invAmount?.toFixed(decimalPosition)}
                </th>
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.paidAmount?.toFixed(decimalPosition)}
                </th>
                {totalJournalKeys.map((key) => (
                  <th key={key}>
                    {symbol}&nbsp;
                    {SingleListData?.total?.journal[key]?.toFixed(
                      decimalPosition
                    ) || "0.00"}
                  </th>
                ))}
                <th>
                  {symbol}&nbsp;
                  {SingleListData?.total !== undefined &&
                    SingleListData?.total?.amountDue?.toFixed(decimalPosition)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <PrintComponent
            header={"Customer Receipt Report"}
            subHead={SingleListData?.customer}
            pageHeight={50}
          >
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th style={{width:"12em"}}>Name</th>
                  <th>Discount</th>
                  <th>Tax Value</th>
                  <th>Invoice Amount</th>
                  <th>Paid Amount</th>
                  {totalJournalKeys?.map((r) => (
                    <th>{r}</th>
                  ))}
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {SingleListData?.result !== undefined &&
                SingleListData?.result?.length !== 0 ? (
                  SingleListData?.result?.slice(0)?.map((r, i) => (
                    <>
                      <tr key={i} onClick={() => handleClickRow(r)}>
                        <td>{convertDateFormat(r?.invoiceDate)}</td>
                        <td>{r?.invoiceNo}</td>
                        <td>
                          {symbol}&nbsp;{r?.discount?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.tax?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.invAmount?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {r?.paidAmount?.toFixed(decimalPosition)}
                        </td>
                        {totalJournalKeys?.length > 0 &&
                          totalJournalKeys.map((key, i) => {
                            const value =
                              r?.journal[key]?.toFixed(decimalPosition) ||
                              "0.00";
                            return (
                              <td key={i}>
                                {symbol}&nbsp;{value}
                              </td>
                            );
                          })}

                        <td>
                          {symbol}&nbsp;{r?.amountDue?.toFixed(decimalPosition)}
                        </td>
                      </tr>
                      {SingleListData?.result?.length - 1 === i && (
                        <tr className="new-layout-print-footer">
                          <td
                            colSpan={4}
                          >
                            TOTAL
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData?.total !== undefined &&
                              SingleListData?.total?.invAmount?.toFixed(
                                decimalPosition
                              )}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData?.total !== undefined &&
                              SingleListData?.total?.paidAmount?.toFixed(
                                decimalPosition
                              )}
                          </td>
                          {totalJournalKeys.map((key) => (
                            <td key={key}>
                              {symbol}&nbsp;
                              {SingleListData?.total?.journal[key]?.toFixed(
                                decimalPosition
                              ) || "0.00"}
                            </td>
                          ))}
                          <td>
                            {symbol}&nbsp;
                            {SingleListData?.total !== undefined &&
                              SingleListData?.total?.amountDue?.toFixed(
                                decimalPosition
                              )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={9}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
}

export default CustomerReceiptReportSingleView;
