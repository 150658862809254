import React, { useEffect, useRef, useState } from "react";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CustomPagination from "../../../../Single Components/CustomPagination";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { set_Back_button_action } from "../../../../Single Components/listSlice/filterCategorySlice";
import { PayableDueReportSingleViewAPIcall } from "./partnerReportApi";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { convertDateFormat, today } from "../../../../../Js/Date";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import ExcelJS from "exceljs";
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";
import PrintComponent from "../../../../print/report/PrintComponent";

function PayableDueReportSingleView() {
  let navigate = useNavigate();
  let location = useLocation();
  let paymentsPrintNew = useRef();
  const dispatch = useDispatch();

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const SingleListData = useSelector(
    (state) => state?.PartnerReport?.PayableDueReportSingleView
  );

  const [filteredData, setFilteredData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const singleViewId = localStorage.getItem("payableSingleViewId");

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const handleClick = () => {
    localStorage.removeItem("payableSingleViewId");
    navigate("/userdashboard/accounts/report/partnerReport/PayableDueReport");
    dispatch(set_Back_button_action({ backButtonClickAction: true }));
  };

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate) {
      parts.push(`As of ${filters?.toDate}`);
    }

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }

    return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Payable Due Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Payable Due Report"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:K1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:K${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Reference",
      "Invoice Date",
      "Due Date",
      "Due Days",
      "Not Due Amount",
      "Over Due 0-30",
      "Over Due 31-60",
      "Over Due 61-90",
      "Over Due 91-120",
      "Over Due 120 Above",
      "Total",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Reference", key: "name", width: 20 },
      { header: "Invoice Date", key: "billDate", width: 25 },
      { header: "Due Date", key: "dueDate", width: 25 },
      { header: "Due Days", key: "days", width: 25 },
      { header: "Not Due Amount", key: "current", width: 25 },
      { header: "Over Due 0-30", key: "upto30days", width: 25 },
      { header: "Over Due 31-60", key: "upto60days", width: 25 },
      { header: "Over Due 61-90", key: "upto90days", width: 25 },
      { header: "Over Due 91-120", key: "upto120days", width: 25 },
      { header: "Over Due 120 Above", key: "morethan120days", width: 25 },
      { header: "Payable Due Report", key: "total", width: 25 },
    ];

    SingleListData?.list?.forEach((item) => {
      const row = sheet.addRow({
        name: item.name,
        billDate: convertDateFormat(item.billDate),
        dueDate: convertDateFormat(item.dueDate),
        days: item.days,
        current: ` ${item?.categories?.current?.toFixed(2)}`,
        upto30days: ` ${item?.categories?.upto30days?.toFixed(2)}`,
        upto60days: ` ${item?.categories?.upto60days?.toFixed(2)}`,
        upto90days: ` ${item?.categories?.upto90days?.toFixed(2)}`,
        upto120days: ` ${item?.categories?.upto120days?.toFixed(2)}`,
        morethan120days: ` ${item?.categories?.morethan120days?.toFixed(2)}`,
        total: ` ${item?.total?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "right" };
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      `${symbol} ${SingleListData?.sumOfCurrent.toFixed(2)}`,
      `${symbol} ${SingleListData?.sumOf30?.toFixed(2)}`,
      `${symbol} ${SingleListData?.sumOf60?.toFixed(2)}`,
      `${symbol} ${SingleListData?.sumOf90?.toFixed(2)}`,
      `${symbol} ${SingleListData?.sumOf120?.toFixed(2)}`,
      `${symbol} ${SingleListData?.sumOfAbove120?.toFixed(2)}`,
      `${symbol} ${SingleListData?.sumOfTotal?.toFixed(2)}`,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FF000000" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        // Right align all columns except the first
        cell.alignment = { horizontal: "right" };
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Payable Due Report Single View.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    PayableDueReportSingleViewAPIcall({
      customerId: singleViewId,
      todayDate:
        FormDataInfo?.toDate === ""
          ? `${today} 23:59:59`
          : `${FormDataInfo?.toDate} 23:59:59`,
      search: FormDataInfo?.search,
      index: currentPage - 1,
    });
  }, [FormDataInfo, singleViewId]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => paymentsPrintNew.current,
  });

  return (
    <div className="global-page-parent-container updated-global-page-parent-container">
      <div className="justify-space-between list-create-head-bar">
        <h3>
          <span
            style={{ color: "gray", cursor: "pointer" }}
            onClick={handleClick}
          >
            Payable Due Report
          </span>
          <ArrowForwardIosIcon style={{ fontSize: "0.9rem" }} />
          {SingleListData?.customerName}
        </h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons
            downloadClick={downloadList}
            PrintClick={handlePrint}
          />
          <CategorySearchandFilter
            onData={handleDataFromChild}
            style={{ width: "100%" }}
            isFromDate={false}
            isBranch={false}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>
      <div className="justify-space-between pagination-container">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <div className="customer-receipt-table-container updated-table-container">
        <table>
          <thead>
            <tr>
              <th>Reference</th>
              <th>Invoice Date</th>
              <th>Due Date</th>
              <th>Due Days</th>
              <th>Not Due Amount</th>
              <th>Over Due 0-30</th>
              <th>Over Due 31-60</th>
              <th>Over Due 61-90</th>
              <th>Over Due 91-120</th>
              <th>Over Due 120 Above</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [...Array(10)].map((r, i) => (
                <tr key={i}>
                  <td colSpan={8}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              ))
            ) : SingleListData?.list !== undefined &&
              SingleListData?.list?.length !== 0 ? (
              SingleListData?.list?.slice(0)?.map((r, i) => (
                <tr key={i}>
                  <td>{r?.name}</td>
                  <td>{convertDateFormat(r?.billDate)}</td>
                  <td>{convertDateFormat(r?.dueDate)}</td>
                  <td>{r?.days}</td>
                  <td>
                    {symbol}&nbsp;
                    {r?.categories?.current?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {symbol}&nbsp;
                    {r?.categories?.upto30days?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {symbol}&nbsp;
                    {r?.categories?.upto60days?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {symbol}&nbsp;
                    {r?.categories?.upto90days?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {symbol}&nbsp;
                    {r?.categories?.upto120days?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {symbol}&nbsp;
                    {r?.categories?.morethan120days?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={9}>
                  No Data
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <th
                style={{
                  textAlign: "start",
                  fontSize: "1rem",
                  paddingLeft: "3%",
                }}
                colSpan={4}
              >
                TOTAL
              </th>
              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOfCurrent?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOf30?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOf60?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOf90?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOf120?.toFixed(decimalPosition)}
              </th>
              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOfAbove120?.toFixed(decimalPosition)}
              </th>

              <th>
                {symbol}&nbsp;
                {SingleListData !== undefined &&
                  SingleListData?.sumOfTotal?.toFixed(decimalPosition)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div style={{ display: "none" }}>
        <div ref={paymentsPrintNew}>
          <PrintComponent header={"Payable Due Report "} subHead={SingleListData?.customerName} pageHeight={50} >
            <table>
              <thead>
                <tr>
                  <th style={{width:"10em"}}>Reference</th>
                  <th style={{width:"8em"}}>Invoice Date</th>
                  <th style={{width:"8em"}}>Due Date</th>
                  <th>Due Days</th>
                  <th>Not Due Amount</th>
                  <th>Over Due 0-30</th>
                  <th>Over Due 31-60</th>
                  <th>Over Due 61-90</th>
                  <th>Over Due 91-120</th>
                  <th>Over Due 120 Above</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {SingleListData?.list !== undefined &&
                SingleListData?.list?.length !== 0 ? (
                  SingleListData?.list?.slice(0)?.map((r, i) => (
                    <>
                      <tr key={i}>
                        <td>{r?.name}</td>
                        <td>{convertDateFormat(r?.billDate)}</td>
                        <td>{convertDateFormat(r?.dueDate)}</td>
                        <td>{r?.days}</td>
                        <td>
                          {symbol}&nbsp;
                          {r?.categories?.current?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {r?.categories?.upto30days?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {r?.categories?.upto60days?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {r?.categories?.upto90days?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {r?.categories?.upto120days?.toFixed(decimalPosition)}
                        </td>
                        <td>
                          {symbol}&nbsp;
                          {r?.categories?.morethan120days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td>
                          {symbol}&nbsp;{r?.total?.toFixed(decimalPosition)}
                        </td>
                      </tr>
                      {SingleListData?.list?.length - 1 === i && (
                        <tr className="new-layout-print-footer">
                          <td
                            style={{
                              textAlign: "start",
                              fontSize: "1rem",
                              paddingLeft: "3%",
                            }}
                            colSpan={4}
                          >
                            TOTAL
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOfCurrent?.toFixed(
                                decimalPosition
                              )}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOf30?.toFixed(decimalPosition)}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOf60?.toFixed(decimalPosition)}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOf90?.toFixed(decimalPosition)}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOf120?.toFixed(
                                decimalPosition
                              )}
                          </td>
                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOfAbove120?.toFixed(
                                decimalPosition
                              )}
                          </td>

                          <td>
                            {symbol}&nbsp;
                            {SingleListData !== undefined &&
                              SingleListData?.sumOfTotal?.toFixed(
                                decimalPosition
                              )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={9}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
}

export default PayableDueReportSingleView;
