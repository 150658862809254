import React, { useEffect } from 'react'
import  "./CSS/selfOrderParent.css"
import SelfLoginPage from './Components/LoginSelf/SelfLoginPage'
import { useDispatch, useSelector } from 'react-redux'
import SelfRegisterPage from './Components/LoginSelf/SelfRegisterPage'
import MenuButton from './Components/Buttons/MenuButton'
import LoginType from './Components/LoginSelf/LoginType'
import { selfDialogScreenSelected, setScreenLoginType, setScreenSelected, setSelfSingleOrderInfo } from './Slices/selfOrderMainSlice'
import PasswordDialog from './Components/Dialog/PasswordDialog'
import MenuDialog from './Components/Dialog/MenuDialog'
import SelfOrderSection from './Components/Order/SelfOrderSection'
import { categoryListAPICall } from '../component/Modules/Inventory/Configuration/Category/CategoryAPI'
import { defaultCustomerForPOSAPI } from '../component/Modules/Sales/Billing/API/billingFloorSection'
import { setSelfChairIds, setSelfCustomer, setSelfMobileNo, setSelfOrderScreen, setSelfOrderType, setSelfTableIds, setSelfTableNames } from './Components/Order/selfOrderEssentialsSlice'
import { listAllFloorAPI } from '../component/Modules/FloorManagement/addSeat/floorManagementApi'
import { singleSelfOrderInfoAPICall } from './API/selfOrderAPIS'
import OrderType from './Components/Order/OrderType'
import { getBranchConfiguration } from '../component/Modules/Sales/Billing/newBillingAPI'
import SelfOngoingOrders from './Components/Order/SelfOngoingOrders'
import { setSelfCartArray, setSelfTotalsInfo } from './Slices/selfOrderCartSlice'
const SelfOrderScreen = () => {

  const dispatch=useDispatch()

  const selfUser=localStorage.getItem('selfUser')
  const selfDefaultTableInfo = localStorage.getItem("selfDefaultTableInfo");
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userBranchId = userInfo?.branchPk;
  // redux states
  const {screenSelected,loginType,selfDialogScreen}=useSelector((state)=>state.selfOrderMainSlice)

  // Dialog close
  const closeDialog=()=>{
    dispatch(selfDialogScreenSelected(null))
  }

  const authSuccessFn=()=>dispatch(selfDialogScreenSelected("menuDialog"))

  // new order clearfn

  const newOrderClick = () => {
    localStorage.removeItem("selfOrderId")
    dispatch(setSelfSingleOrderInfo(null))
    dispatch(setSelfCartArray([]))
    dispatch(setSelfTotalsInfo(null))
    dispatch(setSelfCustomer(null))
    dispatch(setScreenSelected(null))
    dispatch(selfDialogScreenSelected(null))
    dispatch(setSelfMobileNo(null))

    if (selfUser === "SELF") {
      dispatch(setSelfOrderScreen("orderProductLists"))
    } else {
      dispatch(setSelfOrderScreen(null))
      dispatch(setSelfTableIds([]))
      dispatch(setSelfChairIds([]))
      dispatch(setSelfTableNames([]))
    }
  }
  // initial functions
  useEffect(()=>{
    dispatch(setScreenLoginType(selfUser))
    dispatch(setScreenLoginType(selfUser))
    categoryListAPICall();
    getBranchConfiguration({ branchId: userBranchId });
    defaultCustomerForPOSAPI({})
    const selfWOParse=localStorage.getItem("selfOrderId");
    const selfOrderId =  selfWOParse === "null" || selfWOParse === null ? null : selfWOParse;

    let dineInFn = () => {
      if (selfUser === "SELF" && selfDefaultTableInfo) {
        let tableInfo= JSON.parse(selfDefaultTableInfo);
          dispatch(setSelfTableIds(tableInfo?.selfTableIds || []))
          dispatch(setSelfTableNames(tableInfo?.selfTableName || []))
          dispatch(setSelfChairIds(tableInfo?.selfChairIds || []))
          dispatch(setSelfOrderScreen("orderProductLists"))
          dispatch(setSelfOrderType("DINEIN"))
          selfOrderId && dispatch(setScreenSelected("orderScreen"))

          singleSelfOrderInfoAPICall({id:selfOrderId})
          listAllFloorAPI()
        }
    }
     dineInFn()
  },[])

  return (
    <div className='self-order-parent-ctn'>
          {
            loginType==="selfUser"
            ?<LoginType/>
            :
            screenSelected==="orderScreen"?
            <SelfOrderSection newOrderClick={newOrderClick}/>
            :
            <OrderType/>
          }

       {!(screenSelected==="orderScreen") && <MenuButton/>}
        <PasswordDialog open={selfDialogScreen==="passwordDialog"} handleClose={closeDialog} authSuccessFn={authSuccessFn}/>
        <MenuDialog open={selfDialogScreen==="menuDialog"} handleClose={closeDialog}/>
        <SelfOngoingOrders open={selfDialogScreen==="ordersDialog"} handleClose={closeDialog} newOrderClick={newOrderClick}/>
    </div>
  )
}

export default SelfOrderScreen