import { createSlice } from "@reduxjs/toolkit";


export const selfOrderCartSlice=createSlice({
    name:"sefOrderCartSlice",
    initialState:{
        cartArray:[],
        cartModified:false,
        totalsInfo:null
    },
    reducers:{
        setSelfCartArray:(state,action)=>{
            state.cartArray=action.payload
        },
        setSelfCartModified:(state,action)=>{
            state.cartModified=action.payload
        },
        setSelfTotalsInfo:(state,action)=>{
            state.totalsInfo=action.payload;
        }
    }
})

export const {setSelfCartArray,setSelfCartModified,setSelfTotalsInfo}=selfOrderCartSlice.actions

export default selfOrderCartSlice.reducer