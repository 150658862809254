import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import store from '../../../../../Redux/store';
import { get_vendor_list_row_data_list } from '../../../Accounts/Vendor/Slice/vendorsListSlice';
import { viewVendorListAPICall } from '../../../Accounts/Vendor/API/vendorListAPICall';

export const VendorListPurchaseManage = () => {

  let navigateTo=useNavigate()
  const tableListData=useSelector((state)=>state.vendorsListSlice.apiResList);
  
  const [isLoading,setIsLoading]=useState(false)
  const handleClickRow=(row)=>{
    localStorage.setItem("singleViewById", row?._id);
    // store.dispatch(get_vendor_list_row_data_list({vendorResRowData:row}))
    navigateTo("/userdashboard/purchaseManager/vendor/Createvendors")
  }

  useEffect(()=>{
    store.dispatch(get_vendor_list_row_data_list(undefined))
    localStorage.removeItem("singleViewById");
    viewVendorListAPICall(setIsLoading)
  },[])


  return (
    <div className='global-page-parent-container'style={{ margin: "0" }}>
      {/* <p className="breadcrumb">Purchase Manage &gt; Vendors &gt; Vendors List</p> */}

      <div className="global-white-bg-container"style={{backgroundColor:"unset",padding:"5px"}}>
        <div className="justify-end" style={{background:"white",padding:"10px"}}>
          <button className='create-button-blue' 
           onClick={()=>navigateTo("/userdashboard/purchaseManager/vendor/Createvendors")}>
            Create</button>
        </div>

        <div className="global-table-container" style={{height:"85vh",maxHeight:"85vh",marginTop:"4px"}}>
          <table className="global-table">
            <thead>
              <tr>
              <th>No</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={5}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData !== undefined || tableListData?.length === 0 ? (
                tableListData?.map((r, i) => (
                  <tr key={i} onClick={() => handleClickRow(r)} style={{ cursor: "pointer" }}>
                    <td>{i + 1}</td>
                    <td>{r?.name}</td>
                    <td>{r?.mobileNo}</td>
                    <td>{r?.email || "-NIL-"}</td>
                    <td>{r?.country==="undefined"?"-NIL-":r?.country}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
