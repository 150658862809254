import React from 'react'
import SelectCard from '../Card/SelectCard'
import selfImg from "../../../Assets/SelfOrder/Self.png"
import staffImg from "../../../Assets/SelfOrder/Staffs.png"
import { useDispatch } from 'react-redux'
import { setScreenLoginType, setScreenSelected } from '../../Slices/selfOrderMainSlice'
import { selfOrderClear } from '../../SelfGeneralFunctions'
const LoginType = () => {
  const dispatch=useDispatch()

  const cardClick=(click)=>{
    localStorage.setItem("selfUser",click)
    dispatch(setScreenLoginType(click))
    dispatch(setScreenSelected(null))
    selfOrderClear()
  }
  return (
    <div className="self-login-ctn">
        
        <h3>LOGIN TYPE</h3>
        <div className="justify-center" style={{gap:"15px",flexWrap:"wrap"}}>
            <SelectCard image={selfImg} label="SELF" onClick={()=>cardClick("SELF")}/>
            <SelectCard image={staffImg} label="STAFF" onClick={()=>cardClick("STAFF")}/>
        </div>
    </div>
  )
}

export default LoginType