import React from 'react'
import SelfOrderFooter from '../Navbar/SelfOrderFooter'
import CategoryListingScroll from './CategoryListingScroll'
import ProductCardContainer from './ProductCardContainer'
import SubCategoryandSpecialItems from './SubCategoryandSpecialItems'
import SelfSpecialOffersDialog from './SelfSpecialOffersDialog'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd';
import { selfOrderClear } from '../../SelfGeneralFunctions'
import { setSelfMainCat, setSelfOrderScreen, setSelfSubCat } from './selfOrderEssentialsSlice'
const SelfProductLists = ({calculateAndSetCalculatedValues}) => {

    const dispatch=useDispatch()
    const {cartArray}=useSelector((state)=>state.selfOrderCartSlice)
    const { productType } = useSelector((state) => state.newBillingSlice);
  
    const backBtnFunction=()=>{
      localStorage.removeItem("selfOrderId")
      selfOrderClear()
    }
    const addToCart = (product) => {
      // Check if the product is already in the cart based on its id
      const existingItemIndex = cartArray.findIndex(
        (item) => item._id === product._id
      );
      
      if (existingItemIndex !== -1) {
        const updatedCartItems = cartArray.map((item, index) =>
          index === existingItemIndex
        ? { ...item, quantity: item.quantity + 1 }
        : item
      );
      calculateAndSetCalculatedValues(updatedCartItems)
    } else {
      // If the product is not in the cart, add it with a quantity of 1
      
      let newCartItems=[...cartArray]
      newCartItems.unshift({...product, quantity: 1,itemType:productType})
      calculateAndSetCalculatedValues(newCartItems)
    }
    // SNACK MSG
    message.success(`${product.productName} added to cart`);
    };

    const nextBtnFunction=()=>{
      dispatch(setSelfOrderScreen("cartSection"))
      dispatch(setSelfSubCat(null))
      dispatch(setSelfMainCat(null))
    }
  return (
    <div>
        <div className='self-order-middle-container'>
          {/* Category listing and product card */}
            <CategoryListingScroll/>
          {/*  Product Listing and sub category  */}
            <div className="flex-column" style={{flex:"1"}}>
              <SubCategoryandSpecialItems/>
              <ProductCardContainer addToCart={addToCart}/>
            </div>
        </div>
        {/* Common footer */}
        <SelfOrderFooter 
        blackBtnText={"BACK"} 
        redBtnText={cartArray?.length===0?null:"NEXT"} 
        blackBtnOnclick={backBtnFunction}
        redBtnOnClick={nextBtnFunction}
        />
   
   {/* SPECIAL Items popup */}
        <SelfSpecialOffersDialog addToCart={addToCart}/>
    </div>
  )
}

export default SelfProductLists