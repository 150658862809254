import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFloorDetailsForBilling } from '../../../component/Modules/Sales/Billing/API/billingFloorSection';
import { Rnd } from 'react-rnd';
import { shapeTypes, structures } from '../../../component/Modules/FloorManagement/utils/floorManagementEnum';
import LineSegmentTool from '../../../component/Modules/FloorManagement/components/icons/LineSegmentTool';
import StarTool from '../../../component/Modules/FloorManagement/components/icons/StarTool';
import Chair from '../../../component/Modules/FloorManagement/components/icons/Chair';
import { IconButton, Tab, Tabs } from '@mui/material';
import { rgba } from 'polished';
import SelfOrderFooter from '../Navbar/SelfOrderFooter';
import { setSelfOrderScreen, setSelfTableIds, setSelfChairIds, setSelfTableNames} from './selfOrderEssentialsSlice';
import { errorSnackMsg } from '../../../component/Custom Hooks/SnackkBarUtilities';
import { singleSelfOrderInfoAPICall } from '../../API/selfOrderAPIS';
import { setSelfSingleOrderInfo } from '../../Slices/selfOrderMainSlice';
import { selfOrderClear } from '../../SelfGeneralFunctions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const SelfOrderFloorPlan = () => {
    const dispatch=useDispatch()
    const defaultFloorId = localStorage.getItem("defaultFloorId");
    const selfWOParse=localStorage.getItem("selfOrderId");
    const selfOrderId =  selfWOParse === "null" || selfWOParse === null ? null : selfWOParse;

    const selfUser = localStorage.getItem("selfUser");

    // Redux states
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const { selectedFloorDetails } = useSelector((store) => store.billingFloorPlanSlice);
    const { branchConfigurationList } = useSelector((state) => state.newBillingSlice);
    const { floors } = useSelector((state) => state.addSeatSlice);
    const { selfTableIds,selfChairIds } = useSelector((state) => state.selfOrderEssentialsSlice);

    // useStates 
    const [floorSelected, setFloorSelected] = useState(null)
    const [orderIdColorMapping, setOrderIdColorMapping] = useState({});
    const [selectedTables, setSelectedTables] = useState([])
    const [selectedChairs, setSelectedChairs] = useState([])
    const [initialColors,setInitialColors]=useState({})
    const [triggerEffect,setTriggerEffect]=useState(false)
    const [tableOrder,setTableOrders] = useState([])

    // function to set colors on floor tab
    const getSelectedTabColor = (originalColor) => {
        try {
            return rgba(originalColor, 0.5);
        } catch (error) {
            return rgba("#f67878", 0.5);
        }
    };

    const getRandomColor = () => {
        const generateRandomValue = () => Math.floor(Math.random() * 200);
        const color = `rgb(${generateRandomValue()}, ${generateRandomValue()}, ${generateRandomValue()})`;

        return color;

        // const hue = parseInt(id, 36) % 360; // Convert id to hue
        // return `hsl(${hue}, 70%, 50%)`;
    };

    // function to get the orderId (used when multiple order in same table)
    const getFirstObject = (list) => {
        if (list.length !== 0) {
            return list[0]?._id
        } else {
            return null
        }
    }
    // Set floor tab details
    const handleTabDetails = (res) => {

        getFloorDetailsForBilling({ id: res._id, branchId: userInfo?.branchPk });
        localStorage.setItem("defaultFloorId", res._id)
        setSelectedTables([]);
        setSelectedChairs([])
        dispatch(setSelfTableIds([]))
        dispatch(setSelfChairIds([]))
        localStorage.removeItem("selfOrderId")

        setOrderIdColorMapping({})
        setInitialColors({})
    };

  
    // table/chair onClick fn
    const handleShapeSelected = (shape)  => {
        if(shape.isUsed){
            if(selectedTables.length!==0){
                errorSnackMsg("Seat already in use !!")
            }else{
                
                localStorage.setItem("selfOrderId",(selfOrderId=== getFirstObject(shape?.orders)?null:getFirstObject(shape?.orders)))
                setTriggerEffect(!triggerEffect)
            }
        }
        else if(!selfOrderId){
        if (shape.type === "CHAIR") {
            let seatSelection=selectedFloorDetails?.seatSelection
            if(seatSelection){
                const chairSelected = selectedChairs.some((obj) => obj === shape?._id);
                if (chairSelected) {
                    const updatedSelectedObjects = selectedChairs.filter((obj) => obj !== shape._id);
                    setSelectedChairs(updatedSelectedObjects);
                } else {
                    setSelectedChairs([...selectedChairs, shape._id]);
                    setSelectedTables([...selectedTables, shape.tableId]);
                }
            }else{
                errorSnackMsg("Chair selection disabled for this floor")
            }
        }
        else if(shape.type==="TABLE"){
            const tableSelected = selectedTables.some((obj) => obj === shape._id);
            if (tableSelected) {
                const updatedSelectedObjects = selectedTables.filter((obj) => obj !== shape._id);
                const filteredChairs = selectedChairs.filter(chairId => {
                    const matchingObject = selectedFloorDetails?.floorCanvas.find(obj => obj._id === chairId);
                    return !matchingObject || matchingObject.tableId !== shape._id;
                  });

                setSelectedTables(updatedSelectedObjects);
                setSelectedChairs(filteredChairs)
            } else {
                setSelectedTables([...selectedTables, shape._id]);
                const filteredIds = selectedFloorDetails?.floorCanvas
                    .filter((obj) => obj.tableId === shape._id)
                    .map((obj) => obj._id);
                const uniqueIds = new Set(selectedChairs.concat(filteredIds));
                uniqueIds.size !== 0 && setSelectedChairs([...uniqueIds]);
            }
        }
        }
    }  

    // common footer backfunction
    const backBtnFunction=()=>{
        selfOrderClear()
    }
    // common footer nextfunction
    const nextBtnFn=()=>{

        if(selfOrderId){
            let postFn=()=>{
                dispatch(setSelfOrderScreen("orderProductLists"))
            }
            singleSelfOrderInfoAPICall({id:selfOrderId},postFn)
        }
        else if(selectedTables.length===0){
            errorSnackMsg("Please select a table")  
        }
        else{
            const tableNames = selectedFloorDetails?.floorCanvas.filter(item => selectedTables.includes(item._id))
            .map(item => item.name); 
            dispatch(setSelfTableIds(selectedTables))
            dispatch(setSelfTableNames(tableNames))
            dispatch(setSelfChairIds(selectedChairs))
            dispatch(setSelfOrderScreen("orderProductLists"))
            
            const data = {
                selfTableIds: selectedTables,
                selfChairIds: selectedChairs,
                selfTableName: tableNames,
                floorId:defaultFloorId,
                orderId:selfOrderId
            };
            // Save the data for default table selection
            selfUser==="SELF" && localStorage.setItem("selfDefaultTableInfo", JSON.stringify(data));
        }
        !selfOrderId && dispatch(setSelfSingleOrderInfo(null))
    }

    const updateMultiOrder=(orderId)=>{
        localStorage.setItem("selfOrderId",orderId)
        let postFn=()=>{
            dispatch(setSelfOrderScreen("orderProductLists"))
        }
        singleSelfOrderInfoAPICall({id:orderId},postFn)
    }

    // Initially calling to get details of first floor
    useEffect(() => {
        const index = floors.findIndex(obj => obj._id === defaultFloorId);
        if (floors?.length !== 0) {
            let inFloor = index >= 0 ? index : false;
            !inFloor && localStorage.setItem("defaultFloorId",floors[inFloor || 0]?._id)
            setFloorSelected(inFloor || 0)
            getFloorDetailsForBilling({ id: floors[inFloor || 0]?._id, branchId: userInfo?.branchPk });
        }
    }, [floors])

    // setting table depending on chair selecting
    useEffect(()=>{
        if(selectedChairs.length!==0){
            const chairNames = selectedFloorDetails?.floorCanvas
            .filter(obj => selectedChairs.includes(obj._id))
            .map(obj => obj.tableId);
            const uniqueTableIds = [...new Set(chairNames)];

            setSelectedTables(uniqueTableIds);
        }
    },[selectedChairs])

    // setting color on an already existing table order
    useEffect(() => {
        if (Object.keys(initialColors).length === 0) {
          setInitialColors(orderIdColorMapping);
        } else {
          setOrderIdColorMapping(initialColors);
        }
      
        const newColorValue = "rgb(255, 0, 0)";
      
        setOrderIdColorMapping((prevColors) => {
          if (prevColors.hasOwnProperty(selfOrderId)) {
            // Update the color value for the orderIdForPos key
            return { ...prevColors, [selfOrderId]: newColorValue };
          } else {
            return prevColors;
          }
        });
      }, [triggerEffect]);

    //   topBar table click trigger effect
      useEffect(()=>{

        if(selfOrderId){
            branchConfigurationList?.enableMultipleOrder? localStorage?.removeItem("selfOrderId"):
            setTimeout(() => {
                selfOrderId && setTriggerEffect(!triggerEffect)
            }, 0);
        }else{
            setSelectedChairs(selfChairIds || [])
            dispatch(setSelfChairIds([]))
            if(selfChairIds.length===0){
                setSelectedTables(selfTableIds || [])
                dispatch(setSelfTableIds([]))
            }
        }

      },[])
 //   Setting Orders under the selectedTable
    useEffect(() => {
        let orderLists = selectedFloorDetails?.floorCanvas?.filter((obj) => (
            selectedTables.includes(obj?._id)
        ))?.reduce((acc, obj) => acc.concat(obj.orders), []);
        setTableOrders(orderLists)
    }, [selectedTables])
    return (
        <div className='self-order-floor-parent' style={{position:'relative'}}>
            <h3>SELECT A TABLE</h3>
            <Tabs
                value={floorSelected}
                onChange={(e, newValue) => {
                    setFloorSelected(newValue)
                }}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className="floor-container"
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: `${"red"} !important`,
                    },
                }}
            >
                {floors?.map((res, i) => (
                    <Tab
                        key={i}
                        sx={{
                            backgroundColor: getSelectedTabColor(res?.color) || rgba("#f67878", 1),
                            minWidth: floors?.length,
                            color: "white",
                            "&.Mui-selected": {
                                backgroundColor: res?.color || "#f67878",
                                color: "white",
                            },
                            minWidth: 250
                        }}
                        className="floor-box"
                        label={res?.nameOfFloor}
                        onClick={() => handleTabDetails(res)}
                    />
                ))}
            </Tabs>
            {

                (floorSelected === null) ? <div className="center-div">Select a floor</div> :
                    <>
                        {
                            selectedFloorDetails?.floorCanvas.length === 0 ? <div className="center-div">No Floor plan added</div> :
                                <div className="tab-panel-container">
                                    <div
                                        className="sub-tab-wrapper"
                                    >
                                        {selectedFloorDetails?.floorCanvas.map((shape) => (
                                            <Rnd
                                                key={shape._id}
                                                size={{
                                                    width: shape.size.width,
                                                    height: shape.size.height,
                                                }}
                                                position={{
                                                    x: shape.position.x,
                                                    y: shape.position.y,
                                                }}
                                                minWidth={10}
                                                minHeight={10}
                                                bounds="parent"
                                                // onTouchStart={()=>handleShapeSelected(shape)}
                                                onClick={() =>handleShapeSelected(shape)}
                                                enableResizing={false}
                                                disableDragging={true}
                                            >
                                                {

                                                    shape.type === shapeTypes.SHAPE ? (
                                                        <div
                                                            className="shape-container"
                                                            style={{ border: "transparent" }}
                                                        >
                                                            {shape?.structure === structures.LINESEGMENTTOOL ? (
                                                                <LineSegmentTool
                                                                    color={
                                                                        // selectedElementDetails?._id === shape._id
                                                                        // ? "#e91d1d"
                                                                        // :
                                                                        shape?.color
                                                                    }
                                                                    width={shape?.size?.width}
                                                                    height={shape?.size?.height}
                                                                />
                                                            ) : shape?.structure === structures.SPLITFLOOR ? (
                                                                <div
                                                                    className="shape-container"
                                                                    style={{
                                                                        backgroundColor:
                                                                            // selectedElementDetails?._id === shape._id
                                                                            //   ? "#e91d1d"
                                                                            //   : 
                                                                            shape?.color,
                                                                    }}
                                                                />
                                                            ) : shape?.structure === structures.HORIZONTAL ? (
                                                                <div
                                                                    className="shape-container"
                                                                    style={{
                                                                        backgroundColor: shape?.color,
                                                                    }}
                                                                />
                                                            ) : shape?.structure === structures.STAR ? (
                                                                <StarTool
                                                                    color={
                                                                        // selectedElementDetails?._id === shape._id
                                                                        //   ? "#e91d1d"
                                                                        //   :
                                                                        shape?.color
                                                                    }
                                                                    width={shape?.size?.width}
                                                                    height={shape?.size?.height}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    ) : shape.type === shapeTypes.TABLE ? (
                                                        <>
                                                            {
                                                                <div
                                                                    className={`shape-container ${shape.structure}`}
                                                                    style={{
                                                                        backgroundColor:
                                                                            (getFirstObject(shape?.orders) && !(branchConfigurationList?.enableMultipleOrder)) ? (
                                                                                orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                            ) :
                                                                                (selectedTables.includes(shape._id) ? 'red' : '#80808082'),
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    <span style={{userSelect:"none"}}>{shape.name}</span>
                                                                </div>

                                                            }
                                                        </>
                                                    ) : (
                                                        <div
                                                            className="chair-container"
                                                            // onClick={() => handleShapeSelected(shape)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <Chair
                                                                color={
                                                                    ((getFirstObject(shape?.orders) && !(branchConfigurationList?.enableMultipleOrder)) ? (
                                                                        orderIdColorMapping[getFirstObject(shape?.orders)] || (setOrderIdColorMapping((prevMapping) => ({ ...prevMapping, [getFirstObject(shape?.orders)]: getRandomColor() })), orderIdColorMapping[getFirstObject(shape?.orders)])
                                                                    ) :
                                                                        (selectedChairs.includes(shape._id) ? 'red' : '#80808082'))
                                                                }
                                                                width={shape?.size?.width}
                                                                name={shape?.name}
                                                                height={shape?.size?.height}
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </Rnd>
                                        ))}
                                    </div>
                                   
                                </div>}
                    </>
            }

            {tableOrder?.length !== 0 && branchConfigurationList?.enableMultipleOrder &&
                <div className="multi-order-update-ctn" style={{ position: "absolute", bottom: "50px", right: "0" }}>
                    <div className='justify-center'>
                    <button style={{ width: "90%" }} className='btn btn-primary' onClick={nextBtnFn}>New order</button>
                    <IconButton onClick={()=>setTableOrders([])}>
                        <CloseOutlinedIcon />
                    </IconButton>
                    </div>
                    {
                        tableOrder?.filter((item, index, self) =>
                            index === self.findIndex((t) => t._id === item._id)
                        )?.map((item, i) => (
                            <div className="order-ctn justify-space-between">
                                <p>{item?.orderName}</p>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => updateMultiOrder(item?._id)}
                                >
                                    <i className="bi bi-pencil-square edit-icon"></i>
                                </button>
                            </div>
                        ))
                    }
                </div>}
            <SelfOrderFooter blackBtnText={"BACK"} redBtnText={tableOrder?.length===0 &&( selfOrderId?"UPDATE":"NEXT")} blackBtnOnclick={backBtnFunction} redBtnOnClick={nextBtnFn}/>
        </div>
    );
};

export default SelfOrderFloorPlan;
