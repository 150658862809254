import React, { useEffect, useRef, useState } from "react";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import { today } from "../../../../../Js/Date";
import { accountRecievableAgingReportSingleViewApi } from "./partnerReportApi";
import { useNavigate } from "react-router-dom";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import Template from "./AccountAgingSingleViewPrint";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import ReactToPrint from "react-to-print";
import { convertArrayToExcel } from "../../../../../Js/generalFunctions";
import ExcelJS from "exceljs";
import PrintComponent from "../../../../print/report/PrintComponent";

const AccountReceivableAgingReportSingleView = () => {
  let paymentsPrintNew = useRef();

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const singleViewId = localStorage.getItem("singleViewById");
  const [isLoading, setIsLoading] = useState(true);
  const EndDate = localStorage.getItem("toDate");
  const navigate = useNavigate();
  const { AccountReceivableAgingReportListSingleView } = useSelector(
    (res) => res.PartnerReport
  );

  const initialvalue = {
    label: "Account Payable Aging Report",
    sumOfcurrent: 0,
    sumOf31: 0,
    sumOf61: 0,
    sumOf91: 0,
    sumOf121: 0,
    sumOf151: 0,
    sumOfAbove151: 0,
    sumOfTotal: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };

  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  useEffect(() => {
    setFormValues({
      ...formValues,
      sumOfcurrent:
        AccountReceivableAgingReportListSingleView?.sumOfcurrent || 0,
      sumOf31: AccountReceivableAgingReportListSingleView?.sumOf31 || 0,
      sumOf61: AccountReceivableAgingReportListSingleView?.sumOf61 || 0,
      sumOf91: AccountReceivableAgingReportListSingleView?.sumOf91 || 0,
      sumOf121: AccountReceivableAgingReportListSingleView?.sumOf121 || 0,
      sumOf151: AccountReceivableAgingReportListSingleView?.sumOf151 || 0,
      sumOfAbove151:
        AccountReceivableAgingReportListSingleView?.sumOfAbove151 || 0,
      sumOfTotal: AccountReceivableAgingReportListSingleView?.sumOfTotal || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(AccountReceivableAgingReportListSingleView?.list);
  }, [AccountReceivableAgingReportListSingleView, companyProfileData]);

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} `);
    }
    console.log(filters?.fromDate);

    // if (filters?.branchList) {
    //   parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    // }

    return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Account Receivable Ageing Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Account Receivable Ageing Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:J1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    // const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    // if (Array.isArray(filterParts)) {
    //   filterParts.forEach((part, index) => {
    //     const row = sheet.addRow([part]);
    //     row.font = { size: 15, bold: true };
    //     sheet.mergeCells(`A${index + 2}:I${index + 2}`);
    //     row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    //   });
    // }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Particular",
      "current",
      "1 to 30",
      "31 to 60",
      "61 to 90",
      "91 to 120",
      "121 to 150",
      "150 Above",
      "Total",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF002995" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "invoiceDate", width: 50 },
      { header: "Particular", key: "name", width: 20 },
      { header: "Current", key: "current", width: 20 },
      { header: "1 to 30", key: "upto30days", width: 20 },
      { header: "31 to 60", key: "upto60days", width: 20 },
      { header: "61 to 90", key: "upto90days", width: 20 },
      { header: "91 to 120", key: "upto120days", width: 20 },
      { header: "121 to 150", key: "upto150days", width: 20 },
      { header: "150 Above", key: "morethan150days", width: 20 },
      { header: "Account Receivable Ageing Report", key: "total", width: 20 },
    ];

    let rowIndex = 0;

    AccountReceivableAgingReportListSingleView?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        invoiceDate: item.invoiceDate,
        name: ` ${item?.name}`,
        current: ` ${item?.categories?.current?.toFixed(2)}`,
        upto30days: ` ${item?.categories?.upto30days?.toFixed(2)}`,
        upto60days: ` ${item?.categories?.upto60days?.toFixed(2)}`,
        upto90days: ` ${item?.categories?.upto90days?.toFixed(2)}`,
        upto120days: ` ${item?.categories?.upto120days?.toFixed(2)}`,
        upto150days: ` ${item?.categories?.upto150days?.toFixed(2)}`,
        morethan150days: ` ${item?.categories?.morethan150days?.toFixed(2)}`,
        total: ` ${item?.total?.toFixed(2)}`,
      });

      const rowFill = rowIndex % 2 === 0 ? "FFd2deff" : "FFFFFFFF"; // Alternate row colors
      row.eachCell((cell, colNumber) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: rowFill },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "right" };
        }
      });

      rowIndex++;
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      ` ${AccountReceivableAgingReportListSingleView?.sumOfcurrent?.toFixed(
        2
      )}`,
      ` ${AccountReceivableAgingReportListSingleView?.sumOf31?.toFixed(2)}`,
      ` ${AccountReceivableAgingReportListSingleView?.sumOf61?.toFixed(2)}`,
      ` ${AccountReceivableAgingReportListSingleView?.sumOf91?.toFixed(2)}`,
      ` ${AccountReceivableAgingReportListSingleView?.sumOf121?.toFixed(2)}`,
      ` ${AccountReceivableAgingReportListSingleView?.sumOf151?.toFixed(2)}`,
      ` ${AccountReceivableAgingReportListSingleView?.sumOfAbove151?.toFixed(
        2
      )}`,
      ` ${
        AccountReceivableAgingReportListSingleView?.currencySymbol
      } ${AccountReceivableAgingReportListSingleView?.sumOfTotal?.toFixed(2)}`,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        // Right align all columns except the first
        cell.alignment = { horizontal: "right" };
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Account Receivable Ageing Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  // console.log(AccountReceivableAgingReportListSingleView,'ggg')

  useEffect(() => {
    companyProfileInfoForUser();
    accountRecievableAgingReportSingleViewApi(
      {
        todayDate: EndDate ? EndDate : `${today} 23:59:59`,
        customerId: singleViewId,
      },
      setIsLoading
    );
  }, []);

  return (
    <div
      className="in-or-out-container"
      style={{ margin: "0", padding: "4px" }}
    >
      <div
        className="in-or-out-report-wrapper"
        style={{ backgroundColor: "unset", padding: "0px" }}
      >
        <div
          className="sub-nav-head"
          style={{ backgroundColor: "white", paddingInline: "5px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
            <div
              style={{
                background: "white",
                borderRadius: "0",
                maxWidth: "20em",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                fontSize: "larger",
                fontWeight: "800",
                color: "black",
                padding: "0 0 0 8px",
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/partnerReport/AccountReceivableAgingReport"
                );
                localStorage.removeItem("singleViewById");
              }}
            >
              <h3
                className="path-name"
                style={{
                  color: "black",
                  paddingRight: "6px",
                  fontSize: "medium",
                }}
              >
                Account Receivable Aging Report
              </h3>
              &gt;
            </div>
            <div
              className="path-name-wrapper"
              style={{
                maxWidth: "17em",
                backgroundColor: "white",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3
                className="path-name"
                style={{ color: "black", fontSize: "medium" }}
              >
                {AccountReceivableAgingReportListSingleView.customerName}
              </h3>
            </div>
          </div>
          <div>
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div
          className="table-wrapper-container"
          style={{ backgroundColor: "white", marginTop: "4px" }}
        >
          <TableContainer sx={{ maxHeight: "75vh" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="transaction-table"
              sx={{ padding: 0 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Particular</TableCell>
                  <TableCell>Current</TableCell>
                  <TableCell>1 to 30</TableCell>
                  <TableCell>31 to 60</TableCell>
                  <TableCell>61 to 90</TableCell>
                  <TableCell>91 to 120</TableCell>
                  <TableCell>121 to 150</TableCell>
                  <TableCell>150 Above</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AccountReceivableAgingReportListSingleView?.list?.length >
                0 ? (
                  AccountReceivableAgingReportListSingleView?.list?.map(
                    (item, index) => (
                      <TableRow
                        key={index}
                        className={index % 2 === 0 ? "even-row " : ""}
                      >
                        <TableCell>{item?.invoiceDate}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.current}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.upto30days}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.upto60days}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.upto90days}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.upto120days}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.upto150days}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.categories?.morethan150days}
                        </TableCell>
                        <TableCell className="amount-td">
                          {item?.total}
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <TableRow>
                    <TableCell className="no-data-td" colSpan={9}>
                      NO DATA
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter style={{ position: "sticky", bottom: 0 }}>
                <TableRow className="input-and-output-total">
                  <TableCell>Total </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOfcurrent}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf31}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf61}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf91}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf121}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOf151}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.sumOfAbove151}
                  </TableCell>
                  <TableCell>
                    {AccountReceivableAgingReportListSingleView?.currencySymbol}{" "}
                    {AccountReceivableAgingReportListSingleView?.sumOfTotal}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      <LoadingForm loading={isLoading} />

      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <PrintComponent header={"Account Receivable Aging Report"} subHead={AccountReceivableAgingReportListSingleView.customerName}>
            <table>
              <thead>
                <tr>
                  <th style={{width:'8em'}}>Date</th>
                  <th style={{width:'12em'}}>Particular</th>
                  <th>Current</th>
                  <th>1 to 30</th>
                  <th>31 to 60</th>
                  <th>61 to 90</th>
                  <th>91 to 120</th>
                  <th>121 to 150</th>
                  <th>150 Above</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {AccountReceivableAgingReportListSingleView?.list?.length >
                0 ? (
                  AccountReceivableAgingReportListSingleView?.list?.map(
                    (item, index) => (
                      <>
                        <tr key={index}>
                          <td>{item?.invoiceDate}</td>
                          <td>{item?.name}</td>
                          <td>{item?.categories?.current}</td>
                          <td>{item?.categories?.upto30days}</td>
                          <td>{item?.categories?.upto60days}</td>
                          <td>{item?.categories?.upto90days}</td>
                          <td>{item?.categories?.upto120days}</td>
                          <td>{item?.categories?.upto150days}</td>
                          <td>{item?.categories?.morethan150days}</td>
                          <td className="amount-td">{item?.total}</td>
                        </tr>
                        {AccountReceivableAgingReportListSingleView?.list
                          ?.length -
                          1 ===
                          index && (
                          <tr className="new-layout-print-footer">
                            <td>Total </td>
                            <td></td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOfcurrent
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOf31
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOf61
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOf91
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOf121
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOf151
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.sumOfAbove151
                              }
                            </td>
                            <td>
                              {
                                AccountReceivableAgingReportListSingleView?.currencySymbol
                              }{" "}
                              {
                                AccountReceivableAgingReportListSingleView?.sumOfTotal
                              }
                            </td>
                          </tr>
                        )}
                      </>
                    )
                  )
                ) : (
                  <tr>
                    <td className="no-data-td" colSpan={9}>
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </PrintComponent>
          {/* <Template data={data} formValues={formValues} /> */}
        </div>
      </div>
    </div>
  );
};

export default AccountReceivableAgingReportSingleView;
