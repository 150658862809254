import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Autocomplete,
  createFilterOptions,
  Dialog,
  TextField,
} from "@mui/material";
import { getAllTaxSettingsListAPICall } from "../../../../../API/Settings/TaxSettings/taxSettingsAPI";
import { useSelector } from "react-redux";
import { posCategoryListAPICall } from "../../Configuration/POSCategory/posCategoryAPI";
import { categoryListAPICall } from "../../Configuration/Category/CategoryAPI";
import { AddTaxPopupApiCall } from "../inventoryProductsAPI";
import { filterProductsOption } from "../../../../Single Components/GneralUtilities";

function AddTaxPopup({ open, onClose }) {
  const taxListFull = useSelector((state) => state.taxSettingsSlice.value);
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const allNewProductList = useSelector(
    (state) => state.vendorBillSlice.NewProductList
  );
  const posCategoryList = useSelector(
    (state) => state?.posCategorySlice?.value
  );


  const switchOptions = ["All Product", "Category", "Product Wise"];
  const [selectedOption, setSelectedOption] = useState(switchOptions[0]);
  const [filteredPOSCategory, setFilteredPOSCategory] = useState([]);
  const [validationAlert, setValidationAlert] = useState({});
  const formValueInitial = {
    vendorTax: [],
    customerTax: [],
    mainCategory: null,
    posCategory: null,
    product: [],
  };
  const [formValues, setFormValues] = useState(formValueInitial);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFormValues(formValueInitial)

  };

  const handleTaxChange = (key) => (event, newValue) => {
    const { value } = event.target;
    if (key === "mainCategory") {
      let filteredArray = posCategoryList?.filter(
        (obj) => obj?.catId === newValue?.categoryName
      );

      setFilteredPOSCategory(filteredArray);

      setFormValues({
        ...formValues,
        mainCategory: newValue,
        posCategory: null,
      });
    } else {
      setFormValues({
        ...formValues,
        [key]: newValue === undefined ? value : newValue,
      });
    }
  };

  const handleSubmit = () => {
    let isValid = true;
    const newValidationAlert = {};

    if (selectedOption === "Category") {
      if (!formValues.mainCategory) {
        newValidationAlert.mainCategory = "Please select a category.";
        isValid = false;
      }
      if (!formValues.posCategory) {
        newValidationAlert.posCategory = "Please select a sub-category.";
        isValid = false;
      }
    }

    if (selectedOption === "One Product") {
      if (!formValues.product) {
        newValidationAlert.product = "Please select a product.";
        isValid = false;
      }
     
    }

    setValidationAlert(newValidationAlert);

    if (!isValid) {
      return;
    }
    const payload = {
        productIdList:
        selectedOption === "Product Wise" && formValues?.product.length > 0
          ? formValues.product.map((product) => product._id)
          : [],
      mainCatgeoryId:
        selectedOption === "Category" && formValues?.mainCategory
          ? formValues.mainCategory._id
          : "",
      poscat:
        selectedOption === "Category" && formValues?.posCategory
          ? formValues.posCategory._id
          : "",
      vendorTax: formValues?.vendorTax.map((tax) => tax._id) || [],
      customerTax: formValues?.customerTax.map((tax) => tax._id) || [],
      nonTaxable: false,
      allProducts: selectedOption === "All Product",
    };
    AddTaxPopupApiCall(payload);
    onClose()
  };
  useEffect(() => {
    getAllTaxSettingsListAPICall();
    posCategoryListAPICall();
    categoryListAPICall();
  }, []);
  return (
    <Dialog open={open} maxWidth="md" onClose={onClose} fullWidth>
      <div className="tax-ctn">
        <div className="products-switch-menu">
          {switchOptions?.map((option) => (
            <div
              key={option}
              className={`option ${
                selectedOption === option ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
        <div className="global-input-field-container products-info-container" style={{alignItems:"start"}}>
    
          {selectedOption === "Category" && (
            <>
              <div className="global-single-input auto-complete">
                <p>Category</p>
                <Autocomplete
                  options={CategoryList || []}
                  getOptionLabel={(option) => option?.categoryName}
                  renderInput={(params) => <TextField {...params} />}
                  value={formValues?.mainCategory}
                  onChange={handleTaxChange("mainCategory")}
                  // disabled={formValues?.isEdit}
                />
                {validationAlert?.mainCategory && (
                  <p className="doc-validation-alert">
                    {validationAlert?.mainCategory}
                  </p>
                )}
              </div>
              <div className="global-single-input auto-complete">
                <p>Sub Category</p>
                <Autocomplete
                  options={filteredPOSCategory || []}
                  getOptionLabel={(option) => option?.name}
                  renderInput={(params) => <TextField {...params} />}
                  value={formValues?.posCategory}
                  onChange={handleTaxChange("posCategory")}
                  // disabled={formValues?.isEdit}
                />
                {validationAlert?.posCategory && (
                  <p className="doc-validation-alert">
                    {validationAlert?.posCategory}
                  </p>
                )}
              </div>
            
            </>
          )}
          {selectedOption === "Product Wise" && (
            <>
              <div className="global-single-input auto-complete" style={{width:"100%"}}>
                <p>Product</p>
                <Autocomplete
                  multiple
                  options={allNewProductList ||[]}
                  style={{ maxHeight: "100px" }}
                  getOptionLabel={(option) => option?.productName}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{option.productName}</span>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  value={formValues?.product}
                  onChange={handleTaxChange("product")}
                     filterOptions={filterProductsOption}
                />
                {validationAlert?.product && (
                  <p className="doc-validation-alert">
                    {validationAlert?.product}
                  </p>
                )}
              </div>

            </>
          )}
          <div className="global-single-input auto-complete">
                <p>Vendor Tax</p>
                <Autocomplete
                  multiple
                  options={
                    taxListFull
                      ?.filter((obj) => obj?.taxType === "Purchase")
                      ?.filter(
                        (option) =>
                          !formValues?.vendorTax?.length ||
                          option?.includedPrice ===
                            formValues?.vendorTax[0]?.includedPrice
                      ) || []
                  }
                  getOptionLabel={(option) => option?.taxName}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <span>{option.taxName}</span>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  value={formValues?.vendorTax}
                  onChange={handleTaxChange("vendorTax")}
                  // disabled={editEnabled}
                />
              </div>
              <div className="global-single-input auto-complete">
                <p>Customer Tax</p>
                <Autocomplete
                  multiple
                  options={
                    taxListFull?.filter((obj) => obj?.taxType === "Sales")?.filter(
                        (option) =>
                          !formValues?.customerTax?.length ||
                          option?.includedPrice ===
                            formValues?.customerTax[0]?.includedPrice
                      ) || []
                  }
                  getOptionLabel={(option) => option?.taxName}
                  renderOption={(props, option) => (
                    <li {...props} key={option?._id}>
                      <span>{option?.taxName}</span>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} />}
                  value={formValues?.customerTax}
                  onChange={handleTaxChange("customerTax")}
                  // disabled={editEnabled}
                />
              </div>
        </div>
        <div
          className="tax-button-ctn"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <button className="btn btn-secondary-outlined" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Dialog>
  );
}

AddTaxPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddTaxPopup;
