import React from "react";
import { useSelector } from "react-redux";
function ShiftDevice({showSubHeaders}) {
    const decimalPosition = localStorage.getItem("decimalPosition");
    const getBackgroundColor = (index) => {
        return index % 2 === 0 ? "#ffffff" : "#E6E6E6";
      };
      const ShiftBillTypeSummary = useSelector(
        (state) => state.posReportshiftSlice.billTypeSummary
      );
  return (
    <div
    className="new-global-table-container"
    style={{ paddingTop: "0 42px" }}
  >
    <div className="bill-main-head">
      <h5>Device</h5>
      <h5>Order Count</h5>
      <h5>Amount</h5>
    </div>
    {showSubHeaders&&(
      <>
     
    {ShiftBillTypeSummary?.device?.list!==undefined?(
      ShiftBillTypeSummary?.device?.list?.map((r,i)=>(
        <div
        className="bill-sub-head"
        style={{
          backgroundColor: "white",borderBottom:"1px solid black"
        }}
      >
        <h5>{r?.employeeName}</h5>
        <h5 style={{marginLeft:"39px"}}>{r?.count} </h5>
        <h5>{(r?.amount||0)?.toFixed(decimalPosition)}</h5>
      </div>
      ))
    ):(
      <div className="bill-sub-head" style={{ backgroundColor: "white", borderBottom: "1px solid black" }}>
      <h5>No Devices Available</h5>
    </div>
    
    )}
    </> 
    )}
   
    <div className="bill-main-head" style={{backgroundColor:"white"}}>
      <h5>All Devices</h5>
      <h5>{ShiftBillTypeSummary?.device?.count}</h5>
      <h5>{(ShiftBillTypeSummary?.device?.total||0)?.toFixed(decimalPosition)}</h5>
    </div>
  </div>
  )
}

export default ShiftDevice