import axios from "axios";
import { get_employee_permission, update_roles_and_permission } from "../../../Redux/Settings/Roles and permission/viewRolesAndPermissionSlice";
import store from "../../../Redux/store";
import { HEADERS } from "../../UrlAndPaths";
import { setSubmitLoading } from "../../../Redux/Dashboard/mainDashBoardSlice";

// Add Roles and Permission by employee id
export const addEmployeeRolesAndPermissionAPICall = async (
  body,
  setState,
  state
) => {
  store.dispatch(setSubmitLoading(true))
  await axios
  .post("settings/addsettings", body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
        setState({...state,message:"Permission Updated",success:true})
        store.dispatch(setSubmitLoading(false))
      }
    })
    .catch((err) => {
      setState({...state,message:"Something went wrong !!",error:true})
      store.dispatch(setSubmitLoading(false))
     
    });
};

//Get roles and permission of an employee or by designation
export const getRolesAndPermissionAPICall = async (body) => {
  await axios
    .post("settings/viewuserpermission", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(update_roles_and_permission({rolesAndPermission:res.data}))
      }
    })
    .catch((err) => {
        store.dispatch(update_roles_and_permission({rolesAndPermission:undefined}))
        console.error(err.response);
    });
};

// get employee roles and permission
export const employeeRolesAndPermission=(body)=>{
  store.dispatch(setSubmitLoading(true))
  axios.post("employee/viewemployeepermissions",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(setSubmitLoading(false))
      store.dispatch(get_employee_permission({employeePermissionData:res.data}))
      localStorage.setItem("userpermission",JSON.stringify(res.data))
    }
  })
  .catch((err)=>{
    store.dispatch(setSubmitLoading(false))
    store.dispatch(get_employee_permission({employeePermissionData:undefined}))

  })
}