import React from 'react';
import dummyProductImg from "../../../Assets/BillingImages/emptyFoodImg.svg"
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';

const MobileCartItem = ({item,key,handleDecrease,handleIncrease,handleDelete}) => {

    const decimalPosition=localStorage.getItem("decimalPosition");
    const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)
  
  
    return (
        <div className="self-cart-card" key={key}>
          <div className="self-cart-card__content">
            <img
              src={ item?.imageUrl !== null &&
                item?.imageUrl.length !== 0
                  ? item?.imageUrl
                  : dummyProductImg
              }
              alt="Ambur Chicken Biryani"
              className="self-cart-card__image"
            />
            <div className="self-cart-card__details">
              <p className="self-cart-card__name">{item?.productName}</p>
              <p className="self-cart-card__size">{Number(item?.salesPrice)?.toFixed(decimalPosition)}</p>
            </div>
          </div>
          <div className="self-cart-card__actions">
            <div className="self-cart-card__quantity">
              <button onClick={handleDecrease(item)} className="self-cart-card__button">−</button>
              <span className="self-cart-card__quantity-number">{item.quantity}</span>
              <button onClick={handleIncrease(item)} className="self-cart-card__button">+</button>
            </div>
            <div className="self-cart-card__price">{currencyDetails?.symbol || ""} {Number(item?.subTotalWoTax)?.toFixed(decimalPosition)}</div>
            <button
             onClick={handleDelete(item)}
             disabled={item?.objType}
             className="self-cart-card__delete"><DeleteIcon color={item?.objType?'disabled':'error'}/></button>
          </div>
        </div>
      );
    };
  
  export default MobileCartItem;
