import React, { useEffect, useState } from 'react'
import CustomPagination from '../../../../Single Components/CustomPagination'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { IconButton, Tooltip } from '@mui/material'
import ReactToPrint from 'react-to-print'
import { useSelector } from 'react-redux'
import { ListPosWalletAndPaymentAPI } from '../GeneralAPI'
import { getCurrentTime } from '../../../../../Js/Date'
import ExcelJS from "exceljs"
import TableLoading from "../../../../Single Components/TableLoading"
import TableListActionButtons from '../../../../Single Components/ActionButtons/TableListActionButtons'

const PosWallet = () => {
  const [FormDataInfo, setFormDataInfo] = useState([])
  const [filteredData, setFilteredData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const userRole = useSelector((state) => state.userRoleSlice.value);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)
};
const [currentPage, setCurrentPage] = useState(1);

const handlePageChange = (page) => {
    
  setCurrentPage(page);
};
const handleFilterChange = (updatedItems) => {
  setFilteredData(updatedItems);
};
const formatFilterInfo = (filters) => {
  const parts = [];

  if (filters?.toDate&&filters.fromDate) {
    parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
  }
  if (filters?.branchList) {
    parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
  }
  if (filters?.status) {
    parts.push(`status: ${filters?.status}`);
  }
  if (filters?.PaymentType) {
    parts.push(`Payment Type: ${filters?.PaymentType?.label}`);
  }
 
  
  return parts; // Return the array directly
};

const downloadList = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("wallet");
  sheet.properties.defaultRowHeight = 20;

  // Add header section
  const titleRow = sheet.addRow(["wallet"]);
  titleRow.font = { size: 15, bold: true };
  sheet.mergeCells('A1:I1');
  titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  // Add additional header information
  const filterParts = formatFilterInfo(filteredData) || [];

  // Ensure filterParts is an array and iterate over it
  if (Array.isArray(filterParts)) {
    filterParts.forEach((part, index) => {
      const row = sheet.addRow([part]);
      row.font = { size: 11, bold: true };
      sheet.mergeCells(`A${index + 2}:I${index + 2}`);
      row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
    });
  }

  // Add an empty row between header and table
  sheet.addRow([]);

  // Header row styling
  const headerRow = sheet.addRow(["Date", "Sequence", "Order Ref", "Customer","Branch","Shift","Employee","Payment Method","Amount"]);
  const headerFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
  };
  const headerFont = {
    size: 12,
    bold: true,
    color: { argb: "FF000000" }, // White font color
  };

  headerRow.eachCell((cell, colNumber) => {
    cell.fill = headerFill;
    cell.font = headerFont;
    cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Ensure header text is centered
  })

  sheet.columns = [
    { header: "Date", key: "paymentDate", width: 18 },
    { header: "Sequence", key: "sequence", width: 18 },
    { header: "Order Ref", key: "orderReference", width: 20 },
    { header: "Customer", key: "customerName", width: 25 },
    { header: "Branch", key: "branchName", width: 15 },
    { header: "Shift", key: "shiftId", width: 15 },
    { header: "Employee", key: "employeeName", width: 15 },
    { header: "Payment Method", key: "paymentMethod", width: 25 },
    { header: "wallet", key: "paidAmount", width: 15 },


  ];

  walletList?.list?.forEach((item) => {
    const row = sheet.addRow({
      paymentDate: item.paymentDate,
      sequence: item.sequence,
      orderReference: item?.orderReference,
      customerName: item?.customerName,
      branchName: item?.branchName,
      shiftId: item?.shiftId,
      employeeName: item?.employeeName,
      paymentMethod: item?.paymentMethod,
      paidAmount: item?.paidAmount,
    
    
    });

    const rowFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
    };
    
    row.eachCell((cell, colNumber) => {
      cell.fill = rowFill;
      cell.border = {
      //   top: { style: "thin", color: { argb: "FF000000" } },
      //   left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FFedf2f9" } },
      //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber >= 1) { // Right align all columns except the first
          cell.alignment = { horizontal: 'center' };
        }
       
    });
  });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "pos wallet.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
const walletList=useSelector((state)=>state.generalSlice.PaymentAndWalletList)
const totalPages = walletList?.pages; 

useEffect(()=>{
  if (FormDataInfo.length !== 0) {
  ListPosWalletAndPaymentAPI({
    fromDate: FormDataInfo.fromDate === '' ? undefined : `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    endDate: FormDataInfo.toDate === '' ? undefined : `${FormDataInfo.toDate} ${getCurrentTime()}`,
    branchId: FormDataInfo?.branchList?.length === 0 ? undefined:FormDataInfo?.branchList,
    // status: FormDataInfo?.status?.length === 0 ? undefined : FormDataInfo?.status[0],
    search: FormDataInfo?.search===''? undefined : FormDataInfo?.search,
    // paymentMethod:FormDataInfo?.PaymentType ==='' ? undefined : FormDataInfo?.PaymentType,
    isWallet:true,
    index:currentPage-1},setIsLoading)
  }
},[FormDataInfo,currentPage])

  return (
    <div className='global-page-parent-container updated-global-page-parent-container'>
        <div className="justify-space-between list-create-head-bar">
           <h3>Wallet</h3>
           <div className="create-head-buttons-container">
            <TableListActionButtons downloadClick={downloadList}/>
            <CategorySearchandFilter
                statusOptions={['DONE', 'DRAFT']}
                onData={handleDataFromChild}
                isBranch={userRole==="admin"}
                onFilterChange={handleFilterChange}
                onBranchSelect={()=>{}}
            />
        </div>
        </div>

        <div className="justify-space-between pagination-container">
              <CustomPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
        </div>

        
        <div className="new-global-table-container updated-table-container">
            <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Sequence</th>
                <th>Order Ref</th>
                <th>Customer</th>
                <th>Branch</th>
                <th>Shift</th>
                <th>Employee</th>
                <th>Payment Methord</th>
                <th>Amount</th>
              </tr>
            </thead>
            {isLoading ? (
                <TableLoading  colSpan={8} rowcount={10}/>
              ) : (
            <tbody>
                {(walletList?.list!==undefined && walletList?.list.length!==0) ? walletList?.list?.map((r,i)=>(
                        <tr key={i} >
                            <td>{r.paymentDate}</td>
                            <td>{r.sequence}</td>
                            <td>{r.orderReference}</td>
                            <td>{r.customerName}</td>
                            <td>{r.branchName}</td>
                            <td>{r.shiftId}</td>
                            <td>{r.employeeName}</td>
                            <td>{r.paymentMethod}</td>
                            <td>{r.paidAmount}</td>
                            
                        </tr>
                    )):
                    <tr>
                      <td colSpan={9}>NO DATA</td>
                    </tr>
                }
            </tbody>
              )}
            </table>
            </div>
    </div>
  )
}

export default PosWallet