import { createSlice } from "@reduxjs/toolkit";

const loginFormNewSlice=createSlice({
    name:"loginFormNewSlice",
    initialState:{
        companyCode:null,
        userName:null,
        password:null
    },
    reducers:{
        set_company_code_login:(state,action)=>{
            state.companyCode=action.payload
        },
        set_user_name:(state,action)=>{
            state.userName=action.payload
        },
        set_password_login:(state,action)=>{
            state.password=action.payload
        }
    }
})
export const {set_company_code_login,set_user_name,set_password_login}=loginFormNewSlice.actions
export default loginFormNewSlice.reducer