import React from 'react'
import SelectCard from '../Card/SelectCard'
import dineInImg from "../../../Assets/SelfOrder/dineIn.png"
import takeAwayImg from "../../../Assets/SelfOrder/Takeaway.png"
import { useDispatch } from 'react-redux'
import { setSelfChairIds, setSelfOrderScreen, setSelfOrderType, setSelfTableIds, setSelfTableNames } from './selfOrderEssentialsSlice'
import { setScreenSelected } from '../../Slices/selfOrderMainSlice'
import { listAllFloorAPI } from '../../../component/Modules/FloorManagement/addSeat/floorManagementApi'
import MenuButton from '../Buttons/MenuButton'
import { singleSelfOrderInfoAPICall } from '../../API/selfOrderAPIS'
const OrderType = () => {
  const dispatch=useDispatch()
  const selfUser = localStorage.getItem("selfUser");
  const selfDefaultTableInfo = localStorage.getItem("selfDefaultTableInfo");
  const selfOrderId = localStorage.getItem("selfOrderId");


  const cardClick=(click)=>{
    dispatch(setSelfOrderType(click))
    dispatch(setScreenSelected("orderScreen"))
    let dineInFn = () => {
      if (selfUser === "SELF" && selfDefaultTableInfo) {
        let tableInfo= JSON.parse(selfDefaultTableInfo);
          dispatch(setSelfTableIds(tableInfo?.selfTableIds || []))
          dispatch(setSelfTableNames(tableInfo?.selfTableName || []))
          dispatch(setSelfChairIds(tableInfo?.selfChairIds || []))

          localStorage.setItem("defaultFloorId",tableInfo?.floorId)
          dispatch(setSelfOrderScreen("orderProductLists"))
          singleSelfOrderInfoAPICall({id:selfOrderId})
        }else{
          dispatch(setSelfOrderScreen("orderFloorPlan"))
          localStorage.removeItem("selfOrderId")
        } 
      listAllFloorAPI()
    }
    click==="DINEIN" ? dineInFn(): dispatch(setSelfOrderScreen("orderProductLists"))
  }

  return (
    <div className="self-login-ctn">
        <MenuButton/>
        <h3>ORDER TYPE</h3>
        <div className="justify-center" style={{gap:"15px",flexWrap:"wrap"}}>
            <SelectCard image={dineInImg} label="DINE-IN" onClick={()=>cardClick("DINEIN")}/>
            <SelectCard image={takeAwayImg} label="TAKE-AWAY" onClick={()=>cardClick("TAKEAWAY")}/>
        </div>
    </div>
  )
}

export default OrderType