export const headersKeyName = {
  ADDFLOOR: "addFloor",
  ADDTABLE: "addTable",
  ADDSEATS: "addSeats",
  ADDSHAPE: "addShape",
  SETCOLOR: "changeColor",
  RENAME: "rename",
  COPY: "copy",
  DELETE: "delete",
  ENABLESEATSELECTION: "enableSeatSelection",
};

export const addSeatFormKeyName = {
  BRANCHES: "branches",
  BRANCH: "branch",
  FLOOR: "floor",
  TABLE: "table",
  SEATS: "seats",
  SHAPE: "shape",
  COLOR: "color",
  FLOORCOLOR: "floorColor",
  FLOORNAME: "floorName",
};

export const alertMessages = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const shapeTypes = {
  TABLE: "TABLE",
  CHAIR: "CHAIR",
  SHAPE: "SHAPE",
};

export const structures = {
  ELLIPSE: "Ellipse",
  RECTANGLE: "Rectangle",
  POLYGON: "Polygon",
  LINESEGMENTTOOL: "LineSegmentTool",
  SPLITFLOOR:"SplitFloor",
  STAR:"Start",
  HORIZONTAL:"Horizontal"
};
