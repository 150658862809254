import React from 'react'
import { useSelector } from 'react-redux';
import eyetLogo from "../../../Assets/SelfOrder/eyetlogo.png"
const SelfOrderFooter = ({redBtnText,blackBtnText,redBtnOnClick,blackBtnOnclick}) => {
  const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue);
  return (
    <div className='self-order-footer-ctn justify-space-between'>
        <div className="logo-ctn flex-column">
            <img src={companyProfileData?.logo} alt="" className="company-logo" />
            <div className='powered-by-ctn'>
                <p>Powered by</p>
                <img className='eyet-logo' src={eyetLogo} alt="" />
            </div>  
        </div>
        <div className="footer-btn-container">
           {blackBtnText && <button className="self-order-button btn-back" onClick={blackBtnOnclick}>{blackBtnText}</button>}
           {redBtnText &&  <button className="self-order-button btn-next" onClick={redBtnOnClick}>{redBtnText}</button>}
        </div>
    </div>
  )
}

export default SelfOrderFooter