import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { setKeyBoardOnClose, setVisibleKeyboard } from './customKeyBoardSlice';
const CustomeKeyBoard = (props) => {

  const {input,setInput,enterClick,inputRef}=props
  const {keyBoardVisible,keyBoardOnClose} = useSelector((state) => state.customKeyBoardSlice);

  const dispatch=useDispatch()
  const keyBoardRef=useRef(null)
  const [layout, setLayout] = useState("default");
  const [internalInput, setInternalInput] = useState(input || "");
  const keyboard = useRef();

  const onChange = input => {
    setInput(input);
    setInternalInput(input);
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    if (button === "{shift}" || button === "{lock}") handleShift();

    if(button==="{enter}") enterClick()
  };

  useEffect(() => {
    if (input || input==="") {
      setInternalInput(input);
      keyboard.current.setInput(input);
    }
  }, [input]);

  useEffect(() => {
    dispatch(setKeyBoardOnClose(false))
    const handleClickOutside = (event) => {
      if(keyBoardVisible==="referenceKb") return;
        if (
            keyBoardRef.current &&
            !keyBoardRef.current.contains(event.target)
        ) {
          dispatch(setKeyBoardOnClose(true))
          inputRef===undefined &&  dispatch(setVisibleKeyboard(null))
        }
    };
        document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [keyBoardVisible]);

  return (
    <div className="keyboard-container" ref={keyBoardRef}>
      <Keyboard
        keyboardRef={r => (keyboard.current = r)}
        layoutName={layout}
        onChange={onChange}
        onKeyPress={onKeyPress}
        input={internalInput}
      />
    </div>
  )
}

export default CustomeKeyBoard