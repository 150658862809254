import React, { useState } from "react";
import { useSelector } from "react-redux";

const CategorySalesSummaryPrint = () => {
  const decimalPosition = localStorage.getItem("decimalPosition");
  const CategorySalesSummary = useSelector(
    (state) => state.posReportshiftSlice.categorySalesSummary
  );

  const { showSubHeaders } = useSelector((state) => state.posReportshiftSlice);

  return (
    <div className="new-global-table-container shift-report-print-container">
      <div className="bill-main-head">
        <h5>Category Name</h5>
        <h5>Amount</h5>
      </div>
      {CategorySalesSummary ? (
        CategorySalesSummary?.map((partner, index) => (
          <>
            <div key={partner?._id} className="bill-sub-head">
              <h5>{partner?.categoryName}</h5>
              <h5>{partner?.totalAmount?.toFixed(decimalPosition)}</h5>
            </div>

            {showSubHeaders && (
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {partner?.products?.length > 0 ? (
                    partner?.products?.map((order, orderIndex) => (
                      <tr key={orderIndex}>
                        <td className="product-column">
                          <div>{order?.product}</div>
                        </td>
                        <td>{order?.quantity}</td>
                        <td>{order?.amount?.toFixed(decimalPosition)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </>
        ))
      ) : (
        <div className="bill-sub-head" style={{ justifyContent: "center" }}>
          <h5>No Records</h5>
        </div>
      )}
    </div>
  );
};

export default CategorySalesSummaryPrint;
