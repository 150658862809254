import React from 'react'
import './selfOrderCart.css'
import SelfOrderFooter from '../Navbar/SelfOrderFooter'
import EmptyCart from './EmptyCart'
import SelfCartList from './SelfCartList'
import { useDispatch, useSelector } from 'react-redux'
import { setSelfCustomerTopbar, setSelfOrderScreen } from '../Order/selfOrderEssentialsSlice'

const SelfOrderCart = () => {

    const dispatch=useDispatch()
    const {cartArray}=useSelector((state)=>state.selfOrderCartSlice)
    
    const backBtnClick=() => dispatch(setSelfOrderScreen("orderProductLists"))

    const confirmOrderClick=()=>{
      dispatch(setSelfCustomerTopbar(false))
      dispatch(setSelfOrderScreen("loginScreen"))
    }
  return (
      <div>
          <div className='self-order-middle-container'>
              {cartArray?.length===0 ?<EmptyCart backBtnClick={backBtnClick}/>:<SelfCartList backBtnClick={backBtnClick}/> }
          </div>
          <SelfOrderFooter 
            blackBtnText={"ADD MORE +"} 
            redBtnText={cartArray?.length===0?null:"CONFIRM ORDER"}
            redBtnOnClick={confirmOrderClick}
            blackBtnOnclick={backBtnClick}
            />
      </div>
  )
}

export default SelfOrderCart