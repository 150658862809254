import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import ShiftTimerComponent from '../../../component/Single Components/ShiftTimerComponent';
import { selfDialogScreenSelected, setScreenLoginType } from '../../Slices/selfOrderMainSlice';
import { getShiftStatusAPICAll } from '../../../API/Settings/Shift/viewShiftAPI';
import { getCurrentTime, today } from '../../../Js/Date';
import DvrIcon from '@mui/icons-material/Dvr';
import { onGoingOrdersForSelf } from '../../API/selfOrderAPIS';
import SelfOngoingOrders from '../Order/SelfOngoingOrders';
import { errorSnackMsg } from '../../../component/Custom Hooks/SnackkBarUtilities';
const MenuDialog = ({ open, handleClose }) => {
  const theme = useTheme();
  const dispatch=useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);
  const { branchPk,data} = useSelector((state) => state.loginResponseSlice.value);
  const {ongoingOrders}=useSelector((state)=>state.selfOrderMainSlice)
  const loginChangeClick=()=>{
    localStorage.setItem("selfUser","selfUser")
    dispatch(setScreenLoginType("selfUser"))
    dispatch(selfDialogScreenSelected(null))
  }

  const logoutClick=()=>{
    const printValue = localStorage.getItem("PrinterSettings");
    localStorage.clear();
    if (printValue !== null) {
      localStorage.setItem("PrinterSettings", printValue);
    }
    window.location.replace("/");
  }
  const ordersClick=()=>{
    if(ongoingOrders?.length===0){
      errorSnackMsg("No existing orders !!")
    }else{
      dispatch(selfDialogScreenSelected("ordersDialog"))
    }
  }
  const getShiftStatus=()=>{
    getShiftStatusAPICAll({
      branchId:branchPk,
      curTime: `${today} ${getCurrentTime()}`,
    });
  }
  
  useEffect(()=>{
    if(open){
      getShiftStatus()
      onGoingOrdersForSelf({type:1,waiterId:data?._id,branchId:branchPk})
    } 
  },[open])
  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="xs" 
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
          SHIFT {shiftStatus?shiftStatus?.shiftId:"Not found"}
          </Typography>
          {
            !shiftStatus &&
          <IconButton onClick={getShiftStatus}>
            <RefreshIcon color='error'/>
          </IconButton>}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography variant="h4" margin={0}>
            <ShiftTimerComponent/>
          </Typography>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={4} container justifyContent={isMobile?"end":"center"}>
            {isMobile ? (
              <IconButton onClick={loginChangeClick} color="primary" sx={{backgroundColor:"#e7e7e7"}}>
                <SwitchAccountIcon />
              </IconButton>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={loginChangeClick}
              >
                 Login Type
              </Button>
            )}
          </Grid>

          <Grid item xs={4} container justifyContent={"center"}>
            {isMobile ? (
              <Badge
                badgeContent={ongoingOrders?.length > 0 ? ongoingOrders.length : null}
                color="error"
                overlap="circular"
              >
                <IconButton
                  color="warning"
                  sx={{ backgroundColor: "#e7e7e7" }}
                  onClick={ordersClick}
                >
                  <DvrIcon />
                </IconButton>
              </Badge>
            ) : (
              <Badge
                badgeContent={ongoingOrders?.length > 0 ? ongoingOrders.length : null}
                color="error"
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="warning"
                  onClick={ordersClick}
                >
                  Orders
                </Button>
              </Badge>
            )}
          </Grid>

          <Grid item xs={4} container justifyContent={isMobile?"start":"center"}>
            {isMobile ? (
              <IconButton 
              onClick={logoutClick}
              color="error" sx={{backgroundColor:"#e7e7e7"}}>
                <LogoutIcon />
              </IconButton>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="error"
                onClick={logoutClick}
              >
                Logout
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>

      <SelfOngoingOrders/>
    </Dialog>
  );
};

export default MenuDialog;
