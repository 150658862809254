import React, { useState } from 'react'
import { useSelector } from 'react-redux';

const BillTypeSummaryPrint = () => {
  const decimalPosition = localStorage.getItem("decimalPosition");
    
  const ShiftBillTypeSummary = useSelector((state) => state.posReportshiftSlice.billTypeSummary);
  const {showSubHeaders} = useSelector((state) => state.posReportshiftSlice);
  const deliveryTotal = (ShiftBillTypeSummary?.selfDelivery?.total || 0) + (ShiftBillTypeSummary?.aggregator?.total|| 0)
  return (
    <div
      className="new-global-table-container shift-report-print-container"
      style={{ paddingTop: "0 42px" }}
    >
      <div className="bill-main-head">
        <h5>Bill Type</h5>
        <h5>Amount</h5>
      </div>
      <div
        className="bill-sub-head"
      >
        <h5>Dine In</h5>
        <h5>
        {(ShiftBillTypeSummary?.dineIn?.total||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Bill No</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.dineIn?.list !== undefined ? (
              ShiftBillTypeSummary?.dineIn?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r?.billNo}
                  </td>
                  <td>
                    {r?.date}
                  </td>
                  <td>
                    {r?.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
      >
        <h5>Take Away</h5>
        <h5>
        {(ShiftBillTypeSummary?.takeAway?.total||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Bill No</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.takeAway?.list !== undefined ? (
              ShiftBillTypeSummary?.takeAway?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.billNo}
                  </td>
                  <td>
                    {r.date}
                  </td>
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
      >
        <h5>Delivery</h5>
        <h5>
        {(deliveryTotal||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <div className="bill-sub2-head">
          <h5>{ShiftBillTypeSummary?.selfDelivery?.count?`(${ShiftBillTypeSummary?.selfDelivery?.count})`:""}</h5>
          <h5>
          {(ShiftBillTypeSummary?.selfDelivery?.total || 0)?.toFixed(decimalPosition)}
          </h5>
        </div>
      )}
      {showSubHeaders && (
        <table>
          <thead >
            <tr>
              <th>Bill No</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.selfDelivery?.list !== undefined ? (
              ShiftBillTypeSummary?.selfDelivery?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.billNo}
                  </td>
                  <td>
                    {r.date}
                  </td>
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {showSubHeaders && (
        <div className="bill-sub2-head">
          <h5>Aggregator{ShiftBillTypeSummary?.aggregator?.count?`(${ShiftBillTypeSummary?.aggregator?.count})`:""}</h5>
          <h5>
          {(ShiftBillTypeSummary?.aggregator?.total || 0)?.toFixed(decimalPosition)}
          </h5>
        </div>
      )}
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>O/ID</th>
              {/* <th>Date</th> */}
              <th>Disc</th>
              <th>Comm.</th>
              <th>Amt</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.aggregator?.list !== undefined ? (
              ShiftBillTypeSummary?.aggregator?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.aggregatorName}
                  </td>
                  <td>
                    {r.billNo}
                  </td>
                  {/* <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.date}
                  </td> */}
                  <td>
                    {r.discount?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {r.commission}
                  </td>
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head">
        <h5>Staff Expense</h5>
        <h5>
        {(ShiftBillTypeSummary?.staffExpense?.total||0)?.toFixed(decimalPosition) }
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Expense</th>
              <th>Emp</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.staffExpense?.list !== undefined ? (
              ShiftBillTypeSummary?.staffExpense?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.expense}
                  </td>
                  <td>
                    {r.employee}
                  </td>
                  <td>
                    {r.date}
                  </td>
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
      >
        <h5>Outlet Expense</h5>
        <h5>
        {(ShiftBillTypeSummary?.outletExpense?.total || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Shift ID</th>
              <th>Doc No</th>
              <th>Expense</th>
              <th>Date</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.outletExpense?.list !== undefined ? (
              ShiftBillTypeSummary?.outletExpense?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.shift}
                  </td>
                  <td>
                    {r.docNo}
                  </td>
                  <td>
                    {r.expense}
                  </td>
                  <td>
                    {r.date}
                  </td>
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head">
        <h5>Refund</h5>
        <h5>
        {(ShiftBillTypeSummary?.refund?.total || 0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>O/ID</th>
              <th>Product</th>
              <th>Type</th>
              {/* <th>Date</th> */}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.refund?.list !== undefined ? (
              ShiftBillTypeSummary?.refund?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.billNo}
                  </td>
                  <td className='product-column'>
                    <div>
                      {r.product}
                    </div>
                  </td>
                  <td>
                    {r.type}
                  </td>
                  {/* <td>
                    {r.date}
                  </td> */}
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head">
        <h5>Cash & Card</h5>
        <h5>
        {(ShiftBillTypeSummary?.cashAndCard?.total ||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Pay Method</th>
              <th>Amount</th>
              <th>Comm.</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.cashAndCard?.list !== undefined ? (
              ShiftBillTypeSummary?.cashAndCard?.list?.map((r, i) => (
                <tr key={i}>
                  <td>
                    {r.type}
                  </td>
                  <td>
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {r.commission?.toFixed(decimalPosition)}
                  </td>
                  <td>
                    {r.balance?.toFixed(decimalPosition)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
        <div
        className="bill-sub-head"
      
      >
        <h5>Delivery Charge</h5>
        <h5>
          {(ShiftBillTypeSummary?.deliveryCharge?.total ||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead >
            <tr>
              <th>Bill No</th>
              <th>Amount</th>
            
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.deliveryCharge?.list !== undefined ? (
              ShiftBillTypeSummary?.deliveryCharge?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                 
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div
        className="bill-sub-head"
       
      >
        <h5>Packing Charge</h5>
        <h5>
          {(ShiftBillTypeSummary?.packagingCharge?.total ||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
      {showSubHeaders && (
        <table>
          <thead>
            <tr>
              <th>Bill No</th>
              <th>Amount</th>
             
            </tr>
          </thead>
          <tbody>
            {ShiftBillTypeSummary?.packagingCharge?.list !== undefined ? (
              ShiftBillTypeSummary?.packagingCharge?.list?.map((r, i) => (
                <tr key={i}>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.billNo}
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black", height: "29px" }}
                  >
                    {r.amount?.toFixed(decimalPosition)}
                  </td>
                 
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>NO DATA</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default BillTypeSummaryPrint