import React, { useEffect, useState } from 'react';
import "./heldOrderGridView.css";
import TableRestaurantTwoToneIcon from "@mui/icons-material/TableRestaurantTwoTone";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateFormat, convertToAMPM, getCurrentTime } from '../../../../../Js/Date';
import { setOrderIdInPOS } from '../Slice/billingScreenSlice';
import { Skeleton } from '@mui/material';
import { getInvoiceReceipt } from '../API/billingPaymentSection';
import PlaceIcon from '@mui/icons-material/Place';
function HeldOrderGridView() {

    const dispatch=useDispatch()
    const decimalPosition = localStorage.getItem("decimalPosition");
    const { heldOrders ,tableLoading} = useSelector((state) => state.viewOrdersBillingSlice);
    const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)

    const viewOrderSingleClick=(row)=>()=>{
        dispatch(setOrderIdInPOS(row?._id))
        getInvoiceReceipt({orderId:row?._id})
      }
   

      const calculateTimeDifference = (startDateTime) => {
        if (!startDateTime) {
            let bgColor = "rgb(123 112 112)";
            let backgroundColor = "#e7e7e7";
            return { formattedTime: { hours: "00", minutes: "00", seconds: "00" }, bgColor, backgroundColor };
        } else {
            const startTime = new Date(startDateTime);
            const endTime = new Date();
    
            const timeDiffInMilliseconds = endTime - startTime;
            const timeDiffInSeconds = Math.floor(timeDiffInMilliseconds / 1000);
    
            const hours = String(Math.floor(timeDiffInSeconds / 3600)).padStart(2, '0');
            const minutes = String(Math.floor((timeDiffInSeconds % 3600) / 60)).padStart(2, '0');
            const seconds = String(Math.floor(timeDiffInSeconds % 60)).padStart(2, '0');
    
            let bgColor;
            let backgroundColor;
            let isBlinkingYellow = false;
            let isBlinkingRed = false;
            if (timeDiffInSeconds > 0 && timeDiffInSeconds <= 1800) {
                bgColor = "#209230";
                backgroundColor = "#e8f6e9";
            } else if (timeDiffInSeconds <= 3600) {
                bgColor = "rgb(230, 204, 72)"; // Yellow
                backgroundColor = "rgb(252, 251, 244)";
                isBlinkingYellow = true;
            } else {
                bgColor = "#e64848"; // Red
                backgroundColor = "rgb(252 251 244)";
                isBlinkingRed = true;
            }
            return { formattedTime: { hours, minutes, seconds }, bgColor, backgroundColor, isBlinkingYellow, isBlinkingRed };
        }
    };
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime(new Date());  // Update time every second
      }, 1000);
  
      return () => clearInterval(intervalId);  // Clean up on component unmount
    }, []);
    return (
        <div className='held-order-grid-ctn'>
            {
            tableLoading?
                    [...Array(6)].map((_,i)=>(
                        <div
                            key={i}
                            className={`order-card`}
                            style={{ backgroundColor: "#fff" }}>
                            <div className="order-card-head justify-space-between">
                                <Skeleton width={"100%"} height={50} />
                            </div>
                            <div className="middle-ctn">
                                <div className="middle-ctn-left">
                                    <div className="justify-space-between" style={{ justifyContent: "space-between" }}>
                                        <p><Skeleton width={60} height={30} /></p>
                                        <div className='justify-center' style={{ gap: "5px" }}>
                                            <Skeleton width={60} height={30} />
                                        </div>
                                    </div>
                                    <div className='customer-tag' style={{ padding: 0, width: "100%" }}> <Skeleton width={"100%"} height={60} /></div>
                                </div>
                                <div className="middle-ctn-right">
                                    <div className="hour-label">

                                    </div>
                                    <div className={`hour-value`}>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    ))
                    
            :
            heldOrders.length !== 0 ? (
                heldOrders.map((item, i) => {
                    const { formattedTime, bgColor, backgroundColor, isBlinkingYellow, isBlinkingRed } = calculateTimeDifference(!(item?.status === "CHDRAFT") && `${item?.orderDate} ${item?.orderTime}`);

                    return (
                        <div key={i} 
                            onClick={viewOrderSingleClick(item)}
                            className={`order-card  ${isBlinkingYellow ? 'blinking-yellow-order' : ''} ${isBlinkingRed ? 'blinking-red-order' : ''}`} 
                            style={{ backgroundColor: backgroundColor,opacity:item.status==="CHDRAFT" &&0.7 }}>
                            <div className="order-card-head justify-space-between" style={{ backgroundColor: bgColor, color: "#fff" }}>
                                <p>{convertDateFormat(item?.orderDate)}</p>
                                {
                                    item?.status==="CHDRAFT" && 
                                    <p style={{fontSize:"0.8rem",fontWeight:"400"}}>Booking</p>
                                }
                                <p>{convertToAMPM(item?.orderTime)}</p>
                            </div>
                            <div className="middle-ctn">
                                <div className="middle-ctn-left">
                                    <div className="justify-space-between">
                                        <p>{item?.name}</p>
                                        <div className='justify-center' style={{ gap: "5px" }}>
                                            {
                                            item.ordType==="DELIVERY"?
                                            <DeliveryDiningIcon />:
                                            <TableRestaurantTwoToneIcon />
                                            }
                                            <p>
                                                {
                                                    item.ordType==="DELIVERY"?
                                                    <>{item?.aggregatorName}</>
                                                    :
                                                    <>{
                                                item.tableNames.length!==0?
                                                <>
                                                {item.tableNames.map((tableName, index) => (
                                                    <span key={index}>
                                                        {index===0 && item.tableNames.length-1 >0 && "("}
                                                        {tableName}
                                                        {index !== item.tableNames.length - 1 && ","}
                                                        {index === item.tableNames.length - 1 && item.tableNames.length-1 >0 && ")"}

                                                        {/* {item.chairId.length!==0 && `CH${item.chairId.length}`} */}
                                                    </span>
                                                ))}
                                                {
                                                    item.tableNames.length>2 &&
                                                <br />}
                                                <span>

                                                {item.chairId.length !== 0 &&
                                                    `/CH-${item.chairId.length}`}
                                                    </span>
                                                </>
                                                :<span>-NIL-</span>
                                                    }</>
                                            }
                                            {
                                               item.ordType!=="DELIVERY" 
                                            }
                                            </p>
                                        </div>
                                    </div>
                                   
                                    <div className='customer-tag' style={{ border: `1px solid ${bgColor}` }}>{item?.customerName}</div>
                                   {
                                   item?.ordType==="DELIVERY" &&
                                   <div className="customer-address-ctn-heldorder" style={{ border: `1px solid ${bgColor}` }}>
                                        <PlaceIcon fontSize='small'/>
                                        <span>{Object.keys(item?.custAddress || {}).map((key, index, keys) => (
                                            <>
                                                {item?.custAddress[key]}
                                                {item?.custAddress[key] && index < keys.length - 1 && ", "}
                                            </>
                                        ))}
                                        {Object.values(item?.custAddress || {}).every(val => !val) && "-NIL-"}
                                        </span>
                                    </div>}
                                </div>
                                <div className="middle-ctn-right">
                                    <div className="hour-label">
                                        <p style={{ color: bgColor }}>H</p>
                                        <p style={{ color: bgColor }}>M</p>
                                        <p style={{ color: bgColor }}>S</p>
                                    </div>
                                    <div className={`hour-value ${isBlinkingYellow ? 'blinking-yellow' : ''} ${isBlinkingRed ? 'blinking-red' : ''}`}>
                                        <p style={{ backgroundColor: bgColor }}>{formattedTime.hours}</p>
                                        <p style={{ backgroundColor: bgColor }}>{formattedTime.minutes}</p>
                                        <p style={{ backgroundColor: bgColor }}><span>{formattedTime.seconds}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="justify-space-between order-card-footer">
                                <div style={{ backgroundColor: bgColor }}>{item?.waiterName || "-NIL-"}</div>
                                <div style={{ backgroundColor: bgColor }}>{currencyDetails?.symbol} {item?.amtTotal?.toFixed(decimalPosition)}</div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className='center-div'>No Orders found !!</div>
            )}
        </div>
    );
}

export default HeldOrderGridView;
