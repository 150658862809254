import React from 'react'
import { useSelector } from 'react-redux';

function DeliveryBoysSumm({showSubHeaders}) {
    const getBackgroundColor = (index) => {
        return index % 2 === 0 ? "#ffffff" : "#dfdfdf";
      };
      const deliveryBoysSummary = useSelector((state)=>state.posReportshiftSlice.deliveryBoysSummary);
      const decimalPosition = localStorage.getItem("decimalPosition");
  return (
    <div
    className="new-global-table-container"
    style={{ paddingTop: "0 42px" }}
  >
    <div className="bill-main-head">
      <h5>Delivery Boys</h5>
      <h5>Amount</h5>
    </div>
    {deliveryBoysSummary!==undefined?(
      deliveryBoysSummary?.map((partner, index) => (
        <>
            <div key={partner._id} className="bill-sub-head"  style={{ backgroundColor: showSubHeaders ? "#E6E6E6" : getBackgroundColor(index) }}>
              <h5>{partner.partner}</h5>
              <h5>{partner.total?.toFixed(decimalPosition)}</h5>
            </div>
            
            {showSubHeaders && (
              <table>
                <thead style={{ backgroundColor: "#C0CFF8" }}>
                  <tr>
                    <th>Order No</th>
                    <th>Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                  partner.data !==undefined?(
                       partner?.data?.map((order, orderIndex) => (
                    <tr key={orderIndex}>
                      <td style={{ borderBottom: "1px solid black", height: "29px" }}>
                        {order.orderNo}
                      </td>
                      <td style={{ borderBottom: "1px solid black", height: "29px" }}>
                        {order.date}
                      </td>
                      <td style={{ borderBottom: "1px solid black", height: "29px" }}>
                        {order.amount?.toFixed(decimalPosition)}
                      </td>
                    </tr>
                  )
                  )
               ):(
                <tr>
                  <td colSpan={3}>No data</td>
                </tr>
               )}
                </tbody>
              </table>
            )}
         </>
        )
    )
   ):(
   

    <div
    className="bill-sub-head" style={{ justifyContent: "center" }}>
   No Records
</div>
  ) }
    
  </div>
  )
}

export default DeliveryBoysSumm