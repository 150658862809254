import { Dialog } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { List } from 'antd';
import { setSelfOrderScreen } from './selfOrderEssentialsSlice';
import { onGoingOrdersForSelf, singleSelfOrderInfoAPICall } from '../../API/selfOrderAPIS';
import Swal from 'sweetalert2';
import { cancelOngoingOrderApiCall } from '../../../component/Modules/Sales/Billing/API/viewOrderSection';
import { selfDialogScreenSelected } from '../../Slices/selfOrderMainSlice';

const SelfOngoingOrders = ({ open, handleClose,newOrderClick }) => {
    const dispatch=useDispatch()

    const {ongoingOrders}=useSelector((state)=>state.selfOrderMainSlice)
    const { branchPk,data } = useSelector((state) => state.loginResponseSlice.value);
    const { branchConfigurationList } = useSelector((state) => state.newBillingSlice);

    const updateMultiOrder=(orderId)=>{
        localStorage.setItem("selfOrderId",orderId)
        let postFn=()=>{
            dispatch(setSelfOrderScreen("orderProductLists"))
            handleClose()
        }
        singleSelfOrderInfoAPICall({id:orderId},postFn)
    }

// cancel ongoing orders
const cancelOngoingOrder = (orderId) => {
    let payload = {
      orderId: orderId,
      branchId: branchPk,
      password: "" 
    };
  
    let postFn = () => {
      onGoingOrdersForSelf({ type: 1, waiterId: data?._id, branchId: branchPk });
    };
  
    const showPasswordPrompt = (withPassword) => {
      if (withPassword) {
        dispatch(selfDialogScreenSelected(null)); // Close dialog temporarily
      }
  
      Swal.fire({
        text: withPassword
          ? "Enter your password to cancel this order"
          : "Confirm to cancel this order",
        icon: 'warning',
        input: withPassword ? 'password' : null,
        inputPlaceholder: "Enter password",
        confirmButtonColor: '#f80000',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Back',
        customClass: {
          cancelButton: 'sweet-alert-cancel-button',
          confirmButton: 'sweet-alert-submit-button',
        },
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          const input = Swal.getInput();
          if (input) input.focus(); // Ensure input focus
        },
        preConfirm: (password) => {
          if (withPassword && !password) {
            Swal.showValidationMessage('Password is required!');
            return false; 
          }
          return password || ""; 
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (withPassword) {
            payload.password = result.value; 
          }
          dispatch(selfDialogScreenSelected("ordersDialog")); 
          cancelOngoingOrderApiCall(payload, postFn);
        } else if (withPassword) {
          dispatch(selfDialogScreenSelected("ordersDialog")); // Reopen dialog on cancel
        }
      });
    };
  
    const requiresPassword = branchConfigurationList?.cancelOrdAuth; 
    showPasswordPrompt(requiresPassword);
  };
  
  return (
      <Dialog 
      open={open} 
      onClose={handleClose}  
      fullWidth>
        <div className="justify-space-between" style={{padding:"0 10px",borderBottom:"2px dashed #e7e7e7"}}>
            <h3 style={{margin:"7px 0"}}>Orders</h3>
             <button onClick={newOrderClick} className="btn-primary btn" style={{minWidth:"100px"}}>New</button>
        </div>
          <List
              dataSource={ongoingOrders}
              renderItem={(item) => (
                  <List.Item
                      style={{
                          padding: "8px 20px",
                          borderBottom: "1px solid #f0f0f0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                      }}
                  >
                      {/* Order Details */}
                      <div style={{ flex: 1, marginRight: "30px" }}>
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                              {item.name || "BILL/#"}
                          </span>
                          <span style={{ fontSize: "14px", marginLeft: "8px", color: "#666" }}>
                              | {item?.ordType === "TAKEAWAY" ? "Takeaway" : `Table:${item.tableNames?.join(",") || "-NIL-"}`}
                          </span>
                          <br />
                          <span style={{ fontSize: "12px", color: "#666" }}>
                              Customer: {item.customerName || "Munshid Pn"}
                          </span>
                      </div>

                      {/* Action Icons */}
                      <div style={{ display: "flex", gap: "10px" }}>
                          <HighlightOffOutlinedIcon onClick={()=>cancelOngoingOrder(item?._id)} color='error'/>
                          <QueuePlayNextOutlinedIcon onClick={()=>updateMultiOrder(item?._id)} color='primary'/>
                      </div>
                  </List.Item>
              )}
          />
      </Dialog>
  )
}

export default SelfOngoingOrders