import React, { forwardRef, useEffect, useRef, useState } from 'react'
import QR from "../../../../../Assets/Images/Asset 1.png"
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import { salesQuotationSingleViewAPICall } from './salesQuatationAPI';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { filterObjFromList } from '../../../../../Js/generalFunctions';

export const Template1 = forwardRef ((props, ref) => {
  const { data, formValues, tax,domain, } = props;
  const [symbol, setSymbol] = useState(null);
  const [unit, setUnit] = useState(null);

  const decimalPosition = localStorage.getItem("decimalPosition");
  const numToWords = require("num-words");

  const currencyListUpdated = useSelector(
    (state) => state?.viewUpdatedCurrency?.value
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  function toCamelCase(str) {
    return str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    }).replace(/\s+/g, '');
  }


  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    // console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
    setUnit(currencyObj?.currencyUnit);
  }, [companyProfileData, currencyListUpdated]);

  // const singleRowId = localStorage.getItem("singleViewById")

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  },[]);

  return (
    <div className="print-main-div" style={{ minHeight: "100vh" }} ref={ref}>
      <div className="first-container">
        <div className="left-side-div">
          <img
            src={formValues?.companyLogo}
            alt=""
          />
        </div>
        <div className="right-side-div">
          <h1 style={{ fontSize: "medium" }}>
            {formValues?.companyName === null ? "" : formValues?.companyName}
          </h1>
          <p style={{ paddingLeft: "30px", paddingTop: "10px" }}>
            {formValues?.companyAddress === null || formValues?.companyAddress === "null" ? "" : formValues?.companyAddress}</p>
          <p style={{ fontWeight: "100" }}>{formValues?.companyContact === null ? "" : formValues?.companyContact}</p>
          {formValues?.companyTRN !== "" && (
            <p style={{ fontWeight: "100" }}>TRN: {formValues?.companyTRN}</p>
          )}
        </div>
      </div>

      <hr className='lined' style={{ border: "1px solid #b7b3b3" }} />
      <div className="global-print-heading">{formValues?.label}</div>
      <div className="Table-box">
        <div className="t-box">
          <p>{ "Invoice To"}&nbsp;: </p>
          <p>{formValues?.customerName}</p>
          <p>{formValues?.customerEmail}</p>
          <p>{formValues?.customerAddress}</p>
          <p>{formValues?.customerContact}</p>
          {formValues?.trn !== "" && (
            <p style={{ paddingLeft: "10px", margin: "0" }}>TRN: {formValues?.trn}</p>)}
        </div>
        {/* <div className="t-box">address</div> */}
        <div className="t-box" style={{ display: "flex", justifyContent: "space-between", paddingInline: "15px", width: "35%" }}>
          <div>
            <p style={{ margin: "0" }}>{formValues?.sequence}</p>
            {formValues?.firstDate !== "" && (
              <p style={{ margin: "0" }}>{formValues?.dateN1}</p>)}
            {formValues?.secondDate !== "" && (
              <p style={{ margin: "0" }}>{formValues?.dateN2}</p>)}
            {formValues?.lpo !== "" && (
              <p style={{ margin: "0" }}>LPO No</p>
            )}
            {formValues?.ref !== "" && (
              <p style={{ margin: "0" }}>{formValues?.refN || "Ref No"}</p>)}
            {formValues?.payTerm !== "" && (
              <p style={{ margin: "0" }}>Payment Terms</p>)}
            {formValues?.salesOrder !== "" && formValues?.label === "Delivery Note" && (
              <p style={{ margin: "0" }}>SO No</p>)}
          </div>
          <div>
            <p style={{ margin: "0" }}>: {formValues?.name} </p>
            {formValues?.firstDate !== "" && (
              <p style={{ margin: "0" }}>: {formValues?.firstDate}</p>)}
            {formValues?.secondDate !== "" && (
              <p style={{ margin: "0" }}>: {formValues?.secondDate}</p>)}
            {formValues?.lpo !== "" && (
              <p style={{ margin: "0" }}>: {formValues?.lpo}</p>
            )}
            {formValues?.ref !== "" && (
              <p style={{ margin: "0" }}>: {formValues?.ref}</p>)}
            {formValues?.payTerm !== "" && (
              <p style={{ margin: "0" }}>: {formValues?.payTerm}</p>)}
            {formValues?.salesOrder !== "" && formValues?.label === "Delivery Note" && (
              <p style={{ margin: "0" }}>: {formValues?.salesOrder}</p>)}

          </div>
        </div>
      </div>
      {formValues?.label !== "Delivery Note" && formValues?.label !== "GRN" && (
        <>
          <div>

            <table style={{ width: "100%", marginTop: "8px", borderCollapse: "collapse" }} className="table">
              <thead>
                <tr className="print-table-head">
                  <th style={{ width: "6%" }}>SI NO</th>
                  <th>PART NO:</th>
                  <th >DESCRIPTION</th>
                  <th style={{ width: "6%" }}>QTY</th>
                  <th style={{ width: "6%" }}>UOM</th>
                  <th style={{ width: "6%" }}>RATE</th>
                  <th style={{ width: "8%" }}>AMOUNT</th>
                  <th style={{ width: "7%" }}>DISC%</th>
                  <th style={{ width: "6%" }}>VAT% </th>
                  <th style={{ width: "6%" }}>VAT</th>
                  <th style={{ width: "13%" }}>NET AMOUNT</th>
                </tr>
              </thead>
              <tbody>

                {data?.map((item, index) => (
                  <>

                    {item.line_type === "header" ? <tr>
                      <td colSpan={10} style={{ backgroundColor: item.line_type === "lines" ? "white" : "rgb(220 221 225)" }}>{item?.label}</td>
                    </tr> :
                      <tr
                        key={index}
                        className="print-table-head" style={{ backgroundColor: item.line_type === "lines" ? "white" : "rgb(220 221 225)", textAlignLast: "center" }}
                      >
                        <td>{index + 1}</td>
                        <td>{item?.label}</td>
                        <td>{item.product}</td>
                        <td>{item.qty}</td>
                        <td>{item.uomName}</td>
                        <td>{item.unitPrice?.toFixed(decimalPosition)}</td>
                        <td>{(item.qty * item.unitPrice)?.toFixed(decimalPosition)}</td>
                        <td>{item.discount}%</td>
                        <td

                        >
                          {item.taxes?.map((r, i) => (
                            <p key={i} className="tax" style={{ margin: "1px", border: "none" }}>
                              {r?.amount}%
                            </p>
                          ))}
                        </td>
                        <td>{item.taxAmt?.toFixed(decimalPosition)}</td>
                        <td>
                          {item.includedPrice === false ?
                            ((item.lineTotal + item.taxAmt)?.toFixed(decimalPosition)) :
                            (item.lineTotal?.toFixed(decimalPosition))
                          }
                        </td>
                      </tr>
                    }
                  </>
                ))}
              </tbody>
            </table>



            <div style={{ backgroundColor: "#e9e9e9", display: "flex", textAlign: "end", paddingInline: "3px" }}>

{data && data?.length > 0 && (
  <p style={{ width: "68%", margin: "0" , fontSize: "smaller"}}>
    {symbol}{" "}
    {data?.reduce((total, item) => {
      const qty = typeof item.qty === 'number' ? item.qty : 0; // Check if item.qty is a number, otherwise default to 0
      const unitPrice = typeof item.unitPrice === 'number' ? item.unitPrice : 0; // Check if item.unitPrice is a number, otherwise default to 0
      return total + qty * unitPrice;
    }, 0)?.toFixed(decimalPosition)}
  </p>
)}


<p style={{ width: "16%", margin: "0", fontSize: "smaller"}}>{symbol} {(data?.reduce((acc, row) => acc + row?.taxAmt, 0.00))?.toFixed(decimalPosition)}</p>
<p style={{ width: "15%", padding: "3px", margin: "0", fontSize: "smaller"}}>
  {symbol} {data?.reduce((acc, row) => {
    if (row.includedPrice === false) {
      return acc + (row.lineTotal + row.taxAmt);
    } else {
      return acc + row.lineTotal;
    }
  }, 0.00)?.toFixed(decimalPosition)}
</p>

</div>
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: "8px" }}>
            <div style={{ width: "50%", backgroundColor: "#e9e9e9", paddingInline: "3px", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ margin: "0", fontWeight: "600" }}>Total Before Vat
                </p>
                <p style={{ margin: "0" }}>
                  {symbol} {formValues && formValues.subtotal && typeof formValues.subtotal === 'number'
                    ? formValues.subtotal.toFixed(decimalPosition)
                    : ''}
                </p>

              </div>
              {tax?.map((item, index) => (
                <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
                  <p style={{ margin: "0", fontSize: "small" }}>{item.taxName}</p>
                  <p style={{ margin: "0", fontSize: "small" }}>{symbol} {item.amount?.toFixed(decimalPosition)}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="global-print-total-field">
            <div>
              <p style={{ margin: "0", paddingInline: "10px", fontSize: "small" }}>Amount in Words: {formValues?.totalAmount >= 0 ? toCamelCase(numToWords(Math.floor(formValues?.totalAmount))) : "Negative " + toCamelCase(numToWords(Math.floor(-formValues?.totalAmount)))} {unit}</p>

            </div>
            <div style={{ display: "flex", width: "50%", backgroundColor: "#e9e9e9", justifyContent: "space-between", paddingInline: "5px" }}>
              <p style={{ fontWeight: "600" }}>Total Inc Vat({symbol})</p>
              <p style={{ fontSize: "small" }}>
                {symbol} {typeof formValues?.totalAmount === 'number'
                  ? formValues.totalAmount.toFixed(decimalPosition)
                  : ''}
              </p>

            </div>
          </div>
        </>
      )}
      {(formValues?.label === "Delivery Note" || formValues?.label === "GRN") && (
        <table style={{ width: "100%", marginTop: "8px", borderCollapse: "collapse" }}>
          <thead>
            <tr className="print-table-head">
              <th>SI NO</th>
              {/* <th>PRODUCT CODE</th> */}
              <th>PRODUCT</th>
              <th>UOM</th>
              <th>Ordered Qty</th>
              <th>Deliverd Qty</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <>
                {item.line_type === "header" ? (
                  <tr>
                    <td colSpan={10} style={{ backgroundColor: item.line_type === "lines" ? "white" : "rgb(220 221 225)", textAlignLast: "center" }}>{item?.label}</td>
                  </tr>
                ) : (
                  <tr key={index} className="print-table-head" style={{ backgroundColor: item.line_type === "lines" ? "white" : "rgb(220 221 225)" }}>
                    <td>{index + 1}</td>
                    <td>{item?.product}</td>
                    <td>{item.uomName}</td>
                    <td>{item.orderQuantity}</td>
                    <td>{item.deliveredQuantity || item.recievedQuantity}</td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
          {data && (
              <tfoot>
                <tr style={{ backgroundColor: "rgb(218 218 219)" }}>
                  <td colSpan={3}>Total</td>
                  <td>{data.reduce((total, item) => total + item.qty, 0)}</td>
                  <td>
                    {data.reduce(
                      (total, item) =>
                        total +
                        (formValues?.status === "RETURNED"
                          ? item?.returnQuantity
                          : item?.deliveredQuantity || 0),
                      0
                    )}
                  </td>
                </tr>
              </tfoot>
            )}
        </table>
      )}


      <div className="print-first-footer">
        <div style={{ width: "50%" }}>
          <QRCode
            size={256}
            style={{
              height: "auto",
              width: "70px",
            }}
            value={domain?domain:''}
            // value={"https://textile.eyeterp.com/userdashboard/sales/payments"}
            viewBox={`0 0 256 256`}
          />
          <p style={{ margin: "0", fontSize: "small" }}>Terms and Conditions</p>
          <p style={{ fontSize: "small", margin: "8px 0 0px 8px"}}>{formValues?.termsAndCondition}</p>

        </div>
        <div style={{ paddingInline: "6px" }}>
          <p style={{ paddingBottom: "30px", padding: " 39px 13px 38px 0px", fontSize: "small" }}>AUTHERISED SIGNATORY FOR</p>
          <hr className="line-sign"></hr>
        </div>
      </div>
      <div className="print-footer">
        <p>Page </p>
        <p>Email: example@example.com</p>
      </div>
    </div>
  )
})
export default Template1