import React from 'react'
import "../Input/selfOrderInputcss.css"
import SelfOrderInput from '../Input/SelfOrderInput'
import SelfLoginBtn from '../Buttons/SelfLoginBtn'
import { useDispatch, useSelector } from 'react-redux'
import { setSelfCustomer, setSelfMobileNo, setSelfOrderScreen } from '../Order/selfOrderEssentialsSlice'
import { selfSelectCustomerAPI } from '../../API/selfOrderAPIS'
import BackButton from '../Buttons/BackButton'
const SelfLoginPage = ({confirmOrder}) => {
  const dispatch = useDispatch()
  const {selfCustomer,selfMobileNo,customerTopbar}=useSelector((state)=>state.selfOrderEssentialsSlice)


  const inputChange=(e)=>{
    dispatch(setSelfCustomer(null))
    dispatch(setSelfMobileNo(e.target.value))
  }
  const fetchCustomer=()=>{
    let backState= customerTopbar
    selfSelectCustomerAPI({mobileNo:selfMobileNo},backState)
  }

  return (
    <div className='self-login-ctn'>
      <div className="self-login-input-ctn">
        <h3>CUSTOMER</h3>

        <div className="self-login-body">

          <SelfOrderInput
            value={selfCustomer?selfCustomer?.name:selfMobileNo}
            onChange={inputChange}
            placeholder="Enter mobile number" type='number'
          />
          {
           (!customerTopbar || !selfCustomer) && 
            <SelfLoginBtn
            label={selfCustomer?"CONFIRM ORDER":'CONTINUE'}
            onClick={selfCustomer?confirmOrder:fetchCustomer}
          />}
        </div>

        {
           !customerTopbar && !selfCustomer &&
          <p className="skip" onClick={confirmOrder}>SKIP</p>}
      </div>

    {customerTopbar && <BackButton onClick={()=>dispatch(setSelfOrderScreen("orderProductLists"))}/>}
    </div>
  )
}

export default SelfLoginPage