import React from "react";
import { useSelector } from "react-redux";

function ShiftReceipt({ showSubHeaders }) {
  const decimalPosition = localStorage.getItem("decimalPosition");
  const ShiftBillTypeSummary = useSelector(
    (state) => state.posReportshiftSlice.billTypeSummary
  );
  return (
    <div
      className="new-global-table-container"
      style={{ paddingTop: "0 42px" }}
    >
      <div className="bill-main-head">
        <h5>Receipt</h5>
        <h5>Amount</h5>
      </div>
      {showSubHeaders && (
        <div
          className="bill-sub-head"
          style={{
            backgroundColor: "white",
            borderBottom: "1px solid black",
          }}
        >
          <h5>Sales</h5>
          <h5>
            {(ShiftBillTypeSummary?.receipt?.sales||0)?.toFixed(decimalPosition)}
          </h5>
        </div>
      )}

      {showSubHeaders && (
        <div
          className="bill-sub-head"
          style={{
            backgroundColor: "white",
            borderBottom: "1px solid black",
          }}
        >
          <h5>Delivery Partner Receipt</h5>
          <h5>
            {(ShiftBillTypeSummary?.receipt?.deliveryPartner||0)?.toFixed(
              decimalPosition
            )}
          </h5>
        </div>
      )}

      {showSubHeaders && (
        <div
          className="bill-sub-head"
          style={{
            backgroundColor: "white",
            borderBottom: "1px solid black",
          }}
        >
          <h5>Packaging Charge</h5>
          <h5>
            {(ShiftBillTypeSummary?.receipt?.packagingCharge||0)?.toFixed(
              decimalPosition
            )}
          </h5>
        </div>
      )}

      {showSubHeaders && (
        <div
          className="bill-sub-head"
          style={{
            backgroundColor: "white",
            borderBottom: "1px solid black",
          }}
        >
          <h5>Delivery Charge</h5>
          <h5>
            {(ShiftBillTypeSummary?.receipt?.deliveryCharge||0)?.toFixed(
              decimalPosition
            )}
          </h5>
        </div>
      )}

      <div className="bill-main-head" style={{ backgroundColor: "white" }}>
        <h5>Receipt Total</h5>
        <h5>
          {" "}
          {(ShiftBillTypeSummary?.receipt?.total||0)?.toFixed(decimalPosition)}
        </h5>
      </div>
    </div>
  );
}

export default ShiftReceipt;
