import axios from "axios";
import jwtDecode from "jwt-decode";
import { currentTime, getCurrentTime, today } from "../../Js/Date";
import { update_login_response } from "../../Redux/Login/loginResponseSlice";
import { update_search_user_response } from "../../Redux/Login/searchUserSlice";
import { update_userRole } from "../../Redux/Login/userRoleSlice";
import store from "../../Redux/store";
import Store from "../../Redux/store";
import { getShiftStatusAPICAll } from "../Settings/Shift/viewShiftAPI";
import { HEADERS } from "../UrlAndPaths";
import { errorSnackMsg } from "../../component/Custom Hooks/SnackkBarUtilities";
import { setSubmitLoading } from "../../Redux/Dashboard/mainDashBoardSlice";

//Search User by userName
export const searchUserByUSerNameAPICall = async (body,userRoleClearFn) => {
  store.dispatch(setSubmitLoading(true))
  await axios
  .post("admin/searchuser", body, HEADERS)
  .then((res) => {
    if (res.status === 200) {
        store.dispatch(setSubmitLoading(false))
        Store.dispatch(
          update_search_user_response({ searchUserData: res.data })
        );
        localStorage.setItem("userRole", res.data?.role);
        store.dispatch(update_userRole({ userRole: res.data?.role }));
      }
    })
    .catch((err) => {
      store.dispatch(setSubmitLoading(false))
      userRoleClearFn?.()
      errorSnackMsg("User not found !!")
    });
};

//Final Login
export const finalLoginAPICall = async (
  body
) => {
  store.dispatch(setSubmitLoading(true))

  try {
    const res = await axios.post("admin/login", body, HEADERS)
    if (res.status === 200) {
      localStorage.setItem("userToken", res.data.token);
      localStorage.setItem("isAuth", true);
      localStorage.setItem("branchId", jwtDecode(res?.data?.token)?.branchId);
      localStorage.setItem("login_meta", JSON.stringify(res.data));
      localStorage.setItem("userInfo", JSON.stringify({ userName: res?.data?.userName, imageUrl: res?.data?.imageUrl, }));

      store.dispatch(update_login_response({ loginResponse: res.data }));

      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;


      let userRole = jwtDecode(res?.data?.token)?.role
      if (userRole === 'user') {
        getShiftStatusAPICAll({ branchId: jwtDecode(res?.data?.token)?.branchId, curTime: `${today} ${getCurrentTime()}` })
      }
    }
  } catch (err) {
    let message = null
    if (err === undefined) {
      message = "Network Error!!"
    }
    if (err.response.status === 500) {
      message = "Server error !!"
    }
    if (err.response.status === 401) {
      message = "Invalid password!!"
    }
    store.dispatch(update_login_response({ loginResponse: undefined }));
    errorSnackMsg(message)
  } finally {
    store.dispatch(setSubmitLoading(false))
  }
};
// export const finalLoginAPICall = async (
//   body,
//   setIsSuccess,
//   setError,
//   setErrorMsg
// ) => {
//   await axios
//     .post("admin/login", body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         let userRole=null
//         localStorage.setItem("userToken", res.data.token);
//         localStorage.setItem("isAuth", true);
//         localStorage.setItem("branchId", jwtDecode(res?.data?.token)?.branchId);
//         localStorage.setItem(
//           "userInfo",
//           JSON.stringify({
//             userName: res?.data?.userName,
//             imageUrl: res?.data?.imageUrl,
//           })
//         );
//         axios.defaults.headers.common[
//           "Authorization"
//         ] = `Bearer ${res.data.token}`;
//         setIsSuccess(true);
//         localStorage.setItem("login_meta", JSON.stringify(res.data));
//         store.dispatch(update_login_response({ loginResponse: res.data }));
//         userRole=jwtDecode(res?.data?.token)?.role
//         if(userRole!==null)
//         {
//           if(userRole==='user')
//           {
//             getShiftStatusAPICAll({branchId:jwtDecode(res?.data?.token)?.branchId,curTime:`${today} ${getCurrentTime()}`})
//           }
//         }

//       }
//     })
//     .catch((err) => {
//       if (err === undefined) {
//         setErrorMsg("Network Error!");
//       }
//       if (err.response.status === 500) {
//         setErrorMsg("Server error!");
//       }
//       if (err.response.status === 401) {
//         setErrorMsg("Invalid Password!!");
//       }
//       store.dispatch(update_login_response({ loginResponse: undefined }));
//       setError(true);
//       console.error(err.response);
//     });
// };

//Update Employee UserName and Password
export const updateEmpUsernamePasswordAPICall = async (
  body,
  setSuccess,
  setError,
  setSnackMsg,
  updateListener,
  clearStates
) => {
  await axios
    .put("employee/updateemployeecredentials", body, HEADERS)
    .then((res) => {
      if (res.status == 200) {
        console.log("Password changed successfully");
        setSnackMsg("Password changed 👍");
        setSuccess(true);
        updateListener();
        clearStates();
      }
    })
    .catch((err) => {
      switch (err.response.status) {
        case undefined:
          setSnackMsg("Network error!!");
          break;
        case 401:
          setSnackMsg("Unauthorized!!");
          break;
        case 403:
          setSnackMsg("Forbidden!!");
          break;
        case 409:
          setSnackMsg("Same password!!");
          break;
        case 500:
          setSnackMsg("Server error!!");
          break;

        default:
          break;
      }
      setError(true);
    });
};
