import React from 'react'
import SelfCartItem from './SelfCartItem'
import BackButton from '../Buttons/BackButton'
import MobileCartItem from './MobileCartItem '
import { useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setSelfCartArray, setSelfCartModified } from '../../Slices/selfOrderCartSlice'

const SelfCartList = ({backBtnClick}) => {

  const dispatch = useDispatch()
  const isMobile = useMediaQuery('(max-width:580px)');
  const decimalPosition=localStorage.getItem('decimalPosition');

  const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)
  const {cartArray,totalsInfo}=useSelector((state)=>state.selfOrderCartSlice)


  const handleIncrease = (item)=>() => {
    const existingItemIndex = cartArray.findIndex(
      (product) => product._id === item._id
    );

    if (existingItemIndex !== -1) {
      const updatedCartItems = cartArray.map((item, index) =>
        index === existingItemIndex
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      dispatch(setSelfCartArray(updatedCartItems))
      dispatch(setSelfCartModified(true))
    }
  };
  const handleDecrease = (item)=>() => {
    if (item?.quantity > 1) {
      const existingItemIndex = cartArray.findIndex(
        (product) => product._id === item._id
      );

      if (existingItemIndex !== -1) {
        const updatedCartItems = cartArray.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        dispatch(setSelfCartArray(updatedCartItems))
        dispatch(setSelfCartModified(true))
      }
    }
  };

  const handleDelete = (item)=>() => {
    const updatedCartItems = cartArray.filter((product) => product._id!== item._id);
    dispatch(setSelfCartArray(updatedCartItems))
    dispatch(setSelfCartModified(true))
  };

  const emptyCart=()=>{
    let filterExistingOrder=cartArray?.filter((item)=>item?.objType)
    dispatch(setSelfCartArray(filterExistingOrder || []))
    dispatch(setSelfCartModified(true))
  }
  return (
    <div className='self-cart-container'>
        <div className="self-cart-list-top justify-space-between">
          <BackButton className="" onClick={backBtnClick} />
         {
         isMobile &&
         <div className="self-sub-total">
            {currencyDetails?.symbol || ""} {(totalsInfo?.amtTotal || 0)?.toFixed(decimalPosition)}
          </div>}
          <button onClick={emptyCart} className='btn-red-outlined'>CLEAR ALL</button>
        </div>

        <div className="self-cart-list-middle">
          {
            cartArray.map((cartItem,index)=>(
              
                isMobile?
                  <MobileCartItem item={cartItem} key={index} handleDecrease={handleDecrease} handleIncrease={handleIncrease} handleDelete={handleDelete}/>
                  :
                  <SelfCartItem item={cartItem} key={index} handleDecrease={handleDecrease} handleIncrease={handleIncrease} handleDelete={handleDelete}/>
            ))
            }

        </div>
    
    </div>
  )
}

export default SelfCartList