import React from 'react'
import "./selectCard.css"
const SelectCard = ({image,label,onClick,labelStyle,imgStyle}) => {
  return (
    <div className='self-order-card' onClick={onClick}>
        <img src={image} alt="" style={imgStyle}/>
        <h4 style={labelStyle}>{label}</h4>
    </div>
  )
}

export default SelectCard