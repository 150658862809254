import { CircularProgress, Modal } from "@mui/material";
import React from "react";
import { Loader } from "./Loader";

export const LoadingForm=(props)=>{

    const {loading}=props;
    return(
        <>
            <Modal
                open={loading}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* <Loader/> */}

                <div class="dot-spinner">
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                    <div class="dot-spinner__dot"></div>
                </div>
                {/* <CircularProgress size={100} color="primary" sx={{outline:"none"}}/> */}
            </Modal>
        </>
    )
}