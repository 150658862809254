import axios from "axios";
import store from "../../../../Redux/store";
import { get_barcode_product_list_add_billing, get_branch_configuration_list, get_payment_types_list_for_billing, get_products_list_for_billing, get_sub_category_list_for_billing, setBarcodeProduct, setCartProductType } from "./newBillingSlice";
import { HEADERS, HEADER_FORM_DATA } from "../../../../API/UrlAndPaths";
import { customerListApi } from "../../../../API/Customer/customerAPI";
import { setCustomer } from "./Components/topBarSliceBilling";
import { setDialogBox, setErrorSnack, setPagination, setPosCategoryLoading, setSnackMsg, setSuccessSnack } from "./Slice/billingScreenSlice";
import { setSubmitLoading, setTableLoadingGlobal } from "../../../../Redux/Dashboard/mainDashBoardSlice";


// api for listing products

export const getAllProductsListForBilling=async(body,setIsLoading)=>{
  const isPagination = store.getState().billingScreenSlice.pagination;
  
  !isPagination ? setIsLoading(true) : store.dispatch(setTableLoadingGlobal(true)) 
    await axios.post("readymade/viewproductsforpos",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
              const existingProductsList = store.getState().newBillingSlice.productsList;
              const updatedProductsList = existingProductsList.concat(res.data);

              // Remove duplicates from the updatedProductsList
              const uniqueProductsList = updatedProductsList.filter((product, index, self) => (
                index === self.findIndex((p) => (
                  p._id === product._id 
                ))
              ));

                store.dispatch(get_products_list_for_billing({billingProductsList:isPagination===true?uniqueProductsList:res.data}))
                setIsLoading!==undefined && setIsLoading(false)
                store.dispatch(setCartProductType(1))
                store.dispatch(setPagination(false))
                store.dispatch(setTableLoadingGlobal(false)) 
            }
        })
        .catch((err)=>{
            isPagination===false && store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
            setIsLoading!==undefined && setIsLoading(false)
            store.dispatch(setPagination(isPagination && 'max'))
            store.dispatch(setTableLoadingGlobal(false)) 
        })
}
export const specialOfferProductsListForBilling=async(body,setIsLoading)=>{
    setIsLoading!==undefined && setIsLoading(true)
    await axios.post("pos/viewSpecialOfferById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                setIsLoading!==undefined && setIsLoading(false)
                store.dispatch(setCartProductType(2))
            }
        })
        .catch((err)=>{
            
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
            setIsLoading!==undefined && setIsLoading(false)
        })
}
export const comboOffersProductsListForBilling=async(body,setIsLoading)=>{
    setIsLoading!==undefined && setIsLoading(true)
    await axios.post("pos/viewSpecialOfferCombos",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                setIsLoading!==undefined && setIsLoading(false)
                store.dispatch(setCartProductType(3))
            }
        })
        .catch((err)=>{
            
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
            setIsLoading!==undefined && setIsLoading(false)
        })
}
// api for listing subcategories

export const getAllSubCatForBilling=async(body)=>{

    store.dispatch(setPosCategoryLoading(true))
    await axios.post("readymade/categoryfilter",body,HEADER_FORM_DATA)
    .then((res)=>{
      if(res.status===200){
              store.dispatch(setPosCategoryLoading(false))
              store.dispatch(get_sub_category_list_for_billing({billingSubCategories:res.data}))
            }
          })
          .catch((err)=>{
            store.dispatch(setPosCategoryLoading(false))
            store.dispatch(get_sub_category_list_for_billing({billingSubCategories:undefined}))
            
        })
}

// api to search products from billing
export const searhProductsBilling=async(body,barCodeFn)=>{
    // setIsLoading!==undefined && setIsLoading(true)
    await axios.post("readymade/productSearchByNBA",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                // setIsLoading!==undefined && setIsLoading(false)
                barCodeFn!==undefined && barCodeFn()
            }
        })
        .catch((err)=>{
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
            // setIsLoading!==undefined && setIsLoading(false)
            barCodeFn!==undefined && barCodeFn()

        })
}
// api to barcode cart add products from billing
export const barCodeAddProductsBilling=async(body,barCodeFn)=>{
    // setIsLoading!==undefined && setIsLoading(true)
    await axios.post("readymade/productSearchByNBA",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_barcode_product_list_add_billing({barCodeItems:res.data}))
                // setIsLoading!==undefined && setIsLoading(false)
                barCodeFn!==undefined && barCodeFn()
            }
        })
        .catch((err)=>{
            store.dispatch(get_barcode_product_list_add_billing({barCodeItems:undefined}))
            // setIsLoading!==undefined && setIsLoading(false)
            barCodeFn!==undefined && barCodeFn()

        })
}

// api to add customer

export const addNewCustomerPopupAPICall = async (
    body,
    updateListener,setState,state,setLoadingForm
  ) => {
    setLoadingForm!==undefined && setLoadingForm(false)

    await axios
      .post("account/createVendor", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          setLoadingForm!==undefined && setLoadingForm(false)
          updateListener();
          customerListApi()
          store.dispatch(setCustomer(res.data))
          setState({...state,message:"New Customer Added",success:true})
        }
      })
      .catch((err) => {
        setLoadingForm!==undefined && setLoadingForm(false)

        let message="oops!!"
        if (err.response.status === undefined) {
            message="Network error!!"
          }
          if (err.response.status === 401) {
             message="Unauthorized!!"
          }
          if (err.response.status === 403) {
             message="Forbidden!!"
          }
          if (err.response.status === 409) {
             message="Customer already registered!!"
          }
          if (err.response.status === 500) {
             message="Server error!!"
          }
        setState({...state,message:message,error:true})
      });
  };
  export const addCustomerFromReservation = async (
    body,updateListener
  ) => {
    store.dispatch(setSubmitLoading(true))

    await axios
      .post("account/createVendor", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          store.dispatch(setSubmitLoading(false))
          updateListener();
          customerListApi()
          store.dispatch(setCustomer(res.data))
          store.dispatch(setDialogBox(null))
          store.dispatch(setSnackMsg("Customer Added"))
          store.dispatch(setSuccessSnack(true))
        }
      })
      .catch((err) => {
        store.dispatch(setSubmitLoading(false))
        let message="oops!!"
        if (err.response.status === undefined) {
            message="Network error!!"
          }
          if (err.response.status === 401) {
             message="Unauthorized!!"
          }
          if (err.response.status === 403) {
             message="Forbidden!!"
          }
          if (err.response.status === 409) {
             message="Customer already registered!!"
          }
          if (err.response.status === 500) {
             message="Server error!!"
          }
          store.dispatch(setSnackMsg(message))
          store.dispatch(setErrorSnack(true))
      });
  };
  export const editCustomerFromReservation = async (
    body,updateListener
  ) => {
    store.dispatch(setSubmitLoading(true))

    await axios
      .put("account/editVendor", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          store.dispatch(setSubmitLoading(false))
          updateListener();
          customerListApi()
          store.dispatch(setCustomer(res.data))
          store.dispatch(setDialogBox(null))
          store.dispatch(setSnackMsg("Customer Edited"))
          store.dispatch(setSuccessSnack(true))
        }
      })
      .catch((err) => {
        store.dispatch(setSubmitLoading(false))
        let message="oops!!"
        if (err.response.status === undefined) {
            message="Network error!!"
          }
          if (err.response.status === 401) {
             message="Unauthorized!!"
          }
          if (err.response.status === 403) {
             message="Forbidden!!"
          }
          if (err.response.status === 409) {
             message="Customer already registered!!"
          }
          if (err.response.status === 500) {
             message="Server error!!"
          }
          store.dispatch(setSnackMsg(message))
          store.dispatch(setErrorSnack(true))
      });
  };
  
//   api to get payment types of the branch
export const paymentTypesForBilling=async(body)=>{
    await axios.post("settings/fetchpaymentsforposbybranch",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_payment_types_list_for_billing({billingPaymentTypes:res.data}))
            }
        })
        .catch((err)=>{
            store.dispatch(get_payment_types_list_for_billing({billingPaymentTypes:undefined}))
        })
}

// barcode scan api call
export const getProductFromBarCodeScan=async(body,setInput,addToCart)=>{
  store.dispatch(setSubmitLoading(true))
  setInput("")
  await axios.post("readymade/viewSingleProductByBarcode",body,HEADERS)
      .then((res)=>{
          if(res.status===200){
            addToCart(res.data)
            store.dispatch(setSubmitLoading(false))
          }
      })
      .catch((err)=>{
        store.dispatch(setSnackMsg("Product not found !!"))
        store.dispatch(setErrorSnack(true))
          store.dispatch(setSubmitLoading(false))
          store.dispatch(setBarcodeProduct(null))
      })
}

//send message to whatsapp 

export const sendMessageToWhatsApp = async (body) => {
  try {
    const response = await axios.post("/sendToWs", body , HEADER_FORM_DATA);
    if (response.status === 200) {
      store.dispatch(setSubmitLoading(false))
      return Promise.resolve(response.data);
    } else {
      store.dispatch(setSubmitLoading(false))
      return Promise.reject("Failed to send message"); 
    }
  } catch (error) {
    store.dispatch(setSubmitLoading(false))
    return Promise.reject(error); 
  }
}

// Branchwise configuration

export const getBranchConfiguration=async(body)=>{
  await axios.post("settings/posBranchWiseConfig",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_branch_configuration_list(res.data))
            }
        })
        .catch((err)=>{
            store.dispatch(get_branch_configuration_list(null))
        })
}

