import { createSlice } from "@reduxjs/toolkit";

export const selfOrderMainSlice=createSlice({
    name:"selfOrderMainSlice",
    initialState:{
        screenSelected:null,
        loginType:null,
        selfDialogScreen:null,
        selfSingleOrderInfo:null,
        completedOrders:[],
        ongoingOrders:[]
    },
    reducers:{
      setScreenSelected:(state,action)=>{
        state.screenSelected=action.payload;
      },
      setScreenLoginType:(state,action)=>{
        state.loginType=action.payload;
      },
      selfDialogScreenSelected:(state,action)=>{
        state.selfDialogScreen=action.payload;
      },
      setSelfSingleOrderInfo:(state,action)=>{
        state.selfSingleOrderInfo=action.payload;
      },
      setSelfCompletedOrdersList:(state,action)=>{
        state.completedOrders=action.payload;
      },
      setOngoingOrdersForSelf:(state,action)=>{
        state.ongoingOrders=action.payload;
      }
    }
})

export const {
    setScreenSelected,
    setScreenLoginType,setSelfCompletedOrdersList,
    selfDialogScreenSelected,setSelfSingleOrderInfo,setOngoingOrdersForSelf
}=selfOrderMainSlice.actions

export default selfOrderMainSlice.reducer