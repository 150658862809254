import React, { useEffect } from 'react'
import SelfOrderFloorPlan from './SelfOrderFloorPlan'
import "../../CSS/selfOrderSection.css"
import { useDispatch, useSelector } from 'react-redux'
import SelfOrderTopBar from '../Navbar/SelfOrderTopBar'
import SelfProductLists from './SelfProductLists'
import SelfOrderCart from '../Cart/SelfOrderCart'
import { setSelfCartArray, setSelfCartModified ,setSelfTotalsInfo} from '../../Slices/selfOrderCartSlice'
import cookingIcon from '../../../Assets/SelfOrder/cooking.png';
import { setSelfCustomer, setSelfOrderScreen, setSelfOrderType, setSelfTableNames } from './selfOrderEssentialsSlice'
import OrderConfirmScreen from './OrderConfirmScreen'
import { selfAddOrderAPI, selfUpdateOrderAPI } from './selfOrderListingAPI'
import { getCurrentTime, today } from '../../../Js/Date'
import SelfLoginPage from '../LoginSelf/SelfLoginPage'
import SelfRegisterPage from '../LoginSelf/SelfRegisterPage'
import SelfFeedback from '../Payment/SelfFeedback'
import RegisterCustomerDialog from '../Dialog/RegisterCustomerDialog'
import { findObjFromList } from '../../../Js/generalFunctions'
import { getInvoiceReceipt } from '../../../component/Modules/Sales/Billing/API/billingPaymentSection'
const SelfOrderSection = ({newOrderClick}) => {
  const dispatch=useDispatch()
  const decimalPosition=localStorage.getItem('decimalPosition')
  const defaultFloorId = localStorage.getItem("defaultFloorId");
  const selfWOParse=localStorage.getItem("selfOrderId");
  const selfOrderId =  selfWOParse === "null" || selfWOParse === null ? null : selfWOParse;
  const customerList = useSelector((state) => state.allCustomerListSlice.value);
  
  const {ordType,orderScreen}=useSelector((state)=>state.selfOrderEssentialsSlice)
  const {cartArray,cartModified,totalsInfo}=useSelector((state)=>state.selfOrderCartSlice)
  const {selfSingleOrderInfo}=useSelector((state)=>state.selfOrderMainSlice)
  const {selfTableIds,selfChairIds,selfCustomer}=useSelector((state)=>state.selfOrderEssentialsSlice)
  const {defaultCustomer} = useSelector((state) => state.topBarSliceBilling);
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const userBranchId = userInfo?.branchPk;

  const calculateTotals = (items) => {
    const subTotal = items?.reduce((sum, item) => sum + item?.subTotalWoTax, 0);
    const amtTotal = items?.reduce((sum, item) => sum + item?.lineTotal, 0);
    const totalExclusive = items?.reduce(
      (sum, item) => sum + item?.exclusiveTax,
      0
    );
    const totalInclusive = items?.reduce(
      (sum, item) => sum + item?.inclusiveTax,
      0
    );
    return { subTotal, amtTotal, totalExclusive, totalInclusive };
  };
  const calculateAndSetCalculatedValues = (cartItems) => {
    const calculatedValues = cartItems?.map((row) => {
      let totalAmt = Number(row?.quantity) * Number(row?.salesPrice);
      let subtotalBeforeTax = totalAmt?.toFixed(decimalPosition);
      let totalTax = row?.taxes?.reduce((sum, item) => sum + item?.amount, 0);
      let totalExclTax = (Number(subtotalBeforeTax) * totalTax) / 100;

      if (row?.includedPrice === "true" || row?.includedPrice === true) {

        let taxesAmount=row?.taxes?.map((obj)=>obj?.amount)
        let totalInclArr=taxesAmount?.map((item,index)=>{
          let total=(Number(subtotalBeforeTax) * item) / (100+item)
          return total
        },0)

        let totalIncl=totalInclArr?.reduce((sum,item)=>sum+item,0)
        return {
          ...row,
          inclusiveTax: totalIncl,
          exclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt,
        };
      } else if (
        row?.includedPrice === "false" ||
        row?.includedPrice === false
      ) {
        return {
          ...row,
          exclusiveTax: totalExclTax,
          inclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt + totalExclTax,
        };
      } else {
        return {
          ...row,
          exclusiveTax: 0,
          inclusiveTax: 0,
          subTotalWoTax: totalAmt,
          lineTotal: totalAmt,
        };
      }
    });
    dispatch(setSelfCartArray(calculatedValues))
    dispatch(setSelfCartModified(false))
    dispatch(setSelfTotalsInfo(calculateTotals(calculatedValues)))
  };

 // Utility function to parse included price
const parseIncludedPrice = (value) => {
  if (value === "true" || value === true) return true;
  if (value === "false" || value === false) return false;
  return null;
};

// Helper function to map cart items
const mapCartItem = (item, isUpdate = false) => ({
  productId: item?._id,
  desccription: item?.productName,
  customerNote: item?.customerNote || null,
  qty: Number(item?.quantity),
  uom: item?.uom,
  unitPrice: Number(item?.salesPrice),
  subTotalWoTax: item?.subTotalWoTax,
  lineTotal: Number(item?.lineTotal),
  taxes: item?.taxes,
  itemType: item?.itemType || 1,
  includedPrice: parseIncludedPrice(item?.includedPrice),
  ...(isUpdate && {
    kotOrderId: item?.kotOrderId || null,
    kotStatus: item?.kotStatus || "DRAFT",
    objType:
      item?.quantity > item?.lastQty
        ? "add"
        : item?.quantity < item?.lastQty
        ? "min"
        : "eq",
    _id: item?.existingId,
  }),
});
  const confirmOrderClick=()=>{

    const{subTotal,amtTotal,totalExclusive,totalInclusive}=totalsInfo;
    const orderInfo = cartArray?.map((item) => mapCartItem(item));
    const updatedOrderInfo = cartArray?.map((item) => mapCartItem(item, true));
    
    let payload={
        aggregatorId: null,
        aggregatorRef: null,
        amtTotal,
        branchId:userBranchId,
        cusId:selfCustomer?._id || defaultCustomer?._id,
        discountAmt:0,
        orderInfo:selfOrderId?updatedOrderInfo:orderInfo,
        subTotal,
        totalExclusive,
        totalInclusive,
        waiterId:ordType==="DINEIN"?(userInfo?.data?._id ||null) :null,
    }
    
    if(ordType==="DINEIN"){
      payload.orderDate=`${today} ${getCurrentTime()}`
      payload.floorId=defaultFloorId
      payload.tableId=selfTableIds
      payload.chairId=selfChairIds
    }else{
      payload.ordType=ordType
      payload.isSelfOrder=true
      payload.orderDate=`${today} ${getCurrentTime()}`
    }
    if(selfOrderId){
      payload.ordType=selfSingleOrderInfo?.ordType
      payload.orderId=selfOrderId
      payload.password=""
    }

    selfOrderId?selfUpdateOrderAPI(payload) : selfAddOrderAPI(payload)
}

// fetch existing order info
  useEffect(() => {
    if (selfSingleOrderInfo !== null) {
      const orderInfo = selfSingleOrderInfo?.orderInfo?.map((item) => ({
        customerNote: item?.customerNote || null,
        productName: item?.desccription || "",
        includedPrice:
          item?.includedPrice === true
            ? true
            : item?.includedPrice === false
            ? false
            : null,
        kotOrderId: item?.kotOrderId,
        kotStatus: item?.kotStatus,
        lineTotal: item?.lineTotal || 0,
        _id: item?.productId,
        quantity: item?.qty,
        subTotalWoTax: item?.subTotalWoTax,
        taxes: item.taxes,
        salesPrice: item?.unitPrice,
        uom: item?.uom,
        existingId: item?._id,
        objType: "eq",
        lastQty: item?.qty,
        imageUrl: cookingIcon,
        itemType: item?.itemType,
      }));
      let cusObj=findObjFromList("_id",customerList,"cusId",selfSingleOrderInfo)

      dispatch(setSelfCartArray(orderInfo))
      dispatch(setSelfCartModified(true))
      dispatch(setSelfCustomer(cusObj))
      dispatch(setSelfOrderType(selfSingleOrderInfo?.ordType))
      dispatch(setSelfTableNames(selfSingleOrderInfo?.tableNames ||[]))

      if(selfSingleOrderInfo?.isSelfOrdCompleted){
        getInvoiceReceipt({orderId:selfSingleOrderInfo?._id})
        dispatch(setSelfOrderScreen("orderFeedbackScreen"))
      }

    } else {
      dispatch(setSelfCartArray([]))
      dispatch(setSelfCartModified(true))
    }
  }, [selfSingleOrderInfo,customerList]);

  // effect to triggerthe calculation
  useEffect(()=>{
    cartModified && calculateAndSetCalculatedValues(cartArray)
  },[cartModified])

  const topBarEnable=(orderScreen==="orderFloorPlan");

  return (
    <>
        { !topBarEnable &&<SelfOrderTopBar/>}
        {
          
          (ordType==="DINEIN" && orderScreen==="orderFloorPlan")?
          // TABLE SECTION
          <SelfOrderFloorPlan/>:
          (orderScreen==="orderProductLists" )?
          // Products listing page
          <SelfProductLists calculateAndSetCalculatedValues={calculateAndSetCalculatedValues}/>:
          orderScreen==="cartSection"?
          // Cart section
          <SelfOrderCart/>:
          (orderScreen==="loginScreen")?
          // customer selection page
          <SelfLoginPage confirmOrder={confirmOrderClick}/>:
          orderScreen==="registerScreen"?
          // register customer page
          <SelfRegisterPage />
          : 
          orderScreen==="orderConfirmScreen"?
          // Order summary
          <OrderConfirmScreen/>:
          orderScreen==="orderFeedbackScreen"?
          // final page
          <SelfFeedback newOrderClick={newOrderClick}/>:
          null
        }        
        {/* popup if customer does'nt exist */}
        <RegisterCustomerDialog/>
    </>
  )
}

export default SelfOrderSection