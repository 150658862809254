import React, { useEffect, useRef, useState } from "react";
import "./InvoiceMarginReport.css";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import { ProductMarginReportSingleViewAPIcall } from "./GeneralReportAPI";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import { convertDateFormat } from "../../../../../Js/Date";
import { useLocation, useNavigate } from "react-router-dom";
import { viewAllCurrencyUpdated } from "../../../../../API/Settings/Currency/CurrencyAPI";
import { companyProfileInfoForUser } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";
import ReactToPrint from "react-to-print";
import PrintComponent from "../../../../print/report/PrintComponent";

function ProductMarginReportSingleView() {
  const ExcelJS = require("exceljs");
  let paymentsPrintNew = useRef();

  let navigate = useNavigate();
  let location = useLocation();

  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

  const productMarginSingleList = useSelector(
    (state) => state?.generalReport?.ProductMarginReportSingleList
  );
  console.log(productMarginSingleList, "jjj");
  const decimalPosition = localStorage.getItem("decimalPosition");
  const singleViewId = localStorage.getItem("productSingleViewId");
  const [filteredData, setFilteredData] = useState({});

  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [symbol, setSymbol] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.fromDate && filters?.toDate) {
      parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
    }
    console.log(filters?.fromDate);

    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
    }
    if (filters?.customer) {
      parts.push(`customer: ${filters?.customer}`);
    }

    return parts; // Return the array directly
  };

  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Invoice Margin Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Invoice Margin Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:I1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:I${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "Date",
      "Invoice",
      "Employee",
      "Customer",
      "Qty",
      "Cost",
      "Revenue",
      "Profit",
      "Profit Margin %",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "Date", key: "date", width: 50 },
      { header: "Invoice", key: "invoice", width: 20 },
      { header: "Employee", key: "employee", width: 20 },
      { header: "Customer", key: "vendorName", width: 20 },
      { header: "Qty", key: "qty", width: 20 },
      { header: "Cost ", key: "cost", width: 20 },
      { header: "Revenue ", key: "revenue", width: 20 },
      { header: "Profit ", key: "profit", width: 20 },
      { header: "Product Margin Report", key: "profitMargin", width: 20 },
    ];

    productMarginSingleList?.report?.forEach((item) => {
      const row = sheet.addRow({
        date: item.date,
        invoice: item.invoice,
        employee: item.employee,
        vendorName: item?.vendorName,
        qty: item?.qty,
        cost: ` ${item?.cost?.toFixed(2)}`,
        revenue: ` ${item?.revenue?.toFixed(2)}`,
        profit: ` ${item?.profit?.toFixed(2)}`,
        profitMargin: ` ${item?.profitMargin?.toFixed(2)}`,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFededed" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          cell.alignment = { horizontal: "left" };
        }
        if (colNumber === 4) {
          // Assuming the balance column is the 4th column
          const value = parseFloat(item?.balance);
          if (value < 0) {
            cell.font = { color: { argb: "FFededed" } }; // Red color for negative values
          }
        }
      });
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      "",
      "",
      "",
      `${symbol} ${
        productMarginSingleList?.total !== undefined &&
        productMarginSingleList?.total?.qty?.toFixed(2)
      }`,
      `${symbol} ${
        productMarginSingleList?.total !== undefined &&
        productMarginSingleList?.total?.cost?.toFixed(2)
      }`,
      `${symbol} ${
        productMarginSingleList?.total !== undefined &&
        productMarginSingleList?.total?.revenue?.toFixed(2)
      }`,
      `${symbol} ${
        productMarginSingleList?.total !== undefined &&
        productMarginSingleList?.total?.profit?.toFixed(2)
      }`,
      `${symbol} ${
        productMarginSingleList?.total !== undefined &&
        productMarginSingleList?.total?.profitMargin?.toFixed(2)
      }`,
    ]);

    totalRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Invoice Margin Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const handleClickRow = (row) => {
    if (row.type === 1) {
      localStorage.setItem("singleViewById", row?._id);
      store.dispatch(setFilterActive(window.location.pathname));
      navigate(
        "/userdashboard/accounts/customers/customerInvoices/singleView",
        {
          state: {
            isProduct: true,
            name: productMarginSingleList?.product,
            seq: row?.invoice,
          },
        }
      );
    } else {
      localStorage.setItem("singleViewById", row._id);
      // store.dispatch(get_sales_w_so_row_data_list({salesWSOsingleData:row}))
      //navigate("/userdashboard/accounts/customers/saleswso/create")
      store.dispatch(setFilterActive(window.location.pathname));
      navigate("/userdashboard/accounts/customers/saleswso/singleView", {
        state: {
          isProduct: true,
          name: productMarginSingleList?.product,
          seq: row?.invoice,
        },
      });
    }
  };
  useEffect(() => {
    if (FormDataInfo.length !== 0) {
      ProductMarginReportSingleViewAPIcall({
        _id: singleViewId,
        search: FormDataInfo?.search,
        employee:
          FormDataInfo?.createdBy.length === 0 ? "" : FormDataInfo?.createdBy,
        vendor:
          FormDataInfo?.customer?.length === 0 ? "" : FormDataInfo?.customer,
        branchId: FormDataInfo?.branchList,
      });
    }
  }, [FormDataInfo, singleViewId]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  // const handlePrint = useReactToPrint({
  //   content: () => paymentsPrintNew.current,
  // });

  return (
    <div className="in-or-out-container" style={{ margin: "0" }}>
      <div
        className="in-or-out-report-wrapper"
        style={{ backgroundColor: "unset", padding: "5px" }}
      >
        <div className="sub-nav-head" style={{ backgroundColor: "white" }}>
          {/* <div>
                        <div className="path-name-wrapper" style={{ backgroundColor: '#c3d4f7', maxWidth: "17em" }}>
                            <h3 style={{ color: '#06066f' }} className="path-name">Product Margin Report</h3>
                        </div>
                    </div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.8em",
            }}
          >
            <div
              style={{
                background: "unset",
                borderRadius: "0",
                maxWidth: "20em",
                cursor: "pointer",
              }}
              className="path-name-wrapper"
              onClick={() => {
                navigate(
                  "/userdashboard/accounts/report/generalReport/productMarginReport"
                );
                localStorage.removeItem("singleViewById");
              }}
            >
              <h3
                className="path-name"
                style={{ color: "black", paddingRight: "0" }}
              >
                Product Margin Report&gt;
              </h3>
            </div>
            <div
              className="path-name-wrapper"
              style={{
                backgroundColor: "unset",
                maxWidth: "17em",
                transition: "max-width 1s ease-in-out",
              }}
            >
              <h3 className="path-name" style={{ color: "black" }}>
                {productMarginSingleList?.product}
              </h3>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "69%",
            }}
          >
            <Tooltip title="Share">
              <IconButton>
                <i
                  class="bi bi-share"
                  style={{
                    backgroundColor: "#9797e9",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>

            <ReactToPrint
              trigger={() => (
                <IconButton className="printer-div" style={{ padding: "1px" }}>
                  <i
                    style={{
                      color: "white",
                      backgroundColor: " #d787e7",
                      fontSize: "medium",
                      padding: "8px",
                      borderRadius: "2px",
                    }}
                    class="bi bi-printer"
                  ></i>
                </IconButton>
              )}
              content={() => paymentsPrintNew}
            />

            <Tooltip title="Download">
              <IconButton onClick={downloadList}>
                <i
                  class="bi bi-arrow-down-circle"
                  style={{
                    backgroundColor: "rgb(255 118 133 / 69%)",
                    color: "white",
                    fontSize: "medium",
                    padding: "8px",
                    borderRadius: "2px",
                  }}
                ></i>
              </IconButton>
            </Tooltip>
            <CategorySearchandFilter
              onData={handleDataFromChild}
              isCustomer={true}
              isCreatedBy={true}
              onBranchSelect={() => {}}
            />
          </div>
        </div>
        <div
          className="invoice-table-container"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "0",
            height: "84vh",
          }}
        >
          <div className="invoice-main-div">
            <table style={{ paddingTop: "5px" }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice</th>
                  <th>Employee</th>
                  <th>Customer</th>
                  <th>Qty</th>
                  <th>Cost</th>

                  <th>Revenue</th>
                  <th>Profit </th>
                  <th>Profit Margin %</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : productMarginSingleList?.report !== undefined &&
                  productMarginSingleList?.report?.length !== 0 ? (
                  productMarginSingleList?.report?.slice(0)?.map((r, i) => (
                    <tr key={i} onClick={() => handleClickRow(r)}>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.invoice}</td>
                      <td>{r?.employee}</td>
                      <td>{r?.vendorName}</td>
                      <td>{r?.qty?.toFixed(decimalPosition)}</td>
                      <td>
                        {symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}
                      </td>

                      <td>
                        {symbol}&nbsp;{r?.revenue?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}
                      </td>
                      <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
              <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>
                <td colSpan={4} style={{ fontWeight: "bold" }}>
                  Total
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.qty?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {symbol}&nbsp;
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.cost?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {symbol}&nbsp;
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.revenue?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {symbol}&nbsp;
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.profit?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.profitMargin?.toFixed(
                      decimalPosition
                    )}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={(el) => (paymentsPrintNew = el)}>
          <PrintComponent
            header={"Product Margin Report  "}
             subHead={productMarginSingleList?.product}
            pageHeight={80}
          >
            <table style={{ paddingTop: "5px" }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Invoice</th>
                  <th>Employee</th>
                  <th>Customer</th>
                  <th>Qty</th>
                  <th>Cost</th>

                  <th>Revenue</th>
                  <th>Profit </th>
                  <th>Profit Margin %</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  [...Array(10)].map((r, i) => (
                    <tr key={i}>
                      <td colSpan={8}>
                        <Skeleton
                          variant="rectangular"
                          animation="wave"
                          width={"100%"}
                          height={40}
                        />
                      </td>
                    </tr>
                  ))
                ) : productMarginSingleList?.report !== undefined &&
                  productMarginSingleList?.report?.length !== 0 ? (
                  productMarginSingleList?.report?.slice(0)?.map((r, i) => (
                    <tr key={i} onClick={() => handleClickRow(r)}>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td>{r?.invoice}</td>
                      <td>{r?.employee}</td>
                      <td>{r?.vendorName}</td>
                      <td>{r?.qty?.toFixed(decimalPosition)}</td>
                      <td>
                        {symbol}&nbsp;{r?.cost?.toFixed(decimalPosition)}
                      </td>

                      <td>
                        {symbol}&nbsp;{r?.revenue?.toFixed(decimalPosition)}
                      </td>
                      <td>
                        {symbol}&nbsp;{r?.profit?.toFixed(decimalPosition)}
                      </td>
                      <td>{r?.profitMargin?.toFixed(decimalPosition)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={8}>
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
              <tr style={{ textAlign: "end", position: "sticky", bottom: "0" }}>
                <td colSpan={4} style={{ fontWeight: "bold" }}>
                  Total
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.qty?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {symbol}&nbsp;
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.cost?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {symbol}&nbsp;
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.revenue?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {symbol}&nbsp;
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.profit?.toFixed(
                      decimalPosition
                    )}
                </td>
                <td style={{ fontWeight: "bold" }}>
                  {productMarginSingleList?.total !== undefined &&
                    productMarginSingleList?.total?.profitMargin?.toFixed(
                      decimalPosition
                    )}
                </td>
              </tr>
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
}

export default ProductMarginReportSingleView;
