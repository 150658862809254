import React, { useEffect, useState } from "react";
import "./headerStyle.css";
import ChairIcon from "@mui/icons-material/Chair";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ArticleIcon from "@mui/icons-material/Article";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleButton from "../toggleButton/ToggleButton";
import MultipleSelectionInput from "../input/MultipleSelectionInput";
import AddButton from "../button/AddButton";
import PopUp from "../popup/PopUp";
import {
  addSeatFormKeyName,
  alertMessages,
  headersKeyName,
  shapeTypes,
  structures,
} from "../../utils/floorManagementEnum";
import IconEllipseOutline from "../svg/Ellipse";
import IconPolygon from "../svg/Polygon";
import IconBxRectangle from "../svg/Rectangle";
import StyledBadgeDot from "../badge/StyledBadge";
import { useDispatch, useSelector } from "react-redux";
import {
  setBranch,
  setSeatSelection,
  setSelectedElementDetails,
  setSelectedFloorDetails,
} from "../../addSeat/addSeatSlice";
import { listAllBranches } from "../../apis/commonApi";
import SingleSelectionInput from "../input/SingleSelectionInput";
import {
  addShapesAPI,
  deleteShapesAPI,
  editFloorAPI,
} from "../../addSeat/floorManagementApi";
import LineSegmentTool from "../icons/LineSegmentTool";
import SplitFloorTool from "../icons/SplitFloorTool";
import StarTool from "../icons/StarTool";
import AlertMessage from "../alertMessage/AlertMessage";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import HorizontalLine from "../icons/HorizontalLine";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const Header = () => {
  const dispatch = useDispatch();
  const {
    selectedElementDetails,
    selectedFloorDetails,
    branch,
    branchListFromApi,
    seatSelection,
  } = useSelector((store) => store.addSeatSlice);
  const [hover, setHover] = useState({
    branch: false,
  });

  const tableShapes = [
    { title: structures.ELLIPSE, icon: <IconEllipseOutline /> },
    { title: structures.RECTANGLE, icon: <IconBxRectangle /> },
    { title: structures.POLYGON, icon: <IconPolygon /> },
  ];
  const shapesStructure = [
    { title: structures.LINESEGMENTTOOL, icon: <LineSegmentTool /> },
    { title: structures.SPLITFLOOR, icon: <SplitFloorTool /> },
    { title: structures.HORIZONTAL, icon: <HorizontalLine /> },
    { title: structures.STAR, icon: <StarTool /> },
  ];

  const headerValues = {
    floor: null,
    table: null,
    seats: null,
    shape: null,
    color: null,
  };
  const [activeSelect, setActiveSelect] = useState({
    status: null,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [headerValue, setHeaderValue] = useState(headerValues);
  const [addButtonSelected, setAddButtonSelected] = useState({
    addFloor: false,
  });
  //  header data onchange
  const handleChange = (key, value) => {
    setHover({ branch: false });
    let sendObj = {
      branchId: branch?._id,
      floorId: selectedFloorDetails?._id,
      color: "#656f75",
      size: {
        width: 158,
        height: 149,
      },
      position: {
        x: 224,
        y: 42,
      },
    };
    //shape selected

    setHeaderValue((prevVal) => ({ ...prevVal, [key]: value }));
    if (key === addSeatFormKeyName.BRANCH) {
      dispatch(setBranch(value));
    } else if (key === addSeatFormKeyName.TABLE) {
      //call create table api
      sendObj.structure = value.title;
      setLoading(true);
      addShapesAPI(sendObj, shapeTypes.TABLE, setLoading, setActiveSelect);
    } else if (key === addSeatFormKeyName.SEATS) {
      sendObj.structure = value.title;
      sendObj.size = {
        width: 25,
        height: 25,
      };
      sendObj.position = selectedElementDetails?.position;
      sendObj.tableId = selectedElementDetails?._id;
      setLoading(true);
      addShapesAPI(sendObj, shapeTypes.CHAIR, setLoading, setActiveSelect);
    } else if (key === addSeatFormKeyName.SHAPE) {
      sendObj.structure = value.title;
      if (value.title === structures.SPLITFLOOR) {
        sendObj.size = {
          width: 2,
          height: 200,
        };
      }
      if (value.title === structures.HORIZONTAL) {
        sendObj.size = {
          width: 200,
          height: 2,
        };
      }

      setLoading(true);
      addShapesAPI(sendObj, shapeTypes.SHAPE, setLoading, setActiveSelect);
    } else {
      setHeaderValue((prevVal) => ({ ...prevVal, [key]: value }));
    }
  };

  // add button selected handler
  const handleAddButtonClick = (key) => {
    setAddButtonSelected((prevVal) => ({ [key]: !prevVal[key] }));
  };

  // handle delete
  const handleDelete = () => {
    if (!selectedElementDetails?._id) return;
    setLoading(true);
    deleteShapesAPI(
      {
        _id: selectedElementDetails?._id,
        branchId: branch?._id,
        floorId: selectedElementDetails?.floorId,
        type: selectedElementDetails?.type,
      },
      setLoading,
      setActiveSelect
    );
  };
  const [elementCopied, setElementCopied] = useState(false);
  //handle copy
  const handleCopy = () => {
    setElementCopied(true);
    const sendCopy = {
      branchId: branch?._id,
      floorId: selectedElementDetails?.floorId,
      color: selectedElementDetails?.color,
      structure: selectedElementDetails?.structure,
      size: selectedElementDetails?.size,
      position: selectedElementDetails?.position,
      tableId: selectedElementDetails?.tableId,
    };
    addShapesAPI(
      sendCopy,
      selectedElementDetails.type,
      setLoading,
      setActiveSelect
    );
  };

  //seat selection on change
  const seatSelectionOnChange = () => {
    // dispatch(setSeatSelection(!seatSelection));
    // dispatch(setSelectedFloorDetails(null))
    editFloorAPI({
      branchId: branch?._id,
      seatSelection: !selectedFloorDetails?.seatSelection,
      floorId: selectedFloorDetails?._id,
      _id: selectedFloorDetails?._id,
    });
  };

  useEffect(() => {
    if (selectedElementDetails) {
      dispatch(setSeatSelection(selectedElementDetails?.seatSelection));
      setActiveSelect({
        status: alertMessages.INFO,
        message: `selected ${
          selectedElementDetails?.name || selectedElementDetails?.nameOfFloor
        }`,
      });
    }
  }, [selectedElementDetails]);


  return (
    <>
      <div className="header-wrapper">
        <div className="header-container">
          <ul>
            <li>
              <div
                className="branch-section"
                onMouseEnter={() => setHover({ branch: true })}
                onMouseLeave={() => setHover({ branch: false })}
              >
                <button className="tool-box-btn ">
                  <span className="tool-name">
                    {branch
                      ? branch.branchName.charAt(0).toUpperCase() +
                        branch.branchName.slice(1)
                      : "Branch"}
                  </span>
                </button>
                {hover.branch && (
                  <div className="content">
                    <div className="detail-container">
                      <SingleSelectionInput
                        options={branchListFromApi}
                        value={branch}
                        placeHolder={"Search Branch"}
                        onChange={handleChange}
                        label={"Branch"}
                        focused={true}
                      />
                    </div>
                  </div>
                )}
              </div>
            </li>
            {branch?._id && (
              <li>
                <div className="tool-box-btn add-btn-cnt">
                  <span className="tool-name">Add Floor</span>
                  <AddButton
                    onClick={handleAddButtonClick}
                    keyName={headersKeyName.ADDFLOOR}
                  />
                </div>
              </li>
            )}
            {/* floor tools  */}
            {branch && selectedFloorDetails && (
              <>
                <li>
                  <div
                    className="branch-section"
                    onMouseLeave={() =>
                      setAddButtonSelected({ addTable: false })
                    }
                  >
                    <div className="tool-box-btn add-btn-cnt">
                      <span className="tool-name">Table</span>
                      <AddButton
                        onClick={handleAddButtonClick}
                        keyName={headersKeyName.ADDTABLE}
                      />
                    </div>
                    {addButtonSelected?.addTable && (
                      <div
                        className="content"
                        onMouseLeave={() =>
                          setHeaderValue((prevVal) => ({
                            ...prevVal,
                            table: null,
                          }))
                        }
                      >
                        <div className="detail-container">
                          <ul>
                            {tableShapes?.map((res, i) => (
                              <li key={i}>
                                <button
                                  onClick={() =>
                                    handleChange(addSeatFormKeyName.TABLE, {
                                      title: res.title,
                                      status: true,
                                    })
                                  }
                                  className="table-tool-box"
                                >
                                  <StyledBadgeDot
                                    isActive={
                                      headerValue?.table?.title === res.title
                                        ? true
                                        : false
                                    }
                                  />
                                  <div className="icon-image-container">
                                    {React.cloneElement(res.icon, {
                                      color:
                                        headerValue?.table?.title === res.title
                                          ? "#0022ff"
                                          : "#000000",
                                    })}
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        headerValue?.table?.title === res.title
                                          ? "#0022ff"
                                          : "#000000",
                                    }}
                                  >
                                    {res?.title}
                                  </div>
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
                {selectedElementDetails?.type === shapeTypes.TABLE && (
                  <li>
                    <div className="tool-box-btn add-btn-cnt">
                      <span className="icon-span">
                        <ChairIcon fontSize="small" />
                      </span>
                      <span className="tool-name">Seats</span>
                      <AddButton
                        onClick={() =>
                          handleChange(addSeatFormKeyName.SEATS, {
                            // title: res.title,
                            status: true,
                          })
                        }
                        // onClick={handleAddButtonClick}
                        keyName={headersKeyName.ADDSEATS}
                      />
                    </div>
                  </li>
                )}
                <li>
                  <div
                    className="branch-section"
                    onMouseLeave={() =>
                      setAddButtonSelected({ addTable: false })
                    }
                  >
                    <div className="tool-box-btn add-btn-cnt">
                      <span className="tool-name">Add Shape</span>
                      <AddButton
                        onClick={handleAddButtonClick}
                        keyName={headersKeyName.ADDSHAPE}
                      />
                    </div>
                    {addButtonSelected?.addShape && (
                      <div
                        className="content"
                        onMouseLeave={() =>
                          setHeaderValue((prevVal) => ({
                            ...prevVal,
                            shape: null,
                          }))
                        }
                      >
                        <div className="detail-container">
                          <ul>
                            {shapesStructure?.map((res, i) => (
                              <li key={i}>
                                <button
                                  onClick={() =>
                                    handleChange(addSeatFormKeyName.SHAPE, {
                                      title: res.title,
                                      status: true,
                                    })
                                  }
                                  className="table-tool-box"
                                >
                                  <StyledBadgeDot
                                    isActive={
                                      headerValue?.shape?.title === res.title
                                        ? true
                                        : false
                                    }
                                  />
                                  <div className="icon-image-container">
                                    {React.cloneElement(res.icon, {
                                      color:
                                        headerValue?.shape?.title === res.title
                                          ? "#0022ff"
                                          : "#000000",
                                    })}
                                  </div>
                                  <div
                                    style={{
                                      color:
                                        headerValue?.shape?.title === res.title
                                          ? "#0022ff"
                                          : "#000000",
                                    }}
                                  >
                                    {res?.title}
                                  </div>
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
               { selectedElementDetails?.isFloor && <li>
                  <button
                    onClick={() =>
                      handleAddButtonClick(headersKeyName.SETCOLOR)
                    }
                    className="tool-box-btn"
                  >
                    <span className="icon-span">
                      <FormatColorFillIcon fontSize="small" />{" "}
                    </span>
                    <span className="tool-name">Color</span>
                  </button>
                </li>}
                <li>
                  <button
                    onClick={() => handleAddButtonClick(headersKeyName.RENAME)}
                    className="tool-box-btn"
                  >
                    <span className="icon-span">
                      <ArticleIcon fontSize="small" />
                    </span>
                    <span className="tool-name">Rename</span>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleCopy()}
                    onMouseLeave={() => setElementCopied(false)}
                    className="tool-box-btn"
                  >
                    <span className="icon-span">
                      {elementCopied ? (
                        <FileCopyIcon fontSize="small" />
                      ) : (
                        <ContentCopyIcon fontSize="small" />
                      )}
                    </span>
                    <span className="tool-name">Copy</span>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleDelete()}
                    className="tool-box-btn"
                  >
                    <span className="icon-span">
                      <DeleteIcon fontSize="small" />
                    </span>
                    <span className="tool-name">Delete</span>
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
        <div>
          <div className="toggle-switch-wrapper">
            <span className="tool-name">Enable seat selection</span>
            <ToggleButton
              onChange={seatSelectionOnChange}
              checked={selectedFloorDetails?.seatSelection||false}
            />
          </div>
        </div>
      </div>
      <PopUp
        open={addButtonSelected}
        handleChange={setHeaderValue}
        onClose={handleAddButtonClick}
      />
      <AlertMessage
        status={activeSelect?.status}
        alertMessage={activeSelect?.message}
        setApiAlertMessage={setActiveSelect}
        duration={1000}
      />
      <LoadingForm loading={loading} />
    </>
  );
};

export default Header;
