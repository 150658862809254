import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch } from 'react-redux';
import { setApplyBtnClicked } from '../../Modules/Sales/Billing/Utilities/filterButtonSlice';
const SyncSessionDataButton = () => {

    const dispatch=useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);

    // Open the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle option selection
    const handleSelect = (option) => {
        option.action()
    };

    const productsReload=()=>{
        dispatch(setApplyBtnClicked("productFetch"))
    }
    const options = [
        { label: 'Products', action:productsReload },
    ];
  return (
    <div>
            <IconButton onClick={handleClick}>
                <SyncIcon
                style={{ fontSize: 24, color: "#000" }}
                />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleSelect(option)}>
                        {option?.label}
                        &nbsp;&nbsp;&nbsp;
                        <SyncIcon fontSize='small'/>
                    </MenuItem>
                ))}
            </Menu>
        </div>
  )
}

export default SyncSessionDataButton