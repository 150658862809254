import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import {
  accountPayableAgingReport,
  accountPayableAgingReportApi,
} from "./partnerReportApi";
import { today } from "../../../../../Js/Date";
import { setAccountPayableAgingReport } from "./partnerReportSlice";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import {
  convertArrayToExcel,
  filterObjFromList,
} from "../../../../../Js/generalFunctions";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Template from "./AccountAgingPrint";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../../Redux/store";
import ExcelJS from "exceljs";
import TableListActionButtons from "../../../../Single Components/ActionButtons/TableListActionButtons";
import PrintComponent from "../../../../print/report/PrintComponent";

const AccountPayableAgingReport = () => {
  const filtereDataOptions = useSelector((state) => state.listCategory);
  let paymentsPrintNew = useRef();
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const decimalPosition = localStorage.getItem("decimalPosition");
  const [symbol, setSymbol] = useState(null);

  const { AccountPayableAgingReportList } = useSelector(
    (res) => res.PartnerReport
  );

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const initialvalue = {
    label: "Account Payable Aging Report",
    sumOfcurrent: 0,
    sumOf31: 0,
    sumOf61: 0,
    sumOf91: 0,
    sumOf121: 0,
    sumOf151: 0,
    sumOfAbove151: 0,
    sumOfTotal: 0,

    companyLogo: "",
    companyName: "",
    companyAddress: "",
    companyContact: "",
  };

  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialvalue);

  useEffect(() => {
    setFormValues({
      ...formValues,
      sumOfcurrent: AccountPayableAgingReportList?.sumOfcurrent || 0,
      sumOf31: AccountPayableAgingReportList?.sumOf31 || 0,
      sumOf61: AccountPayableAgingReportList?.sumOf61 || 0,
      sumOf91: AccountPayableAgingReportList?.sumOf91 || 0,
      sumOf121: AccountPayableAgingReportList?.sumOf121 || 0,
      sumOf151: AccountPayableAgingReportList?.sumOf151 || 0,
      sumOfAbove151: AccountPayableAgingReportList?.sumOfAbove151 || 0,
      sumOfTotal: AccountPayableAgingReportList?.sumOfTotal || 0,

      companyLogo: companyProfileData?.logo || "",
      companyName: companyProfileData?.companyName || "",
      companyAddress: companyProfileData?.companyAddress || "",
      companyContact: companyProfileData?.contactNumber || "",
    });
    setData(AccountPayableAgingReportList?.list);
  }, [AccountPayableAgingReportList, companyProfileData]);

  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };

  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate) {
      parts.push(`As of ${filters?.toDate} `);
    }
    // console.log(filters?.toDate);

    // if (filters?.branchList) {
    //   parts.push(`Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`);
    // }

    return parts; // Return the array directly
  };
  const handlePrint = useReactToPrint({
    content: () => paymentsPrintNew.current,
  });
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Account Payable Ageing Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Account Payable Ageing Report"]);
    titleRow.font = { size: 18, bold: true };
    sheet.mergeCells("A1:I1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredData) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 15, bold: true };
        sheet.mergeCells(`A${index + 2}:I${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "vendor",
      "current",
      "1 to 30",
      "31 to 60",
      "61 to 90",
      "91 to 120",
      "121 to 150",
      "150 Above",
      "Total",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF002995" }, // Use full hex code with "FF" prefix for better compatibility
    };

    const headerFont = {
      size: 15,
      bold: true,
      color: { argb: "FFFFFFFF" }, // White font color
    };

    headerRow.eachCell((cell) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "vendor", key: "customerName", width: 50 },
      { header: "Current", key: "current", width: 20 },
      { header: "1 to 30", key: "upto30days", width: 20 },
      { header: "31 to 60", key: "upto60days", width: 20 },
      { header: "61 to 90", key: "upto90days", width: 20 },
      { header: "91 to 120", key: "upto120days", width: 20 },
      { header: "121 to 150", key: "upto150days", width: 20 },
      { header: "150 Above", key: "morethan150days", width: 20 },
      { header: "Account Payable Ageing Report", key: "total", width: 20 },
    ];

    let rowIndex = 0;

    AccountPayableAgingReportList?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        customerName: ` ${item?.customerName}`,
        current: ` ${item?.categories?.current?.toFixed(2)}`,
        upto30days: ` ${item?.categories?.upto30days?.toFixed(2)}`,
        upto60days: ` ${item?.categories?.upto60days?.toFixed(2)}`,
        upto90days: ` ${item?.categories?.upto90days?.toFixed(2)}`,
        upto120days: ` ${item?.categories?.upto120days?.toFixed(2)}`,
        upto150days: ` ${item?.categories?.upto150days?.toFixed(2)}`,
        morethan150days: ` ${item?.categories?.morethan150days?.toFixed(2)}`,
        total: ` ${item?.total?.toFixed(2)}`,
      });

      const rowFill = rowIndex % 2 === 0 ? "FFd2deff" : "FFFFFFFF"; // Alternate row colors
      row.eachCell((cell, colNumber) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: rowFill },
        };
        cell.border = {
          top: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber > 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "right" };
        }
      });

      rowIndex++;
    });

    // Add row for totals
    const totalRow = sheet.addRow([
      "Total",
      ` ${AccountPayableAgingReportList?.sumOfcurrent?.toFixed(2) || ""}`,
      ` ${AccountPayableAgingReportList?.sumOf31?.toFixed(2)}`,
      ` ${AccountPayableAgingReportList?.sumOf61?.toFixed(2)}`,
      ` ${AccountPayableAgingReportList?.sumOf91?.toFixed(2)}`,
      ` ${AccountPayableAgingReportList?.sumOf121?.toFixed(2)}`,
      ` ${AccountPayableAgingReportList?.sumOf151?.toFixed(2)}`,
      ` ${AccountPayableAgingReportList?.sumOfAbove151?.toFixed(2)}`,
      ` ${
        AccountPayableAgingReportList?.currencySymbol
      } ${AccountPayableAgingReportList?.sumOfTotal?.toFixed(2)}`,
    ]);

    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
      cell.fill = headerFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber > 1) {
        // Right align all columns except the first
        cell.alignment = { horizontal: "right" };
      }
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Account Payable Ageing Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    dispatch(setAccountPayableAgingReport([]));
    if (FormDataInfo?.length !== 0) {
      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData &&
        accountPayableAgingReportApi(
          {
            todayDate:
              FormDataInfo.toDate === ""
                ? `${today} 23:59:59`
                : `${FormDataInfo.toDate} 23:59:59`,
            // fromDate: FormDataInfo.fromDate,
            // toDate: FormDataInfo.toDate,
            search: FormDataInfo?.search,
          },
          setIsLoading
        );
    }
  }, [FormDataInfo.fromDate, FormDataInfo.toDate, FormDataInfo.search]);

  const handleRowClick = (value) => {
    localStorage.setItem(
      "toDate",
      FormDataInfo.toDate === ""
        ? `${today} 23:59:59`
        : `${FormDataInfo.toDate} 23:59:59`
    );
    store.dispatch(setFilterActive(window.location.pathname));
    if (value) {
      navigate(
        "/userdashboard/accounts/report/partnerReport/AccountPayableAgingReportSingleView"
      );
      localStorage.setItem("singleViewById", value);
    }
  };

  return (
    <div
      className="in-or-out-container updated-global-page-parent-container"
      style={{ margin: "8px" }}
    >
      <div className="justify-space-between list-create-head-bar">
        <h3>Account Payable Aging Report</h3>
        <div className="create-head-buttons-container">
          <TableListActionButtons
            downloadClick={downloadList}
            PrintClick={handlePrint}
          />
          <CategorySearchandFilter
            onData={handleDataFromChild}
            isFromDate={false}
            isBranch={false}
            onBranchSelect={handleBranchSelect}
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

      <div
        className="table-wrapper-container updated-table-container"
        style={{ height: "calc(100vh - 145px)" }}
      >
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="transaction-table"
            sx={{ padding: 0 }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Vendor</TableCell>
                <TableCell>Current</TableCell>
                <TableCell>1 to 30</TableCell>
                <TableCell>31 to 60</TableCell>
                <TableCell>61 to 90</TableCell>
                <TableCell>91 to 120</TableCell>
                <TableCell>121 to 150</TableCell>
                <TableCell>150 Above</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AccountPayableAgingReportList?.list?.length > 0 ? (
                AccountPayableAgingReportList?.list?.map((item, index) => (
                  <TableRow
                    key={index}
                    className={index % 2 === 0 ? "even-row " : ""}
                    onClick={() => handleRowClick(item.customerId)}
                  >
                    <TableCell>{item?.customerName}</TableCell>
                    <TableCell className="amount-td">
                      {item?.categories?.current}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;
                      {item?.categories?.upto30days?.toFixed(decimalPosition)}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;
                      {item?.categories?.upto60days?.toFixed(decimalPosition)}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;
                      {item?.categories?.upto90days?.toFixed(decimalPosition)}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;
                      {item?.categories?.upto120days?.toFixed(decimalPosition)}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;
                      {item?.categories?.upto150days?.toFixed(decimalPosition)}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;
                      {item?.categories?.morethan150days?.toFixed(
                        decimalPosition
                      )}
                    </TableCell>
                    <TableCell className="amount-td">
                      {symbol}&nbsp;{item?.total?.toFixed(decimalPosition)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data-td" colSpan={9}>
                    NO DATA
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter style={{ position: "sticky", bottom: 0 }}>
              <TableRow className="input-and-output-total">
                <TableCell>Total </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOfcurrent?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOf31?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOf61?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOf91?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOf121?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOf151?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.sumOfAbove151?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
                <TableCell>
                  {AccountPayableAgingReportList?.currencySymbol}{" "}
                  {AccountPayableAgingReportList?.sumOfTotal?.toFixed(
                    decimalPosition
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <LoadingForm loading={isLoading} />
      <div style={{ display: "none" }}>
        <div ref={paymentsPrintNew}>
          <PrintComponent header={"Account Payable Aging Report"} subHead={"-"} pageHeight={60} >
            <table>
              <thead>
                <tr>
                  <th>Vendor</th>
                  <th>Current</th>
                  <th>1 to 30</th>
                  <th>31 to 60</th>
                  <th>61 to 90</th>
                  <th>91 to 120</th>
                  <th>121 to 150</th>
                  <th>150 Above</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {AccountPayableAgingReportList?.list?.length > 0 ? (
                  AccountPayableAgingReportList?.list?.map((item, index) => (
                    <>
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even-row " : ""}
                        onClick={() => handleRowClick(item.customerId)}
                      >
                        <td>{item?.customerName}</td>
                        <td className="amount-td">
                          {item?.categories?.current}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;
                          {item?.categories?.upto30days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;
                          {item?.categories?.upto60days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;
                          {item?.categories?.upto90days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;
                          {item?.categories?.upto120days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;
                          {item?.categories?.upto150days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;
                          {item?.categories?.morethan150days?.toFixed(
                            decimalPosition
                          )}
                        </td>
                        <td className="amount-td">
                          {symbol}&nbsp;{item?.total?.toFixed(decimalPosition)}
                        </td>
                      </tr>
                      {AccountPayableAgingReportList?.list?.length - 1 ===
                        index && (
                        <tr className="new-layout-print-footer">
                          <td>Total </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOfcurrent?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOf31?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOf61?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOf91?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOf121?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOf151?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.sumOfAbove151?.toFixed(
                              decimalPosition
                            )}
                          </td>
                          <td>
                            {AccountPayableAgingReportList?.currencySymbol}{" "}
                            {AccountPayableAgingReportList?.sumOfTotal?.toFixed(
                              decimalPosition
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td className="no-data-td" colSpan={9}>
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </PrintComponent>
        </div>
      </div>
    </div>
  );
};

export default AccountPayableAgingReport;
