import React, { useState, useEffect } from 'react';
import { getTimer } from '../../Js/Date';
import { useSelector } from 'react-redux';

const ShiftTimerComponent = ({ date }) => {

    const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);
    const [timer, setTimer] = useState('00:00:00');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer(getTimer(shiftStatus?.startDate));
        }, 1000);
        return () => clearInterval(intervalId);
    }, [date,shiftStatus]); 

    return (
        <p style={{margin:"3px 0"}}>{
            shiftStatus?.startDate? timer:"00:00:00"}</p>
    );
};

export default ShiftTimerComponent;