import { IconButton } from '@mui/material'
import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const BackButton = ({onClick,className="self-back-arrow-btn"}) => {
  return (
    <div className={className}>
    <IconButton onClick={onClick} style={{border:"1px solid #000"}}>
        <ArrowBackIcon className='responsive-arrow-icon'/>
    </IconButton>
    </div>
  )
}

export default BackButton