import React from 'react'
const BorderMovingBtn = ({icon,label,className="",onClick}) => {
  return (
      <div className={`animated-border-button ${className}`} onClick={onClick}>

            {label || icon}
      </div>
  )
}

export default BorderMovingBtn