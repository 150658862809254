import { createFilterOptions } from "@mui/material";


const filterOptionsPaginated = createFilterOptions({
  ignoreCase: true,
  matchFrom: "any",
  limit: 30,
  stringify: (option) => `${option.name} ${option.mobileNo}`,
});

export const customerFilterOptions = (options, state) => {
  const inputValue = state.inputValue.replace(/^0+/, ''); // Remove leading zeros
  const filtered = filterOptionsPaginated(options, { ...state, inputValue }); // Use the initial filter options with preprocessed inputValue
  return filtered.filter(option => {
      const searchStr = `${option.name} ${option.mobileNo}`;
      return searchStr.toLowerCase().includes(inputValue.toLowerCase());
  });
};



export const pdfBlobOptionsPrint = {
  filename: 'receipt.pdf',
  image: { type: 'jpeg', quality: 0.98 },
  html2canvas: { scale: 2, logging: false },
  jsPDF: { 
    unit: 'mm', 
    format: [80, 'auto'], // This will be overridden in the function
    orientation: 'portrait' 
  }
};



export const getTableHeightForVirtuso = (tableData) => {
  if (tableData?.length === 1) return "80px";
  if (tableData?.length < 5) return `${tableData.length * 42 + 36}px`;
  return "260px";
};

export const filterProductsOption=createFilterOptions({
  ignoreCase: true,
  matchFrom: "any",
  limit: 75
});


export const generateProducts=(count)=> {
  const products = [];
  
  for (let i = 0; i < count; i++) {
    products.push({
      _id: (i + 1).toString().padStart(24, '0'), // Creates unique _id with 24 characters
      productName: `Product ${i + 1}`, // Unique product name
      barcode:(i+1*3699954)
    });
  }
  
  return products;
}