import axios from "axios"
import { errorSnackMsg, successSnackMsg } from "../../component/Custom Hooks/SnackkBarUtilities"
import { setSubmitLoading } from "../../Redux/Dashboard/mainDashBoardSlice"
import store from "../../Redux/store"
import { HEADERS } from "../../API/UrlAndPaths"
import { selfDialogScreenSelected, setOngoingOrdersForSelf, setSelfSingleOrderInfo } from "../Slices/selfOrderMainSlice"
import { setSelfCustomer, setSelfMobileNo, setSelfOrderScreen } from "../Components/Order/selfOrderEssentialsSlice"

export const verifyPasswordAuthentication= async (
    body,authSuccessFn
    )=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("admin/verifypassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(setSubmitLoading(false))
            authSuccessFn()
        }
    })
    .catch((err)=>{
        errorSnackMsg(err?.response?.status===401?"Incorrect Password" : err.message || err.response.data)
        store.dispatch(setSubmitLoading(false))
    })
}

export const singleSelfOrderInfoAPICall=async(body,postFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/viewPosOrderById",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
                store.dispatch(setSubmitLoading(false))
                store.dispatch(setSelfSingleOrderInfo(res.data))
                postFn?.(); 
        }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            store.dispatch(setSelfSingleOrderInfo(null))
        })
}


export const selfSelectCustomerAPI=async(body,backState=false)=>{
    try{
        store.dispatch(setSubmitLoading(true))
        const response =await axios.post("customerPos/customerLogin",body,HEADERS)

        if(response.status===200){
            successSnackMsg("Customer fetched successfully")
            store.dispatch(setSelfCustomer(response.data?.data))
            store.dispatch(setSelfMobileNo(null))
            backState && store.dispatch(setSelfOrderScreen("orderProductLists"))

        }
    }
    catch(err){
        errorSnackMsg(err.response.data?.message)
        err.response.status===404 && store.dispatch(selfDialogScreenSelected("registerPopup"))
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}
export const selfAddCustomerAPI=async(body)=>{
    try{
        store.dispatch(setSubmitLoading(true))
        const response =await axios.post("account/createVendor",body,HEADERS)

        if(response.status===200){
            let {customerTopbar}=store.getState().selfOrderEssentialsSlice
            successSnackMsg("Customer added successfully")
            store.dispatch(setSelfCustomer(response.data))
            store.dispatch(setSelfMobileNo(null))
            store.dispatch(setSelfOrderScreen(customerTopbar?"orderProductLists":"loginScreen"))
        }
    }
    catch(err){
        errorSnackMsg(err.response.data)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}

export const completeSelfOrderAPI=async(body)=>{
    try{
        store.dispatch(setSubmitLoading(true))
        const response =await axios.post("pos/completeSelfOrder",body,HEADERS)

        if(response.status===200){
            successSnackMsg("Order completed")
            store.dispatch(setSelfOrderScreen("orderFeedbackScreen"))
        }
    }
    catch(err){
        errorSnackMsg(err?.response?.data)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}
export const submitFeedbackSelfOrderAPI=async(body,postFn)=>{
    try{
        store.dispatch(setSubmitLoading(true))
        const response =await axios.post("pos/addSelfOrderCustReview",body,HEADERS)
        response.status===200 && postFn?.()
    }
    catch(err){
        errorSnackMsg(err?.response?.data)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}


export const onGoingOrdersForSelf=async(body)=>{
    try{
        const response=await axios.post("pos/listPosOrders",body,HEADERS)

        if(response.status===200){
            store.dispatch(setOngoingOrdersForSelf(response?.data))
        }
    }
    catch(err){
        store.dispatch(setOngoingOrdersForSelf([]))
    }
}