import React, { useState } from 'react'
import "./SelfFeedback.css"
import { QRCode, Rate } from 'antd'
import billIcon from '../../../Assets/Images/Logo.png';
import SelfOrderFooter from '../Navbar/SelfOrderFooter';
import { useSelector } from 'react-redux';
import { Dialog } from '@mui/material';
import PosReceiptTemplate from '../../../component/Modules/components/template/posReceipt/PosReceiptTemplate';
import { submitFeedbackSelfOrderAPI } from '../../API/selfOrderAPIS';
const SelfFeedback = ({newOrderClick}) => {

    const { receiptData } = useSelector((state) => state.viewOrdersBillingSlice);

    const domainAddress = `${window.location.protocol}//${window.location.host}/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(receiptData?.db)}&vendororcusid=${encodeURIComponent(receiptData?.cusId)}&type=${encodeURIComponent("POS")}&orderId=${encodeURIComponent(receiptData?._id)}`;

    const [viewReceipt,setViewReceipt]=useState(false)
    const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
    const [value,setValue]=useState(5)
    const [feedBack,setFeedback]=useState("")

    const submitRatingClick=()=>{
        let payload={
            orderId: receiptData?._id,
            custRating: value,
            custComment: feedBack
        }
        submitFeedbackSelfOrderAPI(payload,newOrderClick)
    }
    return (
        <div className='self-feedback-ctn self-order-middle-container'>
            <div className='feedback'>
                <div className='self-feedback-first-main'>
                    <div className='self-feedback-content '>
                        <h1>Your Order No:</h1>
                        <h3> {receiptData?.name}</h3>
                    </div>
                </div>
                <div className='self-feedback-secound-main'>
                    <div className='self-feedback-content stardiv'>
                        <h1> Give us feedback</h1>
                        <div className="star-rating">
                            <Rate 
                                tooltips={desc} 
                                allowHalf
                                style={{ fontSize: '32px' }}
                                onChange={setValue} 
                                value={value} 
                            />
                        </div>
                            <div className='feedback-textarea-div'>
                                <textarea
                                    value={feedBack}
                                    onChange={(e)=>setFeedback(e.target.value)}
                                    className="feedback-textarea"
                                    placeholder="Type Something....."
                                    rows="5"
                                ></textarea>
                            </div>
                    </div>
                    <div className='self-feedback-content QRcodeDiv'>
                        <QRCode 
                        value={domainAddress}
                        className='qrcode'
                        icon={billIcon}
                        size={150}
                        imageSettings={{
                          src: "/logo.png", // Your logo path
                          x: null, // Automatically centers the logo
                          y: null,
                          height: 40,
                          width: 40,
                          excavate: true, // Ensures QR code parts aren't covered
                        }}
                        />
                        <h1>SCAN QR CODE FOR RECEIPT</h1>
                        <h1>OR</h1>
                        <button className='self-btn' onClick={()=> setViewReceipt(true)}>View Receipt</button>
                    </div> 
                </div>
               


            </div>
            <SelfOrderFooter redBtnText={"SUBMIT"} blackBtnText={"NEW ORDER"} blackBtnOnclick={newOrderClick} redBtnOnClick={submitRatingClick}/>

            <Dialog open={viewReceipt} onClose={()=>setViewReceipt(false)}>
                <PosReceiptTemplate />
            </Dialog>
        </div>
    )
}

export default SelfFeedback