import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import useProducts from '../../../component/Modules/Sales/Billing/Utilities/useProducts';
import { Skeleton } from '@mui/material';
import dummyProductItemImg from "../../../Assets/BillingImages/emptyFoodImg.svg";

const ProductCardContainer = ({ addToCart, isLoading,specialItemCat }) => {
    const decimalPosition = localStorage.getItem('decimalPosition');
    const userInfo = useSelector((state) => state.loginResponseSlice.value);
    const { productsList } = useSelector((state) => state.newBillingSlice);
    const {mainCat,subCat,searchBarInput} = useSelector((state) => state.selfOrderEssentialsSlice);

    const { data, loading } = useProducts({ branchId: userInfo?.branchPk });

    const [filteredData, setFilteredData] = useState([]);

    // Create a Map for quick lookup of products by name
    const productMap = useMemo(() => {
        const map = new Map();
        const sourceData = specialItemCat ? productsList : data || [];
        
        sourceData.forEach(product => {
            map.set(product.productName.toLowerCase(), product);
        });
        return map;
    }, [data, productsList, specialItemCat]);
    // Filter products based on search query
    const getFilteredData = (searchQuery) => {
        const lowerSearchQuery = searchQuery?.toLowerCase();
        const results = [];

        productMap.forEach((product) => {
            const matchesSearch = lowerSearchQuery ? product?.productName.toLowerCase().includes(lowerSearchQuery) : true;
            const matchesCategory = mainCat ? product.mainCatgeoryId === mainCat?._id : true; // Replace 'categoryId' with actual field
            const matchesSubCategory = subCat ? product.poscat === subCat?._id : true; // Replace 'subCategoryId' with actual field
            
            if (matchesSearch && matchesCategory && matchesSubCategory) {
                results.push(product);
            }
        });

        return results;
    };
    useEffect(() => {
        setFilteredData(getFilteredData(searchBarInput));
    }, [searchBarInput, productMap,subCat,mainCat]); 


    const renderSkeletons = (count) => (
        [...Array(count)].map((_, i) => (
            <div key={i} className="billing-single-product-container">
                <Skeleton width="100%" height="100px" />
                <Skeleton width="100%" height="20px" />
                <div className="billing-product-list-select-container">
                    <Skeleton width="60%" height="20px" />
                    <Skeleton width="30%" height="30px" />
                </div>
            </div>
        ))
    );
  return (
    <div className='self-product-card-ctn'>
    {(loading || isLoading) ? ( renderSkeletons(10)) :
     filteredData.length !== 0 ? (
        filteredData.map((product, index) => (
            <div
                className="self-product-card-item"
                key={index}
                onClick={() => addToCart(product)}>
                <img
                    src={product?.imageUrl && product?.imageUrl.length !== 0
                        ? product?.imageUrl
                        : dummyProductItemImg}
                    alt="No Img"
                    className="product-card-image"
                />
                <h5>{product.productName}</h5>
                <p>${product.salesPrice?.toFixed(decimalPosition)}</p>
            </div>
        ))
    ) : (
        <div className="center-div" style={{ height: "61vh" }}>
            No Products Available !!
        </div>
    )}

    </div>
  )
}

export default ProductCardContainer