import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./selfOrdersListing.css";
import brokenImg from "../../../component/Modules/Sales/Billing/image/picture.png"
import { setCatSubList, setSelfMainCat, setSelfSubCat, setSubCatChildList } from './selfOrderEssentialsSlice';
import { initialSubCatFilterAPI } from './selfOrderListingAPI';
const CategoryListingScroll = () => {
  const dispatch=useDispatch()

  
  const CategoryList = useSelector((state) => state.categorySlice.value);
  const {mainCat} = useSelector((state) => state.selfOrderEssentialsSlice);
  const itemRefs = useRef([]);

  const catClick = (item) => () => {
    let category=item?._id===mainCat?._id?null:item
    dispatch(setSelfMainCat(category))
    category?initialSubCatFilterAPI({catId:category?._id}):dispatch(setCatSubList([]))
    
    dispatch(setSelfSubCat(null))
    dispatch(setSubCatChildList([]))
  }
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 }
    );

    itemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      itemRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [mainCat,CategoryList]);

  return (
    <div className="category-list-container">
      {CategoryList?.map((item, index) => (
        <div
          key={index}
          ref={(el) => (itemRefs.current[index] = el)}
          className={`item ${item?._id===mainCat?._id?'self-selected-selector': ''}`}
          onClick={catClick(item)}
        >
          <img src={item.imageUrl || brokenImg} alt={item.categoryName} />
          <p>{item.categoryName}</p>
        </div>
      ))}
    </div>
  );
};

export default CategoryListingScroll;
