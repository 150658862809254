import { Dialog, IconButton, Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBtnSelected } from './topBarSliceBilling'
import { setDialogBox, setOrderIdInPOS } from '../Slice/billingScreenSlice'
import HomeIcon from '@mui/icons-material/Home';
import TopBar from './TopBar'
import { storeSingleReserevation } from '../Slice/reservationSlice'
import AddReservationPopup from './AddReservationPopup'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FilterButton from '../Utilities/FilterButton'
import { editReservationAPI, generateReservationName, listReservationList, postReservationAPI } from '../API/billingReservation'
import { convertDateFormat, convertToAMPM, getCurrentTime, today } from '../../../../../Js/Date'
import { listHeldOrders } from '../API/viewOrderSection'
import { setApplyBtnClicked, setFilterPopup, setOrderTypeOptions, setStatusOptions } from '../Utilities/filterButtonSlice'
function ReservationBilling() {

    const dispatch=useDispatch()
    const userInfo = useSelector((state) => state.loginResponseSlice.value);

    const {tableLoading}=useSelector((state)=>state.viewOrdersBillingSlice)
    const {dialogOpen}=useSelector((state)=>state.billingScreenSlice)
    const {reservationList,selectedReservation}=useSelector((state)=>state.reservationSlice)
    const {filteredOrderType,filteredStatus,applyBtnClicked}=useSelector((state)=>state.filterButtonSlice)

    const reservationSingleRowClick=(row)=>()=>{
        let data=row?._id===selectedReservation?._id?null:row
        dispatch(storeSingleReserevation(data))
    }

    const ReservationClick=(key)=>()=>{
        
        let payload={
            _id:selectedReservation?._id,
            status:"cancel"
        }
        let clearFn=()=>{
            listReservationList({branchId:userInfo?.branchPk})
            dispatch(storeSingleReserevation(null))
            listHeldOrders({branchId:userInfo?.branchPk,type:1})
        }

        if(key==="cancel"){
            editReservationAPI(payload,clearFn)
        }else if(key==="add"){
            let body={
                postDate: `${today} ${getCurrentTime()}`,
                reservationId:selectedReservation?._id
            }
            postReservationAPI(body,clearFn)
        }
    }
    
    useEffect(()=>{
        let statusOptions=["PENDING","COMPLETED","CANCEL"]
        let orderTypeOptions=["DINEIN","DELIVERY","TAKEAWAY"]
        dispatch(setStatusOptions(statusOptions))
        dispatch(setOrderTypeOptions(orderTypeOptions))
    },[])


    useEffect(()=>{
        if(applyBtnClicked){
            dispatch(setApplyBtnClicked(false))
            let payload={
                status:filteredStatus,
                ordType:filteredOrderType
            }
            dispatch(setFilterPopup(false))
        }
    },[applyBtnClicked])
    return (
        <div className='billing-main-container'>
            <div className="billing-left-container">

                <div className="flex-row" style={{ gap: "5px" }}>
                    <div className='justify-center' style={{ backgroundColor: "#fff", width: "40px" }}>
                        <IconButton
                            onClick={() => {
                                dispatch(setBtnSelected(null))
                                dispatch(setOrderIdInPOS(null))
                            }}
                        >
                            <HomeIcon sx={{ color: "green" }} />
                        </IconButton>
                    </div>
                    <TopBar />
                </div>

                <div className="billing-payment-ctn" style={{position:"relative",height:"85vh",backgroundColor:"#fff"}}>
                    <div className="billing-payment-ctn-left">
                        <div className="justify-space-between" style={{padding:"0 1%"}}>
                            <h4>Reservation</h4>
                            <div className="justify-center reservation-top-right">
                                {/* <FilterButton/> */}
                                <button 
                                className="btn-primary btn"
                                onClick={()=>{
                                    dispatch(storeSingleReserevation(null))
                                    dispatch(setDialogBox("addReservation"))
                                    generateReservationName({branchId:userInfo?.branchPk})
                                }}
                                >Create Reservation</button>
                            </div>
                        </div>
                        <div
                            style={{ maxHeight: "90vh" }}
                            className="payment-view-order-table new-global-table-container">

                            <table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Sequence</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Total Person</th>
                                        <th>Table</th>
                                        <th>Note</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableLoading ?
                                            (
                                                [...Array(10)].map((r, i) => (
                                                    <tr key={i}>
                                                        <td colSpan={10}>
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                width={"100%"}
                                                                height={40}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            ) :
                                            reservationList.length !== 0 ?
                                            reservationList?.list?.map((r, i) => (
                                                    <tr
                                                        key={i}
                                                        onClick={reservationSingleRowClick(r)}
                                                        style={{backgroundColor:selectedReservation?._id===r?._id?"rgb(201 189 189)":"#fff"}}
                                                    >
                                                        <td>{i + 1}</td>
                                                        <td>{r?.name}</td>
                                                        <td>{r?.customerNameL}</td>
                                                        <td>{r?.mobileNo}</td>
                                                        <td>{convertDateFormat(r?.date)}</td>
                                                        <td>{convertToAMPM(r?.time)}</td>
                                                        <td>{r?.totalPerson}</td>
                                                        <td>{r?.tableNames}</td>
                                                        <td>{r?.note}</td>
                                                        <td className="table-data-box">
                                                            {r?.status === "PENDING" ? (
                                                                <span className="draft">&nbsp;&nbsp; PENDING&nbsp;&nbsp; </span>
                                                            ) : (r?.status === "COMPLETED" || r?.status === "DONE") ? (
                                                                <span className="post"> COMPLETED</span>
                                                            ) : r?.status === "cancel" ? (
                                                                <span className="cancel">&nbsp;&nbsp; CANCEL&nbsp;&nbsp;</span>
                                                            ) : (
                                                                <span>{r?.status}</span>
                                                            )}
                                                        </td>
                                                    </tr>

                                                )) :
                                                <tr>
                                                    <td colSpan={19}>No Records</td>
                                                </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                    {
                    selectedReservation!==null &&   selectedReservation?.status==="PENDING"&& 
                    <div className="action-buttons-ctn justify-center">
                        <button className='cancel' onClick={ReservationClick("cancel")}>
                            <CancelOutlinedIcon/>
                            Cancel Reservation
                        </button>
                        <button className='edit' onClick={()=>dispatch(setDialogBox("editReservation"))}>
                           <i className="bi bi-pencil-square edit-icon1"></i>
                            Edit Reservation
                        </button>
                        <button className='add' onClick={ReservationClick("add")}>
                            <AddShoppingCartIcon/>
                            Add Orders
                        </button>
                    </div>}
                </div>
            </div>
            <Dialog 
            open={dialogOpen === "addReservation"||dialogOpen==="editReservation"} 
            onClose={()=>dispatch(setDialogBox(null))} 
            maxWidth="fit-content">
                <AddReservationPopup />
            </Dialog>
        </div>
    )
}

export default ReservationBilling