import axios from "axios"
import { HEADERS } from "../../../API/UrlAndPaths"
import store from "../../../Redux/store"
import { setSubmitLoading } from "../../../Redux/Dashboard/mainDashBoardSlice"
import { errorSnackMsg, successSnackMsg } from "../../../component/Custom Hooks/SnackkBarUtilities"
import { setCatSubList, setSelfCustomerTopbar, setSelfOrderScreen, setSubCatChildList } from "./selfOrderEssentialsSlice"
import { get_products_list_for_billing, setCartProductType } from "../../../component/Modules/Sales/Billing/newBillingSlice"
import { singleSelfOrderInfoAPICall } from "../../API/selfOrderAPIS"
import { getInvoiceReceipt } from "../../../component/Modules/Sales/Billing/API/billingPaymentSection"
import { setSelfCompletedOrdersList } from "../../Slices/selfOrderMainSlice"

export const initialSubCatFilterAPI=async(body)=>{
    try{
        store.dispatch(setSubmitLoading(true))
        const response=await axios.post("readymade/categoryfilter",body,HEADERS)

        if(response.status===200){
            store.dispatch(body?.parentCategories?setSubCatChildList(response.data):setCatSubList(response.data))
        }
    } catch(error){
        store.dispatch(body?.parentCategories?setSubCatChildList([]):setCatSubList([]))
        errorSnackMsg(error.message)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}
export const comboOffersProductsListForSelfOrder=async(body,succcesFn)=>{

    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/viewSpecialOfferCombos",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
                store.dispatch(setSubmitLoading(false))
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                succcesFn()
                store.dispatch(setCartProductType(3))
            }
        })
        .catch((err)=>{
            
            store.dispatch(setSubmitLoading(false))
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
            errorSnackMsg(err.message)
        })
}

export const specialOfferProductsListForSelf=async(body,succcesFn)=>{
    store.dispatch(setSubmitLoading(true))
    await axios.post("pos/viewSpecialOfferById",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                succcesFn()
                store.dispatch(get_products_list_for_billing({billingProductsList:res.data}))
                store.dispatch(setCartProductType(2))
                store.dispatch(setSubmitLoading(false))
            }
        })
        .catch((err)=>{
            store.dispatch(setSubmitLoading(false))
            errorSnackMsg(err.response.data)
            store.dispatch(get_products_list_for_billing({billingProductsList:[]}))
        })
}


export const selfAddOrderAPI=async(body)=>{

    let endPoint=body?.ordType==="TAKEAWAY"?"pos/addPosOrder":"pos/addSelfOrderFromTable"
    store.dispatch(setSubmitLoading(true))
    try{
        const response=await axios.post(endPoint,body,HEADERS)

        if(response.status===200){
            singleSelfOrderInfoAPICall({id:response?.data?.data?._id })
            getInvoiceReceipt({orderId:response?.data?.data?._id})

            successSnackMsg("Order added successfully")
            store.dispatch(setSelfOrderScreen("orderConfirmScreen"))
            localStorage.setItem("selfOrderId",response?.data?.data?._id)
            store.dispatch(setSelfCustomerTopbar(false))
        }
    }
    catch(error){
        errorSnackMsg(error.response.data || error.message)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}

export const selfUpdateOrderAPI=async(body)=>{
    store.dispatch(setSubmitLoading(true))
    try{
        const response=await axios.post("pos/addItemsToExistingOrders",body,HEADERS)
        if(response.status===200){
            singleSelfOrderInfoAPICall({id:response?.data?.data?._id })
            getInvoiceReceipt({orderId:response?.data?.data?._id})
            successSnackMsg("Order updated successfully")
            store.dispatch(setSelfOrderScreen("orderConfirmScreen"))
        }
    }
    catch(error){
        errorSnackMsg(error.response.data || error.message)
    }
    finally{
        store.dispatch(setSubmitLoading(false))
    }
}


export const completedSelfOrderLists=async(body,autoOpen)=>{
    const list=store.getState().selfOrderMainSlice?.completedOrders
    try{
        const response=await axios.post("pos/listCompletedSelfOrder",body,HEADERS)
        if(response.status===200){
            store.dispatch(setSelfCompletedOrdersList(response.data))
            response.data?.length>list.length && autoOpen?.()
        }
    }
    catch(error){
        store.dispatch(setSelfCompletedOrdersList([]))
        console.log(error?.message)
    }
}