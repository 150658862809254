import React, { useEffect, useState } from "react";
import {
  listPosNotesApiCall,
  deleteNoteApiCall,
} from "../API/viewOrderSection";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../../css/Sales/ListNotes.css";

function ListNotes({ setInput,input }) {
  const userLoginMeta = localStorage.getItem("login_meta");
  const userBranchId = JSON.parse(userLoginMeta)?.branchPk;
  const listNotes = useSelector(
    (state) => state.viewOrdersBillingSlice.posNotesList
  );

  const [filteredNotes, setFilteredNotes] = useState(listNotes);

  const viewNotes = () => {
    listPosNotesApiCall({ branchId: userBranchId });
  };
  const selectNote = (value) => () => {
    setInput(value);
  };

  const handleDelete=(note)=>(e)=>{
    e.stopPropagation(); // Prevent the note's onClick event from firing
    deleteNoteApiCall({ noteId: note._id }, viewNotes);
    if (input === note.notes) {
      setInput(''); // Clear the input field if the deleted note is currently selected
    }
  }

  useEffect(() => {
    viewNotes();
  }, [userBranchId]);

    // Filter notes based on input
    useEffect(() => {
      if (input.trim()) {
        const filtered = listNotes?.filter((note) =>
          note.notes.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredNotes(filtered);
      } else {
        setFilteredNotes(listNotes); // Show all notes if input is empty
      }
    }, [input, listNotes]);

   
  

  return (
    <div className="list-notes-ctr">
      {" "}
      {filteredNotes?.map((note) => (
        <div key={note._id} onClick={selectNote(note.notes)} className="items">
          <span>{note.notes}</span>
          <CloseIcon
            className="cross-icon"
            onClick={handleDelete(note)}
          />
        </div>
      ))}
    </div>
  );
}

export default ListNotes;
