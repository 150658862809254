import React, { useRef, useState } from 'react'
import TableRestaurantTwoToneIcon from "@mui/icons-material/TableRestaurantTwoTone";
import MenuButton from '../Buttons/MenuButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { IconButton, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchInputSelf, setSelfCustomerTopbar, setSelfOrderScreen } from '../Order/selfOrderEssentialsSlice';
import PasswordDialog from '../Dialog/PasswordDialog';
const SelfOrderTopBar = () => {

    const dispatch=useDispatch();
    const decimalPosition=localStorage.getItem('decimalPosition');
    const isMobile = useMediaQuery('(max-width:600px)');
    const selfUser = localStorage.getItem('selfUser');

    const inputRef = useRef(null);

    const {selfTableNames,orderScreen,ordType,selfCustomer,searchBarInput}=useSelector((state)=>state.selfOrderEssentialsSlice)
    const cartCount=useSelector((state)=>state.selfOrderCartSlice.cartArray?.length)
    const {totalsInfo}=useSelector((state)=>state.selfOrderCartSlice)

    const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)

    const [iconClick, setIconClick] = useState({search:false,cart:false})
    const [hideContents, setHideContents] = useState(false)
    const [passwordDialog, setPasswordDialog] = useState(false)

    const searchIconOnClick = () => {
        setIconClick((prevState) => {
            const newState = { ...prevState, search: !prevState.search,cart:false };
            if (newState.search) {
              setTimeout(() => {
                inputRef.current?.focus(); 
              }, 0);
            }
            return newState;
          });

        if (isMobile) {
            setHideContents(!iconClick?.search);
        }
    };
    const cartIconOnClick = () => {
        setIconClick({ ...iconClick, cart: !iconClick.cart })
        if (isMobile) {
            setHideContents(!iconClick?.cart);
        }
    };
   
    const customerOnclick=()=>{
        dispatch(setSelfCustomerTopbar(true))
        dispatch(setSelfOrderScreen("loginScreen"))
    }
    const tableOnClick = () => {
        if(ordType==="DINEIN"){
            (selfUser==="STAFF")?dispatch(setSelfOrderScreen("orderFloorPlan")):setPasswordDialog(true)
        }
    }

    const authSuccessFn=()=>{
        dispatch(setSelfOrderScreen("orderFloorPlan"))
    }
    const disableAfterCompletion=(orderScreen==="orderFeedbackScreen")
    return (
        <div className='self-topbar-ctn'>
            <MenuButton />

            {
                <div className="customer-ctn justify-center" onClick={!disableAfterCompletion ? customerOnclick : undefined}>
                    <IconButton>
                        <PersonOutlineIcon style={{ border: "2px solid #fff", color: "#fff", borderRadius: "50%" }} className='responsive-arrow-icon' />
                    </IconButton>
                    <h4 className='text-over-flow '>{selfCustomer?.name || null}</h4>
                </div>}
            {
                !(hideContents) &&
                <div className="table-ctn justify-center" onClick={!disableAfterCompletion ? tableOnClick : undefined}>
                   {
                    ordType==="DINEIN"?
                   <>
                   <TableRestaurantTwoToneIcon className='responsive-arrow-icon' style={{ color: "#e11503" }} />
                    <p>: {selfTableNames?.join(",")}</p>
                   </>:
                   <p style={{color: "#e11503",fontWeight:"600"}}>T/A</p>
                   }
                </div>}

            <div className="search-ctn justify-center">
                <SearchIcon
                    onClick={searchIconOnClick}
                    className="responsive-arrow-icon"
                    style={{ color: "#e11503" }}
                />
                {(!isMobile || iconClick?.search) && (
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchBarInput}
                        onBlur={searchIconOnClick}
                        placeholder='Search'
                        onChange={(e)=>dispatch(setSearchInputSelf(e.target.value))}
                    />
                )}

               {
               (isMobile && searchBarInput) &&
               <span className="self-order-active-span"></span>}
            </div>

            <div 
            className="search-ctn cart-ctn justify-center" 
            onClick={!disableAfterCompletion ? ()=>dispatch(setSelfOrderScreen("cartSection")) : undefined}>
                <ShoppingCartOutlinedIcon
                    onClick={cartIconOnClick}
                    className="responsive-arrow-icon"
                    style={{ color: "#e11503" }}
                    />
                {(!isMobile || iconClick?.cart) && (
                    <p>{(totalsInfo?.amtTotal || 0)?.toFixed(decimalPosition)} {currencyDetails?.symbol || ""}</p>
                )}
                {
                (cartCount>0) &&
                <span className="self-order-active-span">{cartCount}</span>}
            </div>


            <PasswordDialog open={passwordDialog} handleClose={()=>setPasswordDialog(false)} authSuccessFn={authSuccessFn}/>
        </div>
    )
}

export default SelfOrderTopBar