import img1 from "../../../Assets/Images/AI Invoice-04.jpg";
import img2 from "../../../Assets/Images/AI Invoice-02.jpg";
import img3 from "../../../Assets/Images/AI-Invoice-Template-03.png";
import receiptPrintModel1 from "../../../Assets/Images/receiptPrintModel1.png";
import receiptPrintModel2 from "../../../Assets/Images/receiptPrintModel2.png";
import React, { useEffect, useState } from "react";
import { templateSetAPI } from "./SettingsNewAPI";
import { useSelector } from "react-redux";
import { getTemplateAPI } from "../SalesNew/SalesOrders/SalesQuotation/salesQuatationAPI";
import { Radio, FormControlLabel, RadioGroup, Box } from "@mui/material";

const tabs = {
  INVOICE: "invoice",
  RECEIPT: "receipt",
};

const templates = [
  {
    id: "template1",
    name: "Template 1",
    imgSrc: img1,
    description: "This is template 1",
    tab: tabs.INVOICE,
  },
  {
    id: "template2",
    name: "Template 2",
    imgSrc: img2,
    description: "This is template 2",
    tab: tabs.INVOICE,
  },
  {
    id: "template3",
    name: "Template 3",
    imgSrc: img3,
    description: "This is template 3",
    tab: tabs.INVOICE,
  },
  {
    id: "template1",
    name: "Template 1",
    imgSrc: receiptPrintModel1,
    description: "This is template 1",
    tab: tabs.RECEIPT,
  },
  {
    id: "template2",
    name: "Template 2",
    imgSrc: receiptPrintModel2,
    description: "This is template 1",
    tab: tabs.RECEIPT,
  },
];

export const TemplateModel = () => {
  const [selectedTemplates, setSelectedTemplates] = useState({
    invoice: null,
    receipt: null,
  });

  const handleTemplateChange = (template, tabName) => {
    setSelectedTemplates((prev) => ({ ...prev, [tabName]: template }));
    const templateKeyMap = {
      [tabs.INVOICE]: "templateModel",
      [tabs.RECEIPT]: "shiftReportReceiptTemplate",
    };

    const key = templateKeyMap[tabName];
    if (key) {
      templateSetAPI({ [key]: template.id });
    }
  };

  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );

  console.log("template redux", template);

  useEffect(() => {
    if (template) {
      setSelectedTemplates((prev) => ({
        ...prev,
        invoice: { id: template.templateModel },
        receipt: { id: template.shiftReportReceiptTemplate },
      }));
    } else {
      getTemplateAPI();
    }
  }, [template]);

  return (
    <div className="settings-template-container">
      <div className="settings-tab-switch">
        <TabComponent
          selectedTemplates={selectedTemplates}
          onTemplateChange={handleTemplateChange}
        />
      </div>
    </div>
  );
};

export const TabComponent = ({ selectedTemplates, onTemplateChange }) => {
  const [activeTab, setActiveTab] = useState("invoice");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="settings-new-tab-switch-container">
      <div className="settings-new-tab-switch-header">
        <div
          className={`settings-new-tab-switch-item ${
            activeTab === "invoice" ? "active" : ""
          }`}
          onClick={() => handleTabClick("invoice")}
        >
          Invoice Print
        </div>
        <div
          className={`settings-new-tab-switch-item ${
            activeTab === "receipt" ? "active" : ""
          }`}
          onClick={() => handleTabClick("receipt")}
        >
          Receipt Print
        </div>
      </div>

      <div className="settings-new-tab-switch-body">
        {activeTab === "invoice" && (
          <div className="settings-new-tab-switch-content">
            <TemplateSelector
              activeTab="invoice"
              selectedTemplate={selectedTemplates.invoice}
              onTemplateChange={onTemplateChange}
            />
          </div>
        )}
        {activeTab === "receipt" && (
          <div className="settings-new-tab-switch-content">
            <TemplateSelector
              activeTab="receipt"
              selectedTemplate={selectedTemplates.receipt}
              onTemplateChange={onTemplateChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const TemplateSelector = ({
  activeTab,
  selectedTemplate,
  onTemplateChange,
}) => {
  // Filter templates based on the active tab
  const filteredTemplates = templates.filter(
    (template) => template.tab === activeTab
  );

  const handleTemplateChange = (event) => {
    const selectedId = event.target.value;
    console.log("selected id ", selectedId);
    const selectedTemplate = filteredTemplates.find(
      (template) => template.id === selectedId
    );
    console.log("Selected template ", selectedTemplate);
    onTemplateChange(selectedTemplate, activeTab);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <RadioGroup
        row
        value={selectedTemplate?.id || ""}
        onChange={handleTemplateChange}
        sx={{ justifyContent: "", marginTop: 2 }}
      >
        {filteredTemplates.map((template, i) => (
          <Box
            key={template.id}
            sx={{ marginX: 2, textAlign: "center" }}
            className="setting-tab-layout-item"
          >
            <FormControlLabel
              value={template.id}
              control={<Radio />}
              label={template.name}
            />
            <Box
              component="img"
              src={`${template.imgSrc}?=${i}`}
              alt={template.name}
              sx={{
                width: activeTab == tabs.INVOICE ? 200 : 150,
                height: activeTab == tabs.INVOICE ? 300 : 500,
                borderRadius: 2,
                border: "1px solid #ddd",
                marginTop: 1,
              }}
            />
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};
