import { createSlice } from "@reduxjs/toolkit";

export const selfOrderEssentialsSlice=createSlice({
    name:"selfOrderEssentialsSlice",
    initialState:{
        ordType:null,
        orderScreen:null,
        mainCat:null,
        subCat:null,
        catSubList:[],
        subCatChildList:[],
        specialOfferDialog:false,
        selfTableIds:[],
        selfTableNames:[],
        selfChairIds:[],
        selfCustomer:null,
        selfMobileNo:null,
        customerTopbar:false
    },
    reducers:{
      setSelfOrderType:(state,action)=>{
        state.ordType=action.payload;
      },
      setSelfOrderScreen:(state,action)=>{
        state.orderScreen=action.payload;
      },
      setSelfTableIds:(state,action)=>{
        state.selfTableIds=action.payload;
      },
      setSelfChairIds:(state,action)=>{
        state.selfChairIds=action.payload;
      },
      setSelfTableNames:(state,action)=>{
        state.selfTableNames=action.payload;
      },
      setSelfMainCat:(state,action)=>{
        state.mainCat=action.payload;
      },
      setSearchInputSelf:(state,action)=>{
        state.searchBarInput=action.payload;
      },
      setSelfSubCat:(state,action)=>{
        state.subCat=action.payload;
      },
      setCatSubList:(state,action)=>{
        state.catSubList=action.payload;
      },
      setSubCatChildList:(state,action)=>{
        state.subCatChildList=action.payload;
      },
      setSpecialOfferDialog:(state,action)=>{
        state.specialOfferDialog=action.payload;
      },
      setSelfCustomer:(state,action)=>{
        state.selfCustomer=action.payload;
      },
      setSelfMobileNo:(state,action)=>{
        state.selfMobileNo=action.payload;
      },
      setSelfCustomerTopbar:(state,action)=>{
        state.customerTopbar=action.payload;
      },
    }
})

export const {
    setSelfOrderType,setSelfMainCat,setSelfSubCat,setSelfOrderScreen,setSelfCustomerTopbar,
    setCatSubList,setSubCatChildList,setSpecialOfferDialog,setSelfTableNames,
    setSelfChairIds,setSelfTableIds,setSelfMobileNo,setSelfCustomer,setSearchInputSelf
}=selfOrderEssentialsSlice.actions

export default selfOrderEssentialsSlice.reducer