import { setSelfCartArray } from "./Slices/selfOrderCartSlice";
import store from "../Redux/store";
import { setSelfChairIds, setSelfCustomer, setSelfMobileNo, setSelfOrderScreen, setSelfOrderType, setSelfTableIds, setSelfTableNames } from "./Components/Order/selfOrderEssentialsSlice";
import {  setScreenSelected, setSelfSingleOrderInfo } from "./Slices/selfOrderMainSlice";

  export const selfOrderClear=()=>{
      store.dispatch(setSelfOrderType(null))
      store.dispatch(setSelfOrderScreen(null))
      store.dispatch(setScreenSelected(null))
      store.dispatch(setSelfChairIds([]))
      store.dispatch(setSelfTableIds([]))
      store.dispatch(setSelfTableNames([]))
      store.dispatch(setSelfCartArray([]))
      store.dispatch(setSelfSingleOrderInfo(null))
      store.dispatch(setSelfCustomer(null))
      store.dispatch(setSelfMobileNo(null))
      // localStorage.removeItem("selfOrderId")
  }