import { createSlice } from "@reduxjs/toolkit";


export const customKeyBoardSlice=createSlice({
    name:"customKeyBoardSlice",
    initialState:{
        keyBoardVisible:null,
        keyBoardOnClose:false
    },
    reducers:{
        setVisibleKeyboard:(state,action)=>{
            state.keyBoardVisible=action.payload
        },
        setKeyBoardOnClose:(state,action)=>{
            state.keyBoardOnClose=action.payload
        }
    }
})

export const {setVisibleKeyboard,setKeyBoardOnClose}=customKeyBoardSlice.actions

export default customKeyBoardSlice.reducer
