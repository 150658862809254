import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import { Autocomplete, Dialog, IconButton, TextField, Tooltip } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
import { setBtnSelected, setCustomerWallet } from './topBarSliceBilling';
import "./billingPayment.css"
import Calculator from '../../../../Single Components/Calculator';
import CloseIcon from '@mui/icons-material/Close';
import { getCurrentTime, getTimeDifference, today } from '../../../../../Js/Date';
import { setDialogBox, setErrorSnack, setOrderIdInPOS, setSnackMsg } from '../Slice/billingScreenSlice';
import { finalPaymentPOSApiCall, refundPaymentApiCall, refundUpdatePaymentApiCall } from '../API/billingPaymentSection';
import { listHeldOrders, listOrdersForBillingAPI } from '../API/viewOrderSection';
import { setInputCalculator } from '../Slice/calculatorSlice';
import walletIcon from "../image/wallet (5).png"
import Swal from 'sweetalert2';
import paymentEmpty from "../image/paytype-01.png"
import UpdateOrderAuth from './UpdateOrderAuth';
import { errorSnackMsg } from '../../../../Custom Hooks/SnackkBarUtilities';

function BillingPayment() {

  const dispatch=useDispatch()

  const decimalPosition=localStorage.getItem("decimalPosition")


  // redux states
  const { customer,defaultCustomer,customerWallet} = useSelector((state) => state.topBarSliceBilling)
  const { singleOrderInfo,orderIdForPos,branchId,dialogOpen} = useSelector((state) => state.billingScreenSlice);
  const {currencyDetails}=useSelector((state)=>state.mainDashBoardSlice)

  const {floors} = useSelector((state) => state.addSeatSlice);
  const {inputValueCalculator} = useSelector((state) =>state.calculatorSlice);

  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const shiftStatus = useSelector((state) => state.shiftStatusSlice.value);
  const posSettings=useSelector((state)=>state.posSettingSlice.apiResList)
  
  const paymentTypesForBranch=useSelector((state)=>state.newBillingSlice.paymentTypeList)
  const { authPassWord,branchConfigurationList } = useSelector((state) => state.newBillingSlice);

  const cardManual = useSelector((state) => state.cardManualSlice.value);
  
const walletStatus=posSettings?.activeWalletStatus
const activeWalletJournel=posSettings?.activeWallet?.activeWalletJournel

// useStates
 const paymentFormInitialStates={
    deliveryCharge:0.00,
    pkgCharge:0.00,
    rewardPoints:"",
    discount:0,
    amountTotal:null,
    paidAmount:null,
    wallet:"",
    credit:"",
    totalAmt:0,
    totalExcl:0,
    totalIncl:0,
    change:0,
    balance:0,
    changeInWallet:null
  }
  const [paymentForm,setPaymentForm]=useState(paymentFormInitialStates)

  const [paymentsTypeArray,setPaymentsTypeArray]=useState([])
  const [paymentSelectedArray,setPaymentSelectedArray]=useState([])
  const [initialRefresh,setInitialRefresh]=useState(true)
  const [selectedPaymentRow,setSelectedPaymentRow]=useState(null)
  const paymentFormOnChange=(key,value)=>{
    const validInput = value.replace(/[^0-9.]/g, '');
    setPaymentForm((prevForm) => {
        let amountTotal = singleOrderInfo?.totalExclusive+singleOrderInfo?.subTotal;
        if (key === "deliveryCharge") {
            amountTotal = (amountTotal + Number(validInput) + Number(prevForm.pkgCharge)) - Number(prevForm.discount);
        } else if (key === "pkgCharge") {
            amountTotal = (amountTotal + Number(validInput) + Number(prevForm.deliveryCharge)) - Number(prevForm.discount);
        } else if (key === "discount") {
            amountTotal = (amountTotal - Number(validInput)) + (Number(prevForm.deliveryCharge) + Number(prevForm.pkgCharge));
        }

        if(amountTotal<0){
            errorSnackMsg("Total amount is less than zero!!")
            return prevForm
        }else{
            return {
                ...prevForm,
                [key]: Number(validInput),
                amountTotal
            };

        }
    });
    
  }

    const cusWalletObjInitial = {
        journalId: activeWalletJournel,
        journalName: "Customer Wallet",
        type: "wallet",
        _id: null,
        walletBalance: 0
    }
    const [cusWalletObj,setCusWalletObj]=useState(cusWalletObjInitial)

  // adding payment type in array
  const paymentTypeClick=(obj)=>()=>{
        let paidAmount=0
        if(obj.type==="wallet"){
                paidAmount=(Number(paymentForm.balance)>Number(obj.walletBalance))?(obj?.walletBalance):paymentForm.balance
                let walletBal=cusWalletObj.walletBalance-Number(paidAmount)   
                setCusWalletObj({...cusWalletObj,walletBalance:walletBal})
            
        }else{
            paidAmount=paymentForm.balance
        }
        if(obj.type==="wallet" && paymentSelectedArray.some(obj=>obj.type==="wallet")){
            dispatch(setSnackMsg("Wallet payment type already exist!!"))
            dispatch(setErrorSnack(true))
        }else{
            const updatedObj={...obj,paidAmt:Number(paidAmount)?.toFixed(decimalPosition) || 0}
            setPaymentSelectedArray([...paymentSelectedArray,updatedObj])
        }

  }

// card type selecting
  const paymentCardOnchange=(key)=>(e,newValue)=>{
    if(newValue===null){
            // const newArray=paymentSelectedArray?.filter(obj=>obj.journalId !== key?.journalId)
            // setPaymentSelectedArray(newArray)
    }else{
        setPaymentSelectedArray((prevArray) => {
            // const newArray = prevArray?.filter(obj => obj._id !== newValue?._id);
            const updatedValue = { ...newValue,
                 type: "card",
                 paidAmt:(Number(paymentForm.balance)?.toFixed(decimalPosition) || 0),
                 journalId:key?.journalId
                };
            return [...prevArray, updatedValue];
          });
    }
  }

// deleting selected payment type array
const removePaymentType=(index)=>()=>{
    let newArray = [...paymentSelectedArray];
    newArray.splice(index, 1); 
  
    setPaymentSelectedArray(newArray); 
}

const paidAmountChange=(index)=>(e)=>{
    let validInput = e.target.value.replace(/[^0-9.]/g, '');
    const updatedData = paymentSelectedArray.map((r, i) => {
        if (i === index) {
            if(r.type==="wallet" && Number(validInput)>customerWallet?.walletBalance){
                dispatch(setSnackMsg(`available customer wallet amount is ${customerWallet?.walletBalance}`))
                dispatch(setErrorSnack(true))
                validInput=customerWallet?.walletBalance
            }
            return { ...r, paidAmt: validInput};
        }else{
            return r
        }
    })
    

    setPaymentSelectedArray(updatedData); 
}

const approvalNoOnChange=(index)=>(e)=>{
    const updatedData = paymentSelectedArray.map((r, i) => {
        if (i === index) {
            return { ...r, approvalCode: e.target.value};
        }else{
            return r
        }
    })
    setPaymentSelectedArray(updatedData); 
}

const amountAndWalletChange=(key)=>(e)=>{
    if(key==="wallet"){
        let changeAmt=Math.max(Number(paymentForm.change)-Number(e.target.value),0)
        setPaymentForm({...paymentForm,wallet:e.target.value,changeInWallet:changeAmt?.toFixed(decimalPosition)})
    }
}

// calculate change and balance
const calculateCHangeAndBal=()=>{

    let paidAmt=Number(paymentForm.paidAmount)?.toFixed(decimalPosition)
    let totalAmount=Number(paymentForm.amountTotal)?.toFixed(decimalPosition)

    let changeAmt=Math.max(paidAmt-totalAmount,0)
    let balanceAmt=Math.max(totalAmount-paidAmt,0)
    setPaymentForm({...paymentForm,
        change:changeAmt?.toFixed(decimalPosition),
        balance:balanceAmt?.toFixed(decimalPosition)
    })
}

const clearPaymetArrayFunction=()=>{
    let filteredArray=paymentSelectedArray.filter((obj)=>(!obj.change===true))
    setPaymentSelectedArray(filteredArray)
}
const finalPlaceOrder=()=>{
    const paymentMethodBody=paymentSelectedArray?.map((item)=>(
        {
            type: item?.type,
            journalId: item?.journalId || null,
            paidAmt:Number(item?.paidAmt),
            cardId:(cardManual!==undefined && item?.type==="card")?(item?._id|| null):null,
            approvalCode:item?.approvalCode===""?undefined:item?.approvalCode
        }
    ))
    let payload={
        orderId: orderIdForPos,
        branchId: userInfo?.branchPk,
        discountAmt:(paymentForm?.discount==="" || paymentForm?.discount===null || paymentForm?.discount==="NaN")
                        ?0:Number(paymentForm?.discount),
        deliveryCharge:(paymentForm?.deliveryCharge==="" ||paymentForm?.deliveryCharge===null || paymentForm?.deliveryCharge==="NaN")
                        ?0:Number(paymentForm?.deliveryCharge),
        pkgCharge:(paymentForm?.pkgCharge==="" ||paymentForm?.pkgCharge===null || paymentForm?.pkgCharge==="NaN")
                        ?0:Number(paymentForm?.pkgCharge),
        amtTotal:paymentForm?.amountTotal===""?0:Number(paymentForm?.amountTotal?.toFixed(decimalPosition)),
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentMethod:paymentMethodBody,
        cusId:customer?._id || defaultCustomer?._id,
        ordDuration:getTimeDifference(singleOrderInfo?.orderDate),
        password:authPassWord
    }
    
    let clearFn=()=>{

        if(floors.length!==0){
            dispatch(setBtnSelected("floorPlan"))                
        }else{
            dispatch(setBtnSelected(null))                
        }
        dispatch(setDialogBox(null))
        dispatch(setCustomerWallet(null))
    }

    if((branchConfigurationList?.addDiscountAuth && payload?.discountAmt>0) && !(dialogOpen==="discountAuthPassword")){
        dispatch(setDialogBox("discountAuthPassword"))
    }else{
        finalPaymentPOSApiCall(payload,clearFn,clearPaymetArrayFunction)
    }
}


const finalPlaceOrderValidations=()=>{

    let changeAmt=Number(paymentForm.change)
    if(orderIdForPos===null){
        dispatch(setSnackMsg("Order Not Found !!"))
        dispatch(setErrorSnack(true))
    }else if(shiftStatus===null){
        dispatch(setSnackMsg("Shift status not available !!"))
        dispatch(setErrorSnack(true))
    }else if(Number(paymentForm.balance)>0){
        if(customer===null){
            dispatch(setSnackMsg("Credit not available !!"))
            dispatch(setErrorSnack(true))
        }else{
            Swal.fire({
                text:"Balance amount will be added to customer credit!!",
                icon: 'warning',
                    showCancelButton: true,
                   confirmButtonColor: '#f80000',
                   confirmButtonText: 'Pay',
                   customClass:{
                     cancelButton:'sweet-alert-cancel-button',
                     confirmButton:'sweet-alert-submit-button',
                   }
              }).then((result)=>{
                if(result.isConfirmed){
                  finalPlaceOrder()
                }
             })
        }
    }else if(paymentSelectedArray.length===0){
        dispatch(setSnackMsg("Payment Method Not Selected !!"))
        dispatch(setErrorSnack(true))
    }
    else if(changeAmt>0){
        if(customer!==null &&walletStatus){
            setPaymentForm({...paymentForm,changeInWallet:paymentForm.change,wallet:""})
            dispatch(setDialogBox("walletChange"))
        }else{
            const cashObj = paymentSelectedArray.find(obj => obj.type === "cash");

            if (cashObj) {
                const modifiedCashObj = { ...cashObj,type:"change", paidAmt: -(changeAmt), change: true };
                // Add the modified object back to the array
                paymentSelectedArray.push(modifiedCashObj);
                finalPlaceOrder()
              }
              else{
                dispatch(setSnackMsg("Change not cleared !!"))
                dispatch(setErrorSnack(true))
              }
        }
    }
    else{
        finalPlaceOrder()
    }
}




const payFromWallet=()=>{

    let changeAmt=Number(paymentForm.changeInWallet)
    let wallet=Number(paymentForm.wallet)
    const cashObj = paymentSelectedArray.find(obj => obj.type === "cash");

    if(changeAmt>0){

        if (cashObj) {
            const modifiedCashObj = { ...cashObj,type:"change", paidAmt: -(changeAmt), change: true };
            paymentSelectedArray.push(modifiedCashObj);
          }
          else{
            dispatch(setSnackMsg("Change not cleared !!"))
          }
    }

    if(wallet>0){

        const walletObj= {
            type: "wallet",
            journalId: activeWalletJournel,
            paidAmt:-(wallet),
            cardId:null,
            change:true
        }

        paymentSelectedArray.push(walletObj);

    }

    if(changeAmt>0 && cashObj===undefined){
        dispatch(setSnackMsg("Change can only be cleared in cash payment !!"))
        dispatch(setErrorSnack(true))
        walletCancelPopuo()
    }else if(activeWalletJournel===undefined || activeWalletJournel===null){
        dispatch(setSnackMsg("Wallet journal can't found!!"))
        dispatch(setErrorSnack(true))
        walletCancelPopuo()
    }else{
        finalPlaceOrder()
    }
}

const walletCancelPopuo=()=>{
    let walletRemovedArray=paymentSelectedArray.filter((item)=>!(item?.type==="wallet"))
    setPaymentSelectedArray(walletRemovedArray)
    dispatch(setDialogBox(null))
}
const refundOrderPayment=()=>{
    const paymentMethodBody=paymentSelectedArray?.map((item)=>(
        {
            type: item?.type,
            journalId: item?.journalId || null,
            paidAmt:-(Number(item?.paidAmt)),
            cardId:(cardManual!==undefined && item?.type==="card")?(item?._id|| null):null,
            approvalCode:item?.approvalCode===""?undefined:item?.approvalCode
        }
    ))
    let refundPayload={
        mainOrdId:singleOrderInfo?.mainOrdId,
        branchId:singleOrderInfo?.branchId,
        orderInfo:singleOrderInfo?.orderInfo,
        cusId:singleOrderInfo?.cusId,
        subTotal: -Number(singleOrderInfo?.subTotal?.toFixed(decimalPosition)),
        discountAmt: Number(paymentForm.discount),
        totalExclusive: -Number(paymentForm?.totalExcl?.toFixed(decimalPosition)),
        totalInclusive: Number(paymentForm?.totalIncl?.toFixed(decimalPosition)),
        amtTotal: -Number(paymentForm?.amountTotal?.toFixed(decimalPosition)),
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentMethod:paymentMethodBody
    }
    let refundUpdatePayload = {
        orderId: singleOrderInfo?.mainOrdId,
        branchId: singleOrderInfo?.branchId,
        paymentDate: `${today} ${getCurrentTime()}`,
        paymentMethod:paymentMethodBody
    }
    if(orderIdForPos===null){
        dispatch(setSnackMsg("Order Not Found !!"))
        dispatch(setErrorSnack(true))
    }else if(shiftStatus===null){
        dispatch(setSnackMsg("Shift status not available !!"))
        dispatch(setErrorSnack(true))
    } else if (Number(paymentForm.balance) > 0) {
        dispatch(setSnackMsg("balance amount pending !!"))
        dispatch(setErrorSnack(true))
    } else if (paymentSelectedArray.length === 0) {
        dispatch(setSnackMsg("Payment Method Not Selected !!"))
        dispatch(setErrorSnack(true))
    }
    else{
        let clearFn=()=>{
            listOrdersForBillingAPI({branchId,type:2})
        }
        if(singleOrderInfo?.status==="RETURN"){
            refundUpdatePaymentApiCall(refundUpdatePayload,clearFn)
        }else{
            refundPaymentApiCall(refundPayload,clearFn)
        }
    }
}

const handleMouseLeave = () => {

    const deliveryChargeAmt = typeof paymentForm?.deliveryCharge === 'number' ? paymentForm?.deliveryCharge : parseFloat(paymentForm?.deliveryCharge);
    const pkgChargeAmt = typeof paymentForm?.pkgCharge === 'number' ? paymentForm?.pkgCharge : parseFloat(paymentForm?.pkgCharge);
    const discountAmount = typeof paymentForm?.discount === 'number' ? paymentForm?.discount : parseFloat(paymentForm?.discount);


    setPaymentForm({...paymentForm,
                deliveryCharge:deliveryChargeAmt?.toFixed(decimalPosition),
                pkgCharge:pkgChargeAmt?.toFixed(decimalPosition),
            discount:discountAmount?.toFixed(decimalPosition)
        })
    }

    const paymentRowClick=(index)=>()=>{
        setSelectedPaymentRow(index===selectedPaymentRow?null:index)
       dispatch(setInputCalculator(""))
      }
    
const handleMouseLeavePayment = () => {

    let roundedArray = paymentSelectedArray.map((item) => 
        {
            const paidAmtNumber = typeof item.paidAmt === 'number' ? item.paidAmt : parseFloat(item.paidAmt);
            return {
                ...item,
                paidAmt: paidAmtNumber.toFixed(decimalPosition),
            };
        }
    )   
    // setInitialRefresh(true)
    setPaymentSelectedArray(roundedArray)
}



  useEffect(()=>{
    if(posSettings?.posStatus){
        setPaymentsTypeArray(paymentTypesForBranch)
    }else{
      let paymentArray=[
        {
          journalName:"Cash",
          type:"cash"
        },
        {
          journalName:"Card",
          type:"card"
        },
        {
          journalName:"Cheque",
          type:"cheque"
        },
      ]
      setPaymentsTypeArray(paymentArray)
    }
  },[paymentTypesForBranch,posSettings])

// to ge the sum of selected payment methods input
 useEffect(()=>{
    if(!(paymentSelectedArray.some(obj=>obj.type==="wallet"))){
        setCusWalletObj({...cusWalletObj,walletBalance:customerWallet?.walletBalance || 0})
    }
    if(paymentSelectedArray.length===0 && initialRefresh===false ){
        setPaymentForm({...paymentForm,paidAmount:0})
        setCusWalletObj({...cusWalletObj,walletBalance:customerWallet?.walletBalance || 0})
    }else{
        let total=paymentSelectedArray?.reduce((sum,item)=>sum+Number(item?.paidAmt),0)
        setPaymentForm({...paymentForm,paidAmount:total})
    }
 },[paymentSelectedArray])

 useEffect(() => {
    initialRefresh===false && calculateCHangeAndBal()
    // setInitialRefresh(false)
    
  }, [paymentForm.paidAmount, paymentForm.amountTotal]);


  useEffect(()=>{
    if(singleOrderInfo!==null){
        let amtTotal=singleOrderInfo?.totalExclusive+singleOrderInfo?.subTotal;
        let orderTotal=singleOrderInfo?.amountDue>0?singleOrderInfo?.amountDue: amtTotal-(singleOrderInfo?.discountAmt || 0)
        setInitialRefresh(false)
        setPaymentForm({...paymentForm,
            paidAmount:0,
            amountTotal:orderTotal,
            totalExcl:singleOrderInfo?.totalExclusive,
            totalIncl:singleOrderInfo?.totalInclusive,
            discount:singleOrderInfo?.discountAmt?.toFixed(decimalPosition),
            change:0,
            balance:orderTotal,
            amountPaid:(amtTotal-singleOrderInfo?.amountDue)?.toFixed(decimalPosition),
            deliveryCharge:singleOrderInfo?.deliveryCharge?.toFixed(decimalPosition),
            pkgCharge:singleOrderInfo?.pkgCharge?.toFixed(decimalPosition),
        })
    }
  },[singleOrderInfo])
  
  
  useEffect(()=>{
    if(selectedPaymentRow!==null){

        let amt=inputValueCalculator!==""?Number(inputValueCalculator)?.toFixed(decimalPosition):"0.00"
        let duplicateArray=[...paymentSelectedArray]
        duplicateArray[selectedPaymentRow]={...duplicateArray[selectedPaymentRow],paidAmt:amt}

        setPaymentSelectedArray(duplicateArray)
    }
  },[inputValueCalculator])


  useEffect(()=>{
    if(customerWallet===null){
        setCusWalletObj(cusWalletObjInitial)
    }else{
        setCusWalletObj({...cusWalletObj,walletBalance:customerWallet?.walletBalance})
    }
    setPaymentSelectedArray([])
  },[customerWallet])

  return (
    <div className='billing-main-container billing-payment'>
        <div className="billing-left-container" style={{height:"auto"}}>

          <div className="flex-row" style={{gap:"5px"}}>
              <div className='justify-center' style={{backgroundColor:"#fff",width:"40px"}}>
                  <IconButton
                    onClick={()=>{
                        dispatch(setBtnSelected(null))
                        dispatch(setOrderIdInPOS(null))
                    }}
                    >
                      <HomeIcon sx={{ color: "green" }} />
                  </IconButton>
              </div>
              <TopBar />
          </div>

          <div className="billing-payment-ctn">
            <div className="billing-payment-ctn-left" style={{height: "calc(100vh - 120px)"}}>
                <div className="payment-table-div">
                    
                <table>
                    <thead>
                        <th className='name' style={{paddingLeft:"25px"}}>Name</th>
                        <th>&nbsp;</th>
                        <th>Qty.</th>
                        <th>Price</th>
                        <th>Total</th>
                    </thead>
                    <tbody>
                        {
                            singleOrderInfo?.orderInfo?.map((r,i)=>(
                                <tr key={i}>
                                    <td style={{paddingLeft:"25px"}}>{r?.desccription}</td>
                                    <td>&nbsp;</td>
                                    <td>{Math.abs(r?.qty)}</td>
                                    <td>{r?.unitPrice}</td>
                                    <td>{Math.abs(r?.subTotalWoTax)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                </div>

                <div className="bottom">
                    <p>Subtotal</p>
                    <div>{currencyDetails?.symbol} {Math.abs(singleOrderInfo?.subTotal)?.toFixed(decimalPosition) || "---"}</div>
                </div>
            </div>
                  <div className="billing-payment-ctn-right">
                    <div className="billing-payment-input-rows"
                    >
                          <div className="billing-input-field">
                              <p>Order Amount</p>
                              <input
                                  placeholder="00.00"
                                  type="number"
                                  value={(singleOrderInfo?.totalExclusive+singleOrderInfo?.subTotal)?.toFixed(decimalPosition)}
                                 readOnly
                              />
                          </div>
                          {
                            singleOrderInfo?.ordType==="DELIVERY" &&
                            <div className="billing-input-field">
                              <p>Delivery charge</p>
                              <input
                                  placeholder="0.00"
                                  type="number"
                                  onFocus={(e)=>e.target.select()}
                                  value={paymentForm?.deliveryCharge}
                                  onChange={(e)=>paymentFormOnChange("deliveryCharge",e.target.value)}
                                  onBlur={handleMouseLeave}
                                  disabled={singleOrderInfo?.type==="refund" || (branchConfigurationList?.deliveryCharge)}
                                />
                          </div>}
                         {
                         !(singleOrderInfo?.ordType==="DINEIN") && 
                         <div className="billing-input-field">
                              <p>Packaging charge</p>
                              <input
                                  placeholder="0.00"
                                  type="number"
                                  onFocus={(e)=>e.target.select()}
                                  value={paymentForm?.pkgCharge}
                                  onChange={(e)=>paymentFormOnChange("pkgCharge",e.target.value)}
                                  onBlur={handleMouseLeave}
                                  disabled={singleOrderInfo?.type==="refund" || (branchConfigurationList?.packagingCharge)}
                                />
                          </div>}
                          <div className="billing-input-field">
                              <p>Discount</p>
                              <input
                                  placeholder="0.00"
                                  type="number"
                                  value={paymentForm?.discount}
                                  onChange={(e)=>paymentFormOnChange("discount",e.target.value)}
                                  onFocus={(e)=>e.target.select()}
                                  onBlur={handleMouseLeave}
                                  disabled={singleOrderInfo?.type==="refund" || (singleOrderInfo?.status==="DONE" && singleOrderInfo?.amountDue>0)}
                                />
                          </div>
                          {/* {
                            singleOrderInfo?.status==="DONE" &&
                           <div className="billing-input-field">
                              <p>Paid Amount</p>
                              <input
                                  placeholder="0.00"
                                  type="number"
                                  value={paymentForm?.amountPaid || ""}
                                  readOnly
                                  />
                          </div>} */}
                         
                    </div>
                      <div
                          className="total-amount"
                      >
                          <div className="total">
                              {paymentForm?.totalExcl !== 0 && 
                              <p>Exclusive Tax</p>}
                            
                        {paymentForm?.totalIncl !== 0 && 
                              <p >Inclusive Tax</p>
                              }
                              <p>Change</p>
                              <p style={{color:"red"}}>Balance</p>
                              <h1>Total amount </h1>
                          </div>
                          <div className="total-a">
                              {
                                  paymentForm?.totalExcl !== 0 &&
                                  <p>{paymentForm?.totalExcl?.toFixed(decimalPosition)}</p>
                                  }
                              {
                                  paymentForm?.totalIncl!== 0 &&
                                  <p>{paymentForm?.totalIncl?.toFixed(decimalPosition)}</p>}
                              <p>{paymentForm?.change}</p>
                              <p style={{color:"red"}}>{paymentForm?.balance}</p>
                              <h1>{paymentForm?.amountTotal?.toFixed(decimalPosition)}</h1>
                          </div>
                      </div>
                    <Calculator/>
            </div>
          </div>
        </div>
        <div className="flex-column">
            
            <div className="payment-types-ctn">
                <div className="payment-types-ctn-head justify-center">
                    Payment Method
                </div>
                <div className="payment-types-row-ctn">
                    {
                        paymentsTypeArray.length!==0?
                        <>
                       { paymentsTypeArray.map((r,i)=>(
                            cardManual!==undefined && cardManual?.filter((obj)=>obj?.journalId===r?.journalId).length!==0 && r?.type==="card"?
                            <>
                            <div key={i} className="payment-types-row payment-types-row-auto-complete">
                                <Autocomplete
                                    options={cardManual?.filter((obj)=>obj?.journalId===r?.journalId) || []}
                                    getOptionLabel={(option) => option?.cardName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={r?.journalName}
                                        />
                                    )}
                                    onChange={paymentCardOnchange(r)}
                                    value={null}
                                />
                            </div>
                            </>
                            :
                            <>
                                <div key={i}
                                onClick={paymentTypeClick(r)}
                                className="payment-types-row">
                                    {r?.journalName}
                                </div>
                            </>
                        ))}
                        {
                            walletStatus && customerWallet && singleOrderInfo?.type!=="refund" &&
                            <div
                                onClick={paymentTypeClick(cusWalletObj)}
                                className="payment-types-row">
                                {cusWalletObj?.journalName}
                                <span style={{color:"#139109",fontWeight:"500"}}>
                                    {cusWalletObj?.walletBalance?.toFixed(decimalPosition)}
                                </span>
                            </div>
                        }
                        </>
                        :
                        <div className="center-div">
                            Payment Methods Not Added !!
                        </div>
                    
                    }
                </div>
                <div className="payment-types-ctn-head justify-center">
                    Payment Selected
                </div>
                <div className="payment-types-row-ctn" style={{flex:"1"}}>
                { 
                    paymentSelectedArray.length!==0?
                    paymentSelectedArray.map((r,i)=>(
                    <>
                    {
                    !(r?.change) &&    
                    <div 
                    key={i}
                    onMouseLeave={handleMouseLeavePayment}
                    onClick={paymentRowClick(i)}
                    className="payment-types-row-list">
                        <div className="payment-types-row">
                            <p className='justify-center' style={{gap:"4px"}}>
                              {/* {
                              cardManual!==undefined && r?.type==="card"?
                              <img
                                style={{height:"20px",width:"20px"}}
                                src={r?.imageUrl} alt="" />
                                :""
                                } */}
                                {r?.journalName?.toUpperCase()}
                                </p>
                                {r?.type==="card" &&
                                <input 
                                className='card-aproval-code' 
                                type="text" 
                                placeholder='Approval code'
                                onChange={approvalNoOnChange(i)}
                                />
                                }
                                <div
                                    className='justify-center'
                                    style={{ justifyContent: "end", gap: "10px" }}
                                >
                                    <input
                                        type='number'
                                        className={selectedPaymentRow === i && "editable"}
                                        value={r?.paidAmt}
                                        onChange={paidAmountChange(i)}
                                        onFocus={(e) => e.target.select()}
                                        onBlur={handleMouseLeavePayment}
                                    />
                                </div>
                             
                        </div>
                        <button
                            onClick={removePaymentType(i)}
                        >
                                <CloseIcon sx={{color:"#fff"}}/>
                        </button>
                    </div>}
                    </>
                     ))
                     :
                    <div className="center-div" style={{ height: "100%", flexDirection: "column" }}>
                        <img src={paymentEmpty} alt="" style={{ width: "75%" }} />
                        <p>Select a Payment Type</p>
                    </div>
                }
                    
                </div>
            </div>
              <div className="payment-button-ctn">
                  <button
                      onClick={singleOrderInfo?.type==="refund"?refundOrderPayment : finalPlaceOrderValidations}
                      className="billing-placeOrder-button">Payment</button>
              </div>
        </div>
        <Dialog open={dialogOpen==="walletChange"} onClose={walletCancelPopuo}>
            <div className='wallet-change-dialog'>
                  <div className="head justify-center">
                    <div className="icon justify-center">
                        <img src={walletIcon} alt="" />
                    </div>
                        Amount add wallet
                  </div>
                  <div className="input-ctn">
                    <button disabled className="btn">Change</button>
                    <input type="text" 
                    value={paymentForm?.changeInWallet}
                    readOnly
                    placeholder={0?.toFixed(decimalPosition)}
                    />
                  </div>
                  <div className="input-ctn">
                    <button disabled  className="btn">Wallet</button>
                    <input type="text" 
                    value={paymentForm?.wallet}
                    onChange={amountAndWalletChange("wallet")}
                    placeholder={0?.toFixed(decimalPosition)}
                    onFocus={(e)=>e.target.select()}
                    // onblur={handleMouseLeave}
                    />
                  </div>
                  <div className="wallet-change-btn">
                    <button
                    onClick={walletCancelPopuo}
                    className="btn-secondary-outlined btn">Cancel</button>
                    <button
                    onClick={payFromWallet}
                    className="btn-primary btn">Pay</button>
                  </div>
            </div>
        </Dialog>

          <Dialog open={dialogOpen === "discountAuthPassword"} onClose={() => dispatch(setDialogBox(false))}>
              <UpdateOrderAuth text={"Discount Authentication"} submitClick={finalPlaceOrder} />
          </Dialog>
    </div>
  )
}

export default BillingPayment