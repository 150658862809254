import React from 'react'
import emptyCartImg from '../../../Assets/BillingImages/emptyCart.svg'
import BackButton from '../Buttons/BackButton'

const EmptyCart = ({backBtnClick}) => {
  return (
      <div className="self-cart-container" style={{justifyContent:"center"}}>
          <BackButton onClick={backBtnClick}/>
          <img className='empty-cart-svg' src={emptyCartImg} alt="" />
          <p className='label'>Your cart is empty</p>
      </div>
  )
}

export default EmptyCart