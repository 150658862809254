import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint  from "react-to-print";
import ShiftReportTable from "./PosShiftReportTables/ShiftReportTable";
import { viewAllAllowedBranchesAPICall } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewShiftAPICall } from "../../../../API/Settings/Shift/viewShiftAPI";

import ExcelJS from "exceljs"

import { set_shift_report_branch, set_shift_report_shift, set_shift_report_submit } from "./ReportSlice";


function PosNewShiftReport() {
  const dispatch = useDispatch();
  const {shiftReportBranch,shiftReportShift}=useSelector((state)=>state.posReportshiftSlice)

  const allBranchesList = useSelector((state) => state.allBranchesSlice.value);
  const allowedBranchList = useSelector((state) => state.allAllowedBranchesSlice.value);
  const userRole = useSelector((state) => state.userRoleSlice.value);
  const userInfo = useSelector((state) => state.loginResponseSlice.value);
  const allShiftList = useSelector((state) => state.viewShiftSlice.value);
  const {
    shiftReportDetails,
    billTypeSummary,
    deliveryPartnerSummary,
    deliveryBoysSummary,
    discountSummary,
    categorySalesSummary,
    taxDetails
  } = useSelector((state) => state.posReportshiftSlice);
  const [validationAlert, setValidationAlert] = useState({});
  const decimalPosition = localStorage.getItem("decimalPosition");
  const deliveryTotal = (billTypeSummary?.selfDelivery?.total || 0) + (billTypeSummary?.aggregator?.total|| 0)
  const template = useSelector(
    (state) => state?.salesQuatationSlice?.setTemplate
  );

const exportToExcel = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Shift Summary Report', { properties: { defaultRowHeight: 20 } });

  // Title and Branch Information
  const titleRow = sheet.addRow(['Advanced Shift Report']);
  titleRow.font = { size: 18, bold: true };
  sheet.mergeCells('A1:L1');
  titleRow.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };

  const styles = {
      mainHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFedf2f9' } },
      subHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF8F8F8' } },
      tableHead: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC0CFF8' } },
      tableSub: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC2C2C2' } },
  };

  // Branch name row
  const branchRow = sheet.addRow([shiftReportDetails?.branchName || '-NIL-']);
  branchRow.font = { size: 15, bold: true };
  sheet.mergeCells(`A${sheet.lastRow._number}:L${sheet.lastRow._number}`);
  branchRow.eachCell(cell => cell.fill = styles.subHead);

  // Shift details
  const shiftDataRows = [
      [`Shift: ${shiftReportDetails?.shiftId || ''}`, `Start Date: ${shiftReportDetails?.startDate || ''}`],
      [`Employee: ${shiftReportDetails?.employeeName || ''}`, `End Date: ${shiftReportDetails?.endDate || 'Active Shift'}`]
  ];

  shiftDataRows.forEach(([text1, text2]) => {
      const row = sheet.addRow([text1, '', '', text2]);
      sheet.mergeCells(`A${row._number}:C${row._number}`);
      sheet.mergeCells(`D${row._number}:L${row._number}`);
      row.eachCell(cell => cell.fill = styles.subHead);
  });


  //////////////////////////////////////////////// Data for Bill Type Summary ///////////////////////////////////////////////////      
  if(template?.shiftReportReceiptTemplate==="template1"){
    sheet.addRow(['']);
    const billTypeRow = sheet.addRow(['Bill Type',"","",'Amount']);
    billTypeRow.font = { size: 14, bold: true };
    for (let i = 1; i <= 4; i++) {
        billTypeRow.getCell(i).fill = styles.mainHead;
    }
    
    ////////////////////////////////////////////// Cash And Card Section  
    const cashdRow = sheet.addRow(['Cash & Card',"","", billTypeSummary?.cashAndCard?.total?.toFixed(decimalPosition) || '0.0']);
    cashdRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 4; i++) {
      cashdRow.getCell(i).fill = styles.subHead;
  }
  
    const cashHeaderRow = sheet.addRow(['Payment Method', 'Amount', 'Commission', 'Balance']);
    cashHeaderRow.font = { bold: true };
    cashHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.cashAndCard?.list?.forEach((item) => {
      const dataRow =sheet.addRow([item.type, item.amount, item.commission, item.balance.toFixed(decimalPosition)]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
    ////////////////////////////////////////////// Sales Section  
    const salesSecRow = sheet.addRow(['Sales',"", billTypeSummary?.sales?.total?.toFixed(decimalPosition) || '0.0']);
    salesSecRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      salesSecRow.getCell(i).fill = styles.subHead;
  }
  
    const saleHeaderRow = sheet.addRow(['Type', 'Order Count', 'Amount']);
    saleHeaderRow.font = { bold: true };
    saleHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    Object.entries(billTypeSummary?.sales?.list)?.map(([type, details], i) => {
      const dataRow =sheet.addRow([type, details.count, details.amount?.toFixed(decimalPosition)]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
      ////////////////////////////////////////////// Staff Expense Section  
      const staffExpenseRow = sheet.addRow(['Staff Expense', '', billTypeSummary?.staffExpense?.total?.toFixed(decimalPosition) || '0.0']);
      staffExpenseRow.font = { size: 12, bold: true };
      for (let i = 1; i <= 3; i++) {
        staffExpenseRow.getCell(i).fill = styles.subHead;
    }
      const staffExpenseHeaderRow = sheet.addRow(['Expense', 'Employee', 'Amount']);
      staffExpenseHeaderRow.font = { bold: true };
      staffExpenseHeaderRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = styles.tableHead
      });
    
      billTypeSummary?.staffExpense?.list?.forEach((item) => {
        const dataRow =  sheet.addRow([item.expense, item.employee, item.amount.toFixed(decimalPosition)]);
        dataRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
      });
        ////////////////////////////////////////////////   Outlet Expense Section   
    const outletExpenseRow = sheet.addRow(['Outlet Expense',billTypeSummary?.outletExpense?.total?.toFixed(decimalPosition) || '0.0']);
    outletExpenseRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      outletExpenseRow.getCell(i).fill = styles.subHead;
  }
  
    const outletExpenseHeaderRow = sheet.addRow(['Doc No', 'Expense', 'Amount']);
    outletExpenseHeaderRow.font = { bold: true };
    outletExpenseHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.outletExpense?.list?.forEach((item) => {
      const dataRow =sheet.addRow([ item.docNo, item.expense, item.amount.toFixed(decimalPosition)]);
        dataRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
    });
  
      ///////////////////////////////////////////////////// Delivery row 
      const deliveryRow = sheet.addRow(['Delivery', '', deliveryTotal.toFixed(decimalPosition)]);
      deliveryRow.font = { size: 12, bold: true };
      for (let i = 1; i <= 3; i++) {
        deliveryRow.getCell(i).fill = styles.subHead;
    }
      // Self Delivery Section
      const selfDeliveryRow = sheet.addRow(['Self Delivery',"",billTypeSummary?.selfDelivery?.total]);
      selfDeliveryRow.font = { size: 12, bold: true };
      for (let i = 1; i <= 3; i++) {
        selfDeliveryRow.getCell(i).fill = styles.tableSub;
    }
        ///////////////// Self delivery section
        const selfDeliveryHeaderRow = sheet.addRow(['Employee','Order Count','Amount']);
        selfDeliveryHeaderRow.font = { bold: true }; 
        selfDeliveryHeaderRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = styles.tableHead
        });
      
        billTypeSummary?.delivery?.selfDelivery?.list?.forEach((item) => {
          const dataRow=sheet.addRow([item.name,item.count,item.amount.toFixed(decimalPosition)]);
          dataRow.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "right" };
          });
        });
    
         ///////////////// Aggregator Section
      const aggregatorRow = sheet.addRow(['Aggregator',"",billTypeSummary?.aggregator?.total]);
      aggregatorRow.font = { size: 12, bold: true };
      for (let i = 1; i <= 3; i++) {
        aggregatorRow.getCell(i).fill = styles.tableSub;
    }
    
      // Aggregator Delivery details
      const deliveryHeaderRow = sheet.addRow(['Employee','Order Count','Amount']);
      deliveryHeaderRow.font = { size: 12, bold: true };
      deliveryHeaderRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = styles.tableHead
      });
    
     billTypeSummary?.delivery?.aggregator?.list?.forEach((item) => {
      const dataRow=sheet.addRow([item.name,item.count,item.amount.toFixed(decimalPosition)]);
          dataRow.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "right" };
          });
      });
    /////////////////////////////////////////   RECEIPT DETAILS ////////////////////////////////////////
  
  sheet.addRow(['']);
  const reciptRow = sheet.addRow(['Receipt',"",'Amount']);
  reciptRow.font = { size: 14, bold: true };
  for (let i = 1; i <= 3; i++) {
    reciptRow.getCell(i).fill = styles.subHead;
  }
  sheet.addRow(['Sales', '',billTypeSummary?.receipt?.sales?.toFixed(decimalPosition) || '0.0']);
  
  sheet.addRow(['Delivery Partner Receipt', '',billTypeSummary?.receipt?.deliveryPartner?.toFixed(decimalPosition) || '0.0']);
  
  sheet.addRow(['Packaging Charge', '',billTypeSummary?.receipt?.packagingCharge?.toFixed(decimalPosition) || '0.0']);
  
  sheet.addRow(['Delivery Charge', '',billTypeSummary?.receipt?.deliveryCharge?.toFixed(decimalPosition) || '0.0']);
  
  sheet.addRow(['Receipt Total', '',billTypeSummary?.receipt?.total?.toFixed(decimalPosition) || '0.0']);
  
    /////////////////////////// TAX Section
    const taxBillRow = sheet.addRow(['Tax', '',billTypeSummary?.tax?.total?.toFixed(decimalPosition) || '0.0']);
    taxBillRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      taxBillRow.getCell(i).fill = styles.subHead;
  }
    const headertaxRow = sheet.addRow([
      "Tax Name",
      "Tax%",
      "Amount",
    ]);
    headertaxRow.font = { size: 12, bold: true };
    headertaxRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
  billTypeSummary?.tax?.list?.forEach((item, index) => {
  const dataRow = sheet.addRow([item.name,  item.percentage,  item.amount.toFixed(decimalPosition)]);
  dataRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "right" };
  });
  });
    /////////////////////////// DEVICE Section
    const deviceRow = sheet.addRow(['Device', '',billTypeSummary?.device?.total?.toFixed(decimalPosition) || '0.0']);
    deviceRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      deviceRow.getCell(i).fill = styles.subHead;
  }
    const headerdeviceRow = sheet.addRow([
      "Device Name",
      "Order Count",
      "Amount",
    ]);
    headerdeviceRow.font = { size: 12, bold: true };
    headerdeviceRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
  billTypeSummary?.device?.list?.forEach((item, index) => {
  const dataRow = sheet.addRow([item.employeeName,  item.count,  item.amount.toFixed(decimalPosition)]);
  dataRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "right" };
  });
  });
  }else{
   sheet.addRow(['']);
    const billTypeRow = sheet.addRow(['Bill Type',"",'Amount']);
    billTypeRow.font = { size: 14, bold: true };
    for (let i = 1; i <= 3; i++) {
        billTypeRow.getCell(i).fill = styles.mainHead;
    }
    // Dine In Section
    const dineInRow = sheet.addRow(['Dine In', '',billTypeSummary?.dineIn?.total?.toFixed(decimalPosition) || '0.0']);
    dineInRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      dineInRow.getCell(i).fill = styles.subHead;
  }
    const headerRow = sheet.addRow([
      "Bill No",
      "Date",
      "Amount",
    ]);
    headerRow.font = { size: 12, bold: true };
    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    [20, 20, 20, 20, 20, 20, 20, 20].forEach((width, index) => {
      sheet.getColumn(index + 1).width = width;
    });
  
  billTypeSummary?.dineIn?.list?.forEach((item, index) => {
  const dataRow = sheet.addRow([item.billNo,  item.date,  item.amount.toFixed(decimalPosition)]);
  dataRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "right" };
  });
  });
  
      //////////////////////////////////////////////////// Take Away Section 
    const takeAwayRow = sheet.addRow(['Take Away','', billTypeSummary?.takeAway?.total?.toFixed(decimalPosition) || '0.0']);
    takeAwayRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      takeAwayRow.getCell(i).fill = styles.subHead;
  }
    const takeAwayHeaderRow = sheet.addRow(['Bill No','Date', 'Amount',]);
   takeAwayHeaderRow.font = { size: 12, bold: true };
   takeAwayHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.takeAway?.list?.forEach((item) => {
      const dataRow =  sheet.addRow([item.billNo,item.date,item.amount.toFixed(decimalPosition)]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
  
    ///////////////////////////////////////////////////// Delivery row 
    const deliveryRow = sheet.addRow(['Delivery', '', deliveryTotal.toFixed(decimalPosition)]);
    deliveryRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      deliveryRow.getCell(i).fill = styles.subHead;
  }
    // Self Delivery Section
    const selfDeliveryRow = sheet.addRow(['Self Delivery',"",billTypeSummary?.selfDelivery?.total]);
    selfDeliveryRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      selfDeliveryRow.getCell(i).fill = styles.tableSub;
  }
      ///////////////// Self delivery section
      const selfDeliveryHeaderRow = sheet.addRow(['Bill No','Date','Amount']);
      selfDeliveryHeaderRow.font = { bold: true }; 
      selfDeliveryHeaderRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = styles.tableHead
      });
    
      billTypeSummary?.selfDelivery?.list?.forEach((item) => {
        const dataRow=sheet.addRow([item.billNo,item.date,item.amount.toFixed(decimalPosition)]);
        dataRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
      });
  
       ///////////////// Aggregator Section
    const aggregatorRow = sheet.addRow(['Aggregator',"","","","",billTypeSummary?.aggregator?.total]);
    aggregatorRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 6; i++) {
      aggregatorRow.getCell(i).fill = styles.tableSub;
  }
  
    // Aggregator Delivery details
    const deliveryHeaderRow = sheet.addRow(['Aggregator', 'Order ID', 'Date', 'Discount', 'Commission', 'Amount']);
    deliveryHeaderRow.font = { size: 12, bold: true };
    deliveryHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
   billTypeSummary?.aggregator?.list?.forEach((item) => {
    const dataRow=sheet.addRow([item.aggregatorName, item.billNo, item.date, item.discount, item.commission, item.amount]);
        dataRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
    });
  
    ////////////////////////////////////////////// Staff Expense Section  
    const staffExpenseRow = sheet.addRow(['Staff Expense', '', billTypeSummary?.staffExpense?.total?.toFixed(decimalPosition) || '0.0']);
    staffExpenseRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 4; i++) {
      staffExpenseRow.getCell(i).fill = styles.subHead;
  }
    const staffExpenseHeaderRow = sheet.addRow(['Expense', 'Employee', 'Date', 'Amount']);
    staffExpenseHeaderRow.font = { bold: true };
    staffExpenseHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.staffExpense?.list?.forEach((item) => {
      const dataRow =  sheet.addRow([item.expense, item.employee, item.date, item.amount.toFixed(decimalPosition)]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
    ////////////////////////////////////////////////   Outlet Expense Section   
    const outletExpenseRow = sheet.addRow(['Outlet Expense',billTypeSummary?.outletExpense?.total?.toFixed(decimalPosition) || '0.0']);
    outletExpenseRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 5; i++) {
      outletExpenseRow.getCell(i).fill = styles.subHead;
  }
  
    const outletExpenseHeaderRow = sheet.addRow(['Shift ID', 'Doc No', 'Expense', 'Date', 'Amount']);
    outletExpenseHeaderRow.font = { bold: true };
    outletExpenseHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.outletExpense?.list?.forEach((item) => {
      const dataRow =sheet.addRow([item.shift, item.docNo, item.expense, item.date, item.amount.toFixed(decimalPosition)]);
        dataRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
    });
  
    ////////////////////////////////////////////////////////// Refund Section  
    const refundRow = sheet.addRow(['Refund', billTypeSummary?.refund?.total?.toFixed(decimalPosition) || '0.0']);
    refundRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 5; i++) {
      refundRow.getCell(i).fill = styles.subHead;
  }
  
    const refundHeaderRow = sheet.addRow(['Order ID', 'Product', 'Type', 'Date', 'Amount']);
    refundHeaderRow.font = { bold: true };
    refundHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.refund?.list?.forEach((item) => {
      const dataRow =sheet.addRow([item.billNo, item.product, item.type, item.date, item.amount.toFixed(decimalPosition)]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    }); 
  
    ////////////////////////////////////////////// Cash And Card Section  
    const cashdRow = sheet.addRow(['Cash & Card', billTypeSummary?.cashAndCard?.total?.toFixed(decimalPosition) || '0.0']);
    cashdRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 4; i++) {
      cashdRow.getCell(i).fill = styles.subHead;
  }
  
    const cashHeaderRow = sheet.addRow(['Payment Method', 'Amount', 'Commission', 'Balance']);
    cashHeaderRow.font = { bold: true };
    cashHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.cashAndCard?.list?.forEach((item) => {
      const dataRow =sheet.addRow([item.type, item.amount, item.commission, item.balance.toFixed(decimalPosition)]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
    ////////////////////////////////////////////// DELIVERY CHARGE 
    const delvryRow = sheet.addRow(['Delivery Charge', billTypeSummary?.deliveryCharge?.total?.toFixed(decimalPosition) || '0.0']);
    delvryRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 2; i++) {
      delvryRow.getCell(i).fill = styles.subHead;
  }
  
    const dlvryHeaderRow = sheet.addRow(['Bill No', 'Amount']);
    dlvryHeaderRow.font = { bold: true };
    dlvryHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.deliveryCharge?.list?.forEach((item) => {
      const dataRow =sheet.addRow([item.billNo, item.amount]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
    ////////////////////////////////////////////////// PACKING CHARGE  
    const packingRow = sheet.addRow(['Packing Charge', billTypeSummary?.packagingCharge?.total?.toFixed(decimalPosition) || '0.0']);
    packingRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 2; i++) {
      packingRow.getCell(i).fill = styles.subHead;
  }
  
    const pckgnHeaderRow = sheet.addRow(['Bill No', 'Amount']);
    pckgnHeaderRow.font = { bold: true };
    pckgnHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
  
    billTypeSummary?.packagingCharge?.list?.forEach((item) => {
      const dataRow =sheet.addRow([item.billNo, item.amount]);
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
    });
  }
  
 
 

 

 
////////////////////////////////////////// DELIVERY PARTNER ////////////////////////////////////////////////////////////
  sheet.addRow(['']); 
  const delivryPartnerRow = sheet.addRow(['Delivery Partners','', 'Amount']);
  delivryPartnerRow.font = { size: 14, bold: true };
  for (let i = 1; i <= 3; i++) {
    delivryPartnerRow.getCell(i).fill = styles.mainHead;
  }

  deliveryPartnerSummary?.forEach((partner, index) => {
    const partnerRow = sheet.addRow([partner?.partner, '', partner?.total?.toFixed(decimalPosition) || '0.0']);
    partnerRow.font = { size: 12, bold: true };
    for (let i = 1; i <= 3; i++) {
      partnerRow.getCell(i).fill = styles.subHead;
  }

    const tableHeaderRow = sheet.addRow(['Order No','Date', 'Amount']);
    tableHeaderRow.font = { bold: true, bold: true };
    tableHeaderRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = styles.tableHead
    });
    [20, 20, 20, 20, 20, 20, 20, 20].forEach((width, index) => {
      sheet.getColumn(index + 1).width = width;
    });
    partner?.data?.forEach((order) => {
      
        const orderRow = sheet.addRow([order.orderNo,order.date,order.amount?.toFixed(decimalPosition) || '0.0']);
        orderRow.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "right" };
        });
    });
});
//////////////////////////////////////////////// Delivery Boys  ////////////////////////////////////////////////////
sheet.addRow(['']); 
const delivryBoysrRow = sheet.addRow(['Delivery Boys', '', 'Amount']);
delivryBoysrRow.font = { size: 14, bold: true };
for (let i = 1; i <= 3; i++) {
  delivryBoysrRow.getCell(i).fill = styles.mainHead;
}

deliveryBoysSummary?.forEach((partner, index) => {
  const partnerRow = sheet.addRow([partner?.partner, '', partner?.total?.toFixed(decimalPosition) || '0.0']);
  partnerRow.font = { size: 12, bold: true };
  for (let i = 1; i <= 3; i++) {
    partnerRow.getCell(i).fill = styles.mainHead;
}

  const tableHeaderRow = sheet.addRow(['Order No', 'Date','Amount']);
  tableHeaderRow.font = { bold: true, bold: true };
  tableHeaderRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  });

  partner?.data?.forEach((order) => {
    
      const orderRow = sheet.addRow([order.orderNo,order.date,order.amount?.toFixed(decimalPosition) || '0.0']);
      orderRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });

  });
});
////////////////////////////////////////////////// DISCOUNT SUMMARY  ////////////////////////////////
  sheet.addRow(['']); 
  const billTypeRows = sheet.addRow(['Discount Summary','', 'Amount']);
  billTypeRows.font = { size: 14, bold: true };
  for (let i = 1; i <= 3; i++) {
    billTypeRows.getCell(i).fill = styles.mainHead;
  }
/////////////////   DINE IN
  const dineInRows = sheet.addRow(['Dine In', '', discountSummary?.dineIn?.discount?.toFixed(decimalPosition) || '0.0']);
  dineInRows.font = { size: 12, bold: true };
  for (let i = 1; i <= 3; i++) {
    dineInRows.getCell(i).fill = styles.subHead;
}
 
  const tableHeaderRows = sheet.addRow(['Order No','Date','Amount']);
  tableHeaderRows.font = { size: 12,bold: true };
  tableHeaderRows.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  })
discountSummary?.dineIn?.data?.forEach((item, index) => {
const dataRow = sheet.addRow([item.name, item.orderDate, item.discount.toFixed(decimalPosition)]);
dataRow.eachCell((cell) => {
  cell.alignment = { vertical: "middle", horizontal: "right" };
});
});

  ///////////// Take Away Section
  const takeAwayRows = sheet.addRow(['Take Away', '', discountSummary?.takeAway?.discount?.toFixed(decimalPosition) || '0.0']);
  takeAwayRows.font = { size: 12, bold: true };
  for (let i = 1; i <= 3; i++) {
    takeAwayRows.getCell(i).fill = styles.subHead;
}

  // Table headings for Take Away section
  const takeAwayHeaderRows = sheet.addRow(['Order No','Date', 'Amount']);
  takeAwayHeaderRows.font = { size: 12, bold: true };
  takeAwayHeaderRows.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  });

  discountSummary?.takeAway?.data?.forEach((item) => {
    const dataRow =  sheet.addRow([item.name,item.orderDate,item.discount.toFixed(decimalPosition)]);
    dataRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "right" };
    });
  });
  //////////////////////// Delivery row
  const deliveryRows = sheet.addRow(['Delivery', '',  discountSummary?.delivery?.discount?.toFixed(decimalPosition) || '0.0']);
  deliveryRows.font = { size: 12, bold: true };
  for (let i = 1; i <= 3; i++) {
    deliveryRows.getCell(i).fill = styles.subHead;
}

  const takeAwayHeadRows = sheet.addRow(['Order No','Date', 'Amount']);
  takeAwayHeadRows.font = { bold: true };
  takeAwayHeadRows.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  });

  discountSummary?.delivery?.data?.forEach((item) => {
    const dataRow =  sheet.addRow([item.name,item.orderDate,item.discount.toFixed(decimalPosition)]);
    dataRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "right" };
    });
  });
/////////////////////////////////////////// CATEGORY SALES ////////////////////////////////
sheet.addRow(['']); 
const categryRow = sheet.addRow(['Category Name','', 'Amount']);
categryRow.font = { size: 14, bold: true };
for (let i = 1; i <= 3; i++) {
  categryRow.getCell(i).fill = styles.mainHead;
}

categorySalesSummary?.forEach((partner, index) => {
  const partnerRow = sheet.addRow([partner?.categoryName, '', partner?.totalAmount?.toFixed(decimalPosition) || '0.0']);
  partnerRow.font = { size: 12, bold: true };
  for (let i = 1; i <= 3; i++) {
    partnerRow.getCell(i).fill = styles.subHead;
}

  const tableHeaderRow = sheet.addRow(['Product','Quantity', 'Amount']);
  tableHeaderRow.font = { bold: true, bold: true };
  tableHeaderRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  });
  [20, 20, 20, 20, 20, 20, 20, 20].forEach((width, index) => {
    sheet.getColumn(index + 1).width = width;
  });
  partner?.products?.forEach((order) => {
    
      const orderRow = sheet.addRow([order.product,order.quantity,order.amount?.toFixed(decimalPosition) || '0.0']);
      orderRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "right" };
      });
  });
});
/////////////////////////////////////////////////////  TAX DETAILS  ////////////////////////////////////////////
sheet.addRow(['']);
  const taxRow = sheet.addRow(['Tax Details',"","","",'Tax Amount']);
  taxRow.font = { size: 14, bold: true };
  for (let i = 1; i <= 5; i++) {
    taxRow.getCell(i).fill = styles.mainHead;
  }
  // Dine In Section
  const dineRow = sheet.addRow(['Dine In', "","",'',taxDetails?.dineIn?.totalTax?.toFixed(decimalPosition) || '0.0']);
  dineRow.font = { size: 12, bold: true };
  for (let i = 1; i <= 5; i++) {
    dineRow.getCell(i).fill = styles.subHead;
}
  const taxheaderRow = sheet.addRow([
    "Order ID",
    "Customer",
    "Tax Name",
    "Order Amount",
    "Tax Amount",
  ]);
 taxheaderRow.font = { size: 12, bold: true };
 taxheaderRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  });

  [20, 20, 20, 20, 20, 20, 20, 20].forEach((width, index) => { 
    sheet.getColumn(index + 1).width = width;
  });

  taxDetails?.dineIn?.data?.forEach((item, index) => {
const dataRow = sheet.addRow([item.name,  item.cusName, item.taxName,item.amtTotal?.toFixed(decimalPosition),item.taxAmount?.toFixed(decimalPosition)]);
dataRow.eachCell((cell) => {
  cell.alignment = { vertical: "middle", horizontal: "right" };
});
});

    //////////////////////////////////////////////////// Take Away Section /////////////////////////////////////////////////////////
  const takeAwaytaxRow = sheet.addRow(['Take Away','','','', taxDetails?.takeAway?.totalTax?.toFixed(decimalPosition) || '0.0']);
  takeAwaytaxRow.font = { size: 12, bold: true };
  for (let i = 1; i <= 5; i++) {
    takeAwaytaxRow.getCell(i).fill = styles.subHead;
}
  const takeAwaytaxHeaderRow = sheet.addRow(['Order ID','Customer', 'Tax Name','Order Amount','Tax Amount']);
 takeAwaytaxHeaderRow.font = { size: 12, bold: true };
 takeAwaytaxHeaderRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "center" };
    cell.fill = styles.tableHead
  });

  taxDetails?.takeAway?.list?.forEach((item) => {
    const dataRow =  sheet.addRow([item.name,item.cusName,item.taxName,item.amtTotal,item.taxAmount]);
    dataRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "right" };
    });
  });

  ///////////////////////////////////////////////////// Delivery row  ///////////////////////////////////////////////////////////////
  const deliverytaxRow = sheet.addRow(['Delivery', '','','', taxDetails?.delivery?.totalTax?.toFixed(decimalPosition)]);
  deliverytaxRow.font = { size: 12, bold: true };
  for (let i = 1; i <= 5; i++) {
    deliverytaxRow.getCell(i).fill = styles.subHead;
}
const delvrytaxHeaderRow = sheet.addRow(['Order ID','Customer', 'Tax Name','Order Amount','Tax Amount']);
delvrytaxHeaderRow.font = { size: 12, bold: true };
delvrytaxHeaderRow.eachCell((cell) => {
   cell.alignment = { vertical: "middle", horizontal: "center" };
   cell.fill = styles.tableHead
 });
taxDetails?.delivery?.data?.forEach((item) => {
  const dataRow =  sheet.addRow([item.name,item.cusName,item.taxName,item.amtTotal,item.taxAmount]);
  dataRow.eachCell((cell) => {
    cell.alignment = { vertical: "middle", horizontal: "right" };
  });
});
/////////////////////////////////////////   RECEIPT DETAILS ////////////////////////////////////////
// Receipt Summary Section
sheet.addRow(['']);
const receiptRow = sheet.addRow(['Receipt',"",'Amount']);
receiptRow.font = { size: 14, bold: true };
for (let i = 1; i <= 3; i++) {
  receiptRow.getCell(i).fill = styles.mainHead;
}

const salesRow = sheet.addRow(['Sales', '',billTypeSummary?.receipt?.sales?.toFixed(decimalPosition) || '0.0']);
salesRow.font = { size: 12, bold: true };
const delvryprtnrRow = sheet.addRow(['Delivery Partner Receipt', '',billTypeSummary?.receipt?.deliveryPartner?.toFixed(decimalPosition) || '0.0']);
delvryprtnrRow.font = { size: 12, bold: true };
const pckgngchrgRow = sheet.addRow(['Packaging Charge', '',billTypeSummary?.receipt?.packagingCharge?.toFixed(decimalPosition) || '0.0']);
pckgngchrgRow.font = { size: 12, bold: true };
const dlvryChrgRow = sheet.addRow(['Delivery Charge', '',billTypeSummary?.receipt?.deliveryCharge?.toFixed(decimalPosition) || '0.0']);
dlvryChrgRow.font = { size: 12, bold: true };
const receiptTotalRow = sheet.addRow(['Receipt Total', '',billTypeSummary?.receipt?.total?.toFixed(decimalPosition) || '0.0']);
receiptTotalRow.font = { size: 16, bold: true };
  
///////////////////////////////////////// DEVICE  ////////////////////////////////////////
 // Add Device Data
 sheet.addRow(['']); // Blank row for spacing
 const deviceHeaderRow = sheet.addRow(['Device', 'Order Count', 'Amount']);
 deviceHeaderRow.font = { size: 14, bold: true };
 for (let i = 1; i <= 3; i++) {
   deviceHeaderRow.getCell(i).fill = styles.mainHead;
 }

 billTypeSummary?.device?.list?.forEach((device) => {
   const deviceRow = sheet.addRow([
     device.employeeName || 'N/A',
     device.count || 0,
     (device.amount || 0).toFixed(decimalPosition)
   ]);
   deviceRow.eachCell((cell, colNumber) => {
     cell.alignment = { vertical: 'middle', horizontal: colNumber === 1 ? 'left' : 'right' };
     cell.fill = styles.subHead;
   });
 });

 const totalRow = sheet.addRow(['All Devices', billTypeSummary?.device?.count || 0, 
     (billTypeSummary?.device?.total || 0).toFixed(decimalPosition)]);
 totalRow.font = { size: 14, bold: true };


  // Export to Excel 
  workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'AdvancedShiftReport.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
  });
};

  const handleSubmit = () => {
    let validationErrors = {};
    if (!shiftReportBranch) {
      validationErrors.branch = "Please select a branch.";
    }
    if (!shiftReportShift) {
      validationErrors.shift = "Please select a shift.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setValidationAlert(validationErrors);
      return;
    }
    // submit  function  if no errors
    dispatch(set_shift_report_submit("submit"));
  };

  const branchOnchange = (e, newValue) => {
    dispatch(set_shift_report_branch(newValue));
    dispatch(set_shift_report_shift(null));
    viewShiftAPICall({ branchId: newValue?._id });
    setValidationAlert({});
  };
  useEffect(() => {
    userRole === "user" &&
      viewAllAllowedBranchesAPICall({ employeeId: userInfo?.data?._id });
  }, []);

  return (
    <div className="global-page-parent-container">
      <div
        className="global-white-bg-container Header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 30px 0 25px",
          alignItems: "center",
        }}
      >
        <p>Advance Shift Report</p>
        <div>                       
          <ReactToPrint                               
            trigger={() => (                                 
              <IconButton className="printer-div" style={{ padding: "1px" }}>
                <i                                                     
                  style={{                 
                    color: "white",
                    backgroundColor: " #d787e7",                 
                    fontSize: "medium",                          
                    padding: "8px",
                    borderRadius: "2px",               
                  }}                 
                  class="bi bi-printer"              
                ></i>
              </IconButton>
            )}
          />
                                                                                                                     
          <Tooltip title="Download">
            <IconButton onClick={exportToExcel}>
              <i
                class="bi bi-arrow-down-circle"
                style={{
                  backgroundColor: "rgb(255 118 133 / 69%)",
                  color: "white",
                  fontSize: "medium",
                  padding: "8px",
                  borderRadius: "2px",
                }}
              ></i>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        className="global-white-bg-container "
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          className="new-global-single-input auto-complete-new"
          style={{ width: "26%", paddingInline: "20px" }}
        >
          <Autocomplete
            disablePortal
            options={
              userRole === "admin" ? allBranchesList : allowedBranchList || []
            }
            getOptionLabel={(option) => option?.branchName}
            placeholder="Branch"
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Branch" focused placeholder="" />
            )}
            value={shiftReportBranch}
            onChange={branchOnchange}
          />
          <p style={{ marginRight: "23px" }} className="doc-validation-alert">
            {validationAlert?.branch}
          </p>
        </div>
        <div
          className="new-global-single-input auto-complete-new"
          style={{ width: "28%", padding: "0 55px 0 14px" }}
        >
          <Autocomplete
            disablePortal
            options={allShiftList?.slice(0)?.reverse() || []}
            getOptionLabel={(option) => option?.SHIFID}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Shift" focused placeholder="" />
            )}
            value={shiftReportShift}
            onChange={(e, newValue) => {
              dispatch(set_shift_report_shift(newValue));
              setValidationAlert({});
            }}
          />
          <p style={{ marginRight: "57px" }} className="doc-validation-alert">
            {validationAlert?.shift}
          </p>
        </div>
        <button className="create-button-blue" onClick={handleSubmit}>
          Submit
        </button>
      </div>
  

      <ShiftReportTable />
    </div>
  );
}

export default PosNewShiftReport;
