import { Skeleton } from '@mui/material';
import React from 'react';

const TableLoading =({ colSpan,rowcount }) => {

    const tablelength =Array.from({length:rowcount})

    console.log(tablelength);
    
  return (
      <tbody>
       {
       tablelength?.map((r,i)=>(
    
       <tr key={i}>
          <td colSpan={colSpan} >
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </td>
        </tr>
        ))}
      </tbody>
  );
};

export default TableLoading;
